import React, { useRef, useContext, useState, useEffect } from 'react'
import api from '../../api'
import { DataTable } from 'primereact/datatable'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import ToastContext from '../../ToastContext'


const typeOptions = [
    { label: 'Int', value: 'Int' },
    { label: 'Float', value: 'Float' },
    { label: 'Text', value: 'Text' },
    { label: 'Date', value: 'Date' }
]

function CaseAttributeTypes() {
    const [caseAttributeTypes, setCaseAttributeTypes] = useState([])
    const [editedRows, setEditedRows] = useState({})
    const showToast = useContext(ToastContext)

    const fetchCaseAttributeTypes = async (refresh) => {
        api.get(`/case_attribute_types`)
            .then(response => {
                const data = response.data
                setCaseAttributeTypes(data)
                console.log("Case AttributeTypes:", data)
                if (refresh) {
                    showToast({ severity: 'info', summary: 'Successful', detail: 'Case Attribute Types Refreshed' })
                }
            })
            .catch(error => {
                console.error(error)
            })
    }

    useEffect(() => {
        fetchCaseAttributeTypes()
    }, [])

    const onCellEditComplete = (e) => {
        // Store edited rows for saving later
        setEditedRows({
            ...editedRows,
            [e.rowData.id]: e.rowData,
        })
    }

    const addRow = () => {
        const newRow = {
            id: Math.random().toString(),
            Type: '',
            Attribute: '',
        }
        setCaseAttributeTypes(prev => [...prev, newRow])
    }

    const onDropdownChange = (e, props) => {
        const updatedCaseAttributes = caseAttributeTypes.map(row =>
            row.id === props.rowData.id
                ? { ...row, [props.field]: e.value }
                : row
        )

        setCaseAttributeTypes(updatedCaseAttributes)
        onCellEditComplete({ rowData: updatedCaseAttributes.find(row => row.id === props.rowData.id) })
    }

    const saveChanges = async () => {
        // Assign new ids to rows according to their index
        const caseAttributeTypesNewIds = caseAttributeTypes.map((row, index) => {
            return { ...row, id: index }
        })
        // Convert object values to array before saving
        const rowsToSave = Object.values(caseAttributeTypesNewIds)
        await api.post('/case_attribute_types', rowsToSave)
            .then(response => {
                console.log(response)
                showToast({ severity: 'success', summary: 'Successful', detail: 'Case Attribute Types Updated' })
            })
            .catch(error => {
                console.error(error)
                showToast({ severity: 'error', summary: 'Error', detail: 'Case Attribute Types Not Updated' })
            })
        setEditedRows({})
    }

    const deleteRow = (rowData) => {
        const shouldDelete = window.confirm('Are you sure you want to delete this row?')
        if (shouldDelete) {
            setCaseAttributeTypes(prev => prev.filter(row => row !== rowData))
        }
    }

    const typeEditor = (props) => {
        return (
            <Dropdown
                value={props.rowData[props.field]}
                options={typeOptions}
                onChange={(e) => onDropdownChange(e, props)}
                style={{ width: '100%' }}
            />
        )
    }

    const refreshTable = async () => {
        await fetchCaseAttributeTypes(true)
    }

    return (
        <>
            <h1>Case Attribute Types</h1>
            <div className='flex justify-content-end'>
                <Button className='m-2' icon="pi pi-plus" severity='secondary' rounded outlined onClick={addRow} />
                <Button className='m-2' icon="pi pi-refresh" severity='info' rounded outlined onClick={refreshTable} />
                <Button className='m-2' icon="pi pi-check" severity="success" rounded outlined onClick={saveChanges} />
            </div>

            <DataTable value={caseAttributeTypes} editMode="cell" showGridlines reorderableRows onRowReorder={(e) => setCaseAttributeTypes(e.value)}>
                <Column rowReorder style={{ width: '3rem' }} />
                <Column field="Attribute" header="Attribute"
                    editor={(props) => <CellEditor {...props} caseAttributeTypes={caseAttributeTypes} setCaseAttributeTypes={setCaseAttributeTypes} onCellEditComplete={onCellEditComplete} />}
                    editable={(rowData) => !rowData.in_use}
                    onCellEditComplete={onCellEditComplete}
                    style={{ width: '33%' }} />
                <Column field="Type" header="Type"
                    editor={(props) => <DropdownEditor {...props} caseAttributeTypes={caseAttributeTypes} setCaseAttributeTypes={setCaseAttributeTypes} onCellEditComplete={onCellEditComplete} />}
                    editable={(rowData) => !rowData.in_use}
                    onCellEditComplete={onCellEditComplete}
                    style={{ width: '33%' }} />
                <Column header="Actions"
                    body={(rowData) => <Button icon='pi pi-trash' severity='danger' disabled={rowData.in_use} onClick={() => deleteRow(rowData)} />}
                    style={{ width: '33%' }} />
            </DataTable>

        </>
    )
}

export default CaseAttributeTypes

const onEditorValueChange = (props, value, caseAttributeTypes, setCaseAttributeTypes, onCellEditComplete) => {
    const updatedAttributes = [...caseAttributeTypes]
    updatedAttributes[props.rowIndex][props.field] = value
    onCellEditComplete({ rowData: updatedAttributes[props.rowIndex] })
    setCaseAttributeTypes(updatedAttributes)
}

const CellEditor = React.memo((props) => {
    const [localValue, setLocalValue] = useState(props.rowData[props.field])
    const { caseAttributeTypes, setCaseAttributeTypes, onCellEditComplete } = props

    const handleBlur = () => {
        onEditorValueChange(props, localValue, caseAttributeTypes, setCaseAttributeTypes, onCellEditComplete)
    }

    return (
        <InputText
            type="text"
            value={localValue}
            onChange={(e) => setLocalValue(e.target?.value)}
            onBlur={handleBlur}
            disabled={props.rowData.in_use}
        />
    )
})

const DropdownEditor = React.memo((props) => {
    const [localValue, setLocalValue] = useState(props.rowData[props.field])
    const { caseAttributeTypes, setCaseAttributeTypes, onCellEditComplete } = props

    const handleDropdownChange = (e) => {
        setLocalValue(e.value)
        onEditorValueChange(props, e.value, caseAttributeTypes, setCaseAttributeTypes, onCellEditComplete)
    }

    return (
        <Dropdown
            value={localValue}
            options={typeOptions} // Ensure typeOptions is defined or imported at the top
            onChange={handleDropdownChange}
            style={{ width: '100%' }}
            disabled={props.rowData.in_use}
        />
    )
})