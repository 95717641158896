import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios';
import Billing from './Billing';

function Invoices({ invoices, journals }) {
	const getInvoiceStatus = (invoice) => {
		if (invoice.AmountDue === 0.0 && invoice.AmountPaid > 0) {
			return 'PAID';
		} else if (invoice.AmountDue > 0 && invoice.AmountPaid === 0) {
			return 'UNPAID';
		} else {
			return 'PENDING';
		}
	}

	const getJournalStyles = (status) => {
		let color = '';
		switch (status) {
			case 'PAID': color = 'green'; break;
			case 'UNPAID': color = 'pink'; break;
			case 'PENDING': color = 'yellow'; break;
			default: color = 'yellow';
		}
		return color;
	}

	const getJournalStatus = (journal) => {
		if (['EXPENSE', 'CURRLIAB'].includes(journal.AccountType)) {
			return 'PAID';
		} else if (['BANK'].includes(journal.AccountType)) {
			return 'UNPAID';
		} else {
			return 'PENDING';
		}
	}

	const allowedSourceTypes = ['ACCPAY', 'CASHPAID', 'ACCPAYPAYMENT', 'APCREDITPAYMENT', 'ACCRECPAYMENT', 'ACCPAYCREDIT', 'MANJOURNAL']
	const allowedAccountTypes = ['EXPENSE']

	// Filtering the journals based on the allowed SourceType and AccountType, and a positive GrossAmount
	const filteredJournals = journals.filter(journal =>
		allowedSourceTypes.includes(journal.SourceType) &&
		allowedAccountTypes.includes(journal.AccountType)
	)

	// Sorting the filtered journals by their creation date in descending order (most recent first)
	filteredJournals.sort((a, b) => {
		const dateA = new Date(a.JournalDate)
		const dateB = new Date(b.JournalDate)
		return dateB.getTime() - dateA.getTime()
	})

	// Calculating the total gross amount across the filtered journals
	const totalAmountPaid = filteredJournals.reduce((acc, item) => {
		return Number((acc + Number(item.GrossAmount)).toFixed(2))
	}, 0)

	return (
		<div>
			<div className=''>
				{filteredJournals.length > 0 &&
					<div className="flex justify-content-between flex-wrap p-3">
						<div className="flex align-items-center justify-content-center text-xl text-900 font-medium"></div>
						<div className="flex align-items-center justify-content-center text-xl font-medium text-green-700">${totalAmountPaid}</div>
					</div>
				}

				{filteredJournals.length === 0 && (
					<div className="flex align-items-center justify-content-center py-3">
						<span className="text-500">No invoices found</span>
					</div>
				)}

				<ul className="list-none p-3 m-0">
					{filteredJournals.map((item) => {
						const status = getJournalStatus(item)
						const color = getJournalStyles(status)
						const date = new Date(item.JournalDate).toLocaleDateString()

						return (
							<li key={item.id} className={`flex flex-column px-2 pt-1 pb-2 border-1 mb-2 bg-${color}-50 border-${color}-500`} style={{ borderRadius: '10px' }}>
								<div className="text-md">
									<span className={`text-${color}-700 font-bold`}>{item.AccountName} | {item.AccountType} | {item.SourceType}</span>
								</div>

								<div className="text-md">
									<span className={`text-${color}-700 font-bold`}>{item.Description}</span>
								</div>
								
								<div className="flex align-items-center justify-content-between mt-1">
									<span>
										<span className={`bg-${color}-400 text-${color}-900 font-bold text-xs py-1 px-2`} style={{ borderRadius: '10px' }}>{status}</span>
										<span className={`text-${color}-700 mb-0 py-1 ml-2`}><span className="font-medium text-xs">{date}</span></span>
									</span>
									<div className="text-right ml-3">
										<span className={`text-${color}-700 font-bold`}>${item.GrossAmount}</span>
									</div>
								</div>
							</li>
						)
					})}
				</ul>
			</div>
		</div>
	)
}


export default Invoices

function Journals({ journals }) {
	const [dataTableData, setDataTableData] = useState([]);
	const getJournalStatus = (journal) => {
		if (['EXPENSE', 'CURRLIAB'].includes(journal.AccountType)) {
			return 'PAID';
		} else if (['BANK'].includes(journal.AccountType)) {
			return 'UNPAID';
		} else {
			return 'PENDING';
		}
	}

	const getInvoiceStyles = (status) => {
		let color = '';
		switch (status) {
			case 'PAID': color = 'green'; break;
			case 'UNPAID': color = 'pink'; break;
			case 'PENDING': color = 'yellow'; break;
			default: color = 'yellow';
		}
		return color;
	}

	const allowedSourceTypes = ['ACCPAY', 'CASHPAID', 'ACCPAYPAYMENT', 'APCREDITPAYMENT', 'ACCRECPAYMENT', 'ACCPAYCREDIT', 'MANJOURNAL']

	useEffect(() => {

		// Prepare data for DataTable
		const dataTableData = journals
			.filter(journal => allowedSourceTypes.includes(journal.SourceType) && journal.GrossAmount > 0)
			.map(journal => ({
				AccountName: journal.AccountName,
				AccountType: journal.AccountType,
				SourceType: journal.SourceType,
				JournalDate: new Date(journal.JournalDate).toLocaleDateString(),
				Amount: `$${journal.GrossAmount}`,
				Description: journal.Description
			}))


		setDataTableData(dataTableData); // You'll have to define this state variable

	}, [journals]);

	return (
		<ul className="list-none p-0 m-0">
			{journals.map((journal) => {
				const status = getJournalStatus(journal);
				const color = getInvoiceStyles(status);

				if (allowedSourceTypes.includes(journal.SourceType) && journal.GrossAmount > 0) return (
					<li key={journal.id} className={`flex flex-column px-2 pt-1 pb-2 border-1 mb-2 bg-${color}-50 border-${color}-500`} style={{ borderRadius: '10px' }}>
						<div>
							<div>
								{/* <span className={`inline-flex justify-content-center align-items-center w-2rem h-2rem border-circle border-1 border-${color}-200`}>
									<i className={`pi pi-file text-${color}-700`}></i>
								</span> */}
								<span className={`text-${color}-700 font-bold ml-0`}>{journal.AccountName}</span>
								<span className={`text-${color}-700 font-bold ml-2`}>| {journal.AccountType}</span>
								<span className={`text-${color}-700 font-bold ml-2`}>| {journal.SourceType}</span>
							</div>
						</div>
						<div className="flex align-items-center justify-content-between mt-1 ">
							<span>
								<span className={`bg-${color}-400 text-${color}-900 font-bold text-xs py-1 px-2`} style={{ borderRadius: '10px' }}>{status}</span>
								<span className={`text-${color}-700 mb-0 py-1 ml-2`}><span className="font-medium text-xs">{new Date(journal.JournalDate).toLocaleDateString()}</span></span>
							</span>
							<div className="text-right ml-3">
								{/* <span className={`text-${color}-700 font-bold`}>${journal.AmountDue > 0 ? journal.AmountDue : journal.AmountPaid}</span> */}
								<span className={`text-${color}-700 font-bold`}>${journal.GrossAmount}</span>
							</div>
						</div>
					</li>
				);
			})}
		</ul>
	)




	// return (
	// 	<DataTable value={dataTableData} size='small' stripedRows removableSort>
	// 		<Column field="AccountName" header="Account Name" sortable></Column>
	// 		<Column field="AccountType" header="Account Type" sortable></Column>
	// 		<Column field="SourceType" header="Source Type" sortable></Column>
	// 		<Column field="JournalDate" header="Date" sortable></Column>
	// 		<Column field="Amount" header="Amount" sortable></Column>
	// 	</DataTable>
	// )
}