import { useSelector, useDispatch } from 'react-redux'
import api from '../../api'
import axios from 'axios'
import { useEffect, useMemo, useRef, useState } from 'react'

import {
    setStartDateTime12h,
    setEndDateTime12h,
    setDaterange,
    setDate,
    setAllday,
    setTimeZone,
} from '../../store/CalendarInput/action'
import { Dropdown } from 'primereact/dropdown'
import { InputSwitch } from 'primereact/inputswitch'
import { RadioButton } from 'primereact/radiobutton'
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker'
import DatePicker from 'react-date-picker'
import '@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css'
import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'
import 'react-clock/dist/Clock.css'


const DateTimeInfo = () => {
    const dispatch = useDispatch()
    const startDatetime12h = useSelector(state => new Date(state.calendarInput.startDatetime12h))
    const endDatetime12h = useSelector(state => state.calendarInput.endDatetime12h)
    const daterange = useSelector(state => state.calendarInput.daterange)
    const date = useSelector(state => state.calendarInput.date)
    const allday = useSelector(state => state.calendarInput.allday)
    const timeZone = useSelector(state => state.calendarInput.timeZone)
    const timeZones = useSelector(state => state.calendarInput.timeZones)
    const prevStartDateRef = useRef(null)

    const [animate, setAnimate] = useState(false)

    useEffect(() => {
        let currentDate = new Date(startDatetime12h)
        currentDate.setHours(currentDate.getHours() + 1)
        let newEndDateTime = currentDate.toISOString()
        // Compare with the end date time from the state
        if (newEndDateTime !== endDatetime12h) {
            dispatch(setEndDateTime12h(newEndDateTime))
            setAnimate(true)
            setTimeout(() => setAnimate(false), 300)
        }
    }, [startDatetime12h, endDatetime12h, dispatch])

    useEffect(() => {
        if (daterange === null) {
            prevStartDateRef.current = null
            return
        }

        const [currentStartDate, currentEndDate] = [new Date(daterange[0]), new Date(daterange[1])]
        const prevStartDate = prevStartDateRef.current

        if (
            (prevStartDate === null && currentStartDate !== null) ||
            (prevStartDate !== null && currentStartDate.getTime() !== prevStartDate.getTime())
        ) {
            // Execute the code that should run when the start date changes
            updateStateBasedOnStartDate(currentStartDate)
        }

        // Update previous start date ref for the next render
        prevStartDateRef.current = currentStartDate

    }, [daterange])

    const updateStateBasedOnStartDate = (currentStartDate) => {
        const newEndDate = new Date(currentStartDate)
        newEndDate.setHours(currentStartDate.getHours() + 1)
        dispatch(setDaterange([currentStartDate.toISOString(), newEndDate.toISOString()]))
        dispatch(setStartDateTime12h(currentStartDate.toISOString()))
        setAnimate(true)
        setTimeout(() => setAnimate(false), 300)
    }



    return (
        <>
            <div className="field mb-4 col-12">
                <div className="py-2">
                    <label htmlFor="item" className="font-medium text-900">All Day Event</label>
                </div>
                <div className="py-2">
                    <InputSwitch checked={allday} onChange={(e) => dispatch(setAllday(e.value))} />
                </div>
                {!allday ? (
                    <>
                        <div className="py-2">
                            <label htmlFor="item" className="font-medium text-900">Datetime Range</label>
                        </div>
                        <div className="py-2">
                            <DateTimeRangePicker
                                onChange={newRange => dispatch(setDaterange([newRange[0].toISOString(), newRange[1].toISOString()]))}
                                value={daterange}
                                className={animate ? 'pulse-once' : ''}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="py-2">
                            <label htmlFor="item" className="font-medium text-900">Date</label>
                        </div>
                        <div className="py-2">
                            <DatePicker
                                onChange={newDate => dispatch(setDate(newDate.toISOString()))}
                                value={new Date(date)}
                                className={animate ? 'pulse-once' : ''}
                            />
                        </div>
                    </>
                )}
            </div>

            {!allday ? (
                <>
                    <div className="field mb-4 col-12">
                        <label htmlFor="item" className="font-medium text-900">Time Zone</label>

                        <div className="flex flex-wrap gap-3 mt-2">
                            {timeZones.map((zone, index) => (
                                <div key={zone.value} className="flex align-items-center">
                                    <RadioButton
                                        inputId={`zone${index + 1}`}
                                        name={zone.label}
                                        value={zone.value}
                                        onChange={(e) => dispatch(setTimeZone(e.value))}
                                        checked={timeZone === zone.value}
                                    />
                                    <label htmlFor={`zone${index + 1}`} className="ml-2">{zone.label}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            ) : null}
        </>
    )
}

export default DateTimeInfo