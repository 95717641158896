import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { InputNumber } from "primereact/inputnumber"
import { useContext, useEffect, useState } from "react"
import ToastContext from '../../ToastContext'
import api from "../../api"
import { useSelector } from "react-redux"

function ResolvedAttributeForm({ visible, setVisible, attributes, fetchAttributes }) {
    const matter = useSelector(state => state.caseDetails.matter)
    const showToast = useContext(ToastContext)
    const [grossReceived, setGrossReceived] = useState(null)
    const [clientReceived, setClientReceived] = useState(null)
    const [feesRecovered, setFeesRecovered] = useState(null)
    const [costsRecovered, setCostsRecovered] = useState(null)

    useEffect(() => {
        if (attributes && attributes.length > 0) {
            const findAttribute = (name) => {
                const attr = attributes.find(attribute => attribute.Attribute === name)
                return attr ? attr.Value : null;
            };
    
            setGrossReceived(findAttribute('Gross Received'));
            setClientReceived(findAttribute('Client Received'));
            setFeesRecovered(findAttribute('Fees Recovered'));
            setCostsRecovered(findAttribute('Costs Recovered'));
        }
    }, [attributes]);
    

    const submit = () => {
        console.log('submit')
        const payload = {
            'Gross Received': grossReceived,
            'Client Received': clientReceived,
            'Fees Recovered': feesRecovered,
            'Costs Recovered': costsRecovered,
            MatterNumber: matter.MatterNumber,
        }

        api.post('/resolution_attributes', payload)
            .then(response => {
                console.log(response.data)
                fetchAttributes()
                showToast({
                    severity: 'success',
                    summary: 'Successful',
                    detail: 'Resolution Attributes Saved'
                })
                setVisible(false)
            })
            .catch(error => {
                console.error('Error:', error)
                showToast({ severity: 'error', summary: 'Error', detail: error.message })
            })
    }

    const footerContent = (
        <div>
            <Button label='Save' icon="pi pi-check" outlined onClick={submit} autoFocus disabled={[grossReceived, clientReceived, feesRecovered, costsRecovered].every(value => value === null)} />
        </div>
    )

    return (
        <>
            <Dialog
                header='Resolution Attributes'
                visible={visible}
                onHide={() => setVisible(false)}
                style={{ width: '50vw' }}
                footer={footerContent}
            >
                <div className='grid formgrid p-fluid'>
                    <div className="field col-12">
                        <div className='grid formgrid p-fluid'>
                            <div className="field mb-4 col-12">
                                <div className="py-0">
                                    <label className="font-medium text-900">Gross Received</label>
                                </div>
                                <div className="py-2">
                                    <InputNumber value={grossReceived} onValueChange={(e) => setGrossReceived(e.value)} mode="currency" currency="USD" locale="en-US" />
                                </div>
                            </div>
                            <div className="field mb-4 col-12">
                                <div className="py-0">
                                    <label className="font-medium text-900">Client Received</label>
                                </div>
                                <div className="py-2">
                                    <InputNumber value={clientReceived} onValueChange={(e) => setClientReceived(e.value)} mode="currency" currency="USD" locale="en-US" />
                                </div>
                            </div>
                            <div className="field mb-4 col-12">
                                <div className="py-0">
                                    <label className="font-medium text-900">Fees Recovered</label>
                                </div>
                                <div className="py-2">
                                    <InputNumber value={feesRecovered} onValueChange={(e) => setFeesRecovered(e.value)} mode="currency" currency="USD" locale="en-US" />
                                </div>
                            </div>
                            <div className="field mb-4 col-12">
                                <div className="py-0">
                                    <label className="font-medium text-900">Costs Recovered</label>
                                </div>
                                <div className="py-2">
                                    <InputNumber value={costsRecovered} onValueChange={(e) => setCostsRecovered(e.value)} mode="currency" currency="USD" locale="en-US" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Dialog>
        </>
    )

}

export default ResolvedAttributeForm