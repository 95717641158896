import React, { useState, useContext, useEffect, useRef } from "react";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { Divider } from "primereact/divider";
import { Ripple } from "primereact/ripple";
import axios from 'axios';
import ToastContext from '../../ToastContext';
import { AutoComplete } from 'primereact/autocomplete';
import { Tag } from 'primereact/tag';
import api from "../../api";

const stages = {
    1: 'Pre-Demand',
    2: 'Demand Sent',
    3: 'Arbitration-AAA',
    4: 'Arbitration-JAMS',
    5: 'Arbitration-Other',
    6: 'State Court',
    7: 'Federal Court',
    8: 'Appeal',
    9: 'Post Judgment',
    10: 'Settled/Resolved',
    11: 'Withdrawn'
};

const reversedStages = Object.fromEntries(
    Object.entries(stages).map(([key, value]) => [value, parseInt(key)])
);

function getStageNumber(stageString) {
    return reversedStages[stageString];
}

function NewCase() {

    const showToast = useContext(ToastContext);

    const [matterNumber, setMatterNumber] = useState('');
    const [dateEngaged, setDateEngaged] = useState(new Date());
    const [stage, setStage] = useState(stages[1]);
    const [jurisdiction, setJurisdiction] = useState('');
    const [caseNumber, setCaseNumber] = useState('');

    const createCase = async () => {
        console.log('stage', stage)
        console.log('stage number', getStageNumber(stage))
        const data = {
            MatterNumber: matterNumber,
            DateEngaged: dateEngaged,
            Stage: getStageNumber(stage),
            Jurisdiction: jurisdiction,
            CaseNumber: caseNumber
        }

        api.post('/case', data).then((response) => {
            showToast({ severity: 'success', summary: 'Success', detail: 'Case Created' });
            // Open /case_details/matterNumber in new tab
            window.open(`/case_details/${matterNumber}`, '_blank');
        }).catch((error) => {
            showToast({ severity: 'error', summary: 'Error', detail: 'Case Creation Failed' });
        })
    };

    const resetForm = () => {
        setMatterNumber('');
        setDateEngaged(new Date());
        setStage(stages[1]);
        setJurisdiction('');
        setCaseNumber('');
    }


    const countryOptionTemplate = (option) => {
        return (
            <div className={`flex align-items-center`}>
                <span className={`inline-flex justify-content-center align-items-center mr-3 w-3rem h-3rem border-circle border-1 border-${option.color}-200`}>
                    <i className={`pi pi-calendar text-${option.color}-700`}></i>
                </span>
                <div>{option.name}</div>
            </div>
        );
    };

    return (
        <div>

            <div>
                    <span className="block text-3xl font-medium text-900 mb-4">New Case</span>
            </div>
            <div className=''>
                    <div className="grid formgrid p-fluid">

                        <div className="field mb-4 col-12 md:col-6">
                            <label htmlFor="matter_number" className="font-medium text-900">Matter Number</label>
                            <InputText id="matter_number" type="text" value={matterNumber} onChange={(e) => setMatterNumber(e.target?.value)} />
                        </div>
                        <div className="field mb-4 col-12 md:col-6">
                            <label htmlFor="jurisdiction" className="font-medium text-900">Jurisdiction</label>
                            <InputText id="jurisdiction" type="text" value={jurisdiction} onChange={(e) => setJurisdiction(e.target?.value)} />
                        </div>
                        <div className="field mb-4 col-12 md:col-6">
                            <label htmlFor="stage" className="font-medium text-900">Stage</label>
                            {/* <Dropdown id="stage" value={stage} options={stages} onChange={(e) => setStage(e.value)} optionLabel="name" optionValue="name" placeholder="Select a Stage" /> */}
                            <Dropdown
                                value={stage}
                                options={Object.entries(stages).map(([key, value]) => ({ label: value, value: value }))}
                                onChange={(e) => setStage(e.value)}
                            />
                        </div>
                        <div className="field mb-4 col-12 md:col-6">
                            <label htmlFor="case_number" className="font-medium text-900">Case Number</label>
                            <InputText id="case_number" type="text" value={caseNumber} onChange={(e) => setCaseNumber(e.target?.value)} />
                        </div>
                        <div className="field mb-4 col-12 md:col-6">
                            <label htmlFor="date_engaged" className="font-medium text-900">Date Engaged</label>
                            <Calendar id="date_engaged" value={dateEngaged} onChange={(e) => setDateEngaged(e.value)} dateFormat="mm/dd/yy" />
                        </div>

                        <div className="surface-border border-top-1 opacity-50 mb-4 col-12"></div>
                    </div>
                    <Button label="Create Case" icon="pi pi-Calendar" className="w-auto" onClick={createCase} />
                    <Button label="Reset" icon="pi pi-undo" className="w-auto ml-3" onClick={resetForm} outlined />
                </div>
            </div>
    )
}

export default NewCase