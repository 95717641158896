import { useSelector, useDispatch } from 'react-redux'
import api from '../../api'
import axios from 'axios'
import React, { useState, useContext, useEffect, useRef } from "react"
import {
    setClientName,
    setMatterNumber,
    setMatterNumbers,
    setMatters,
    setTitle,
    setTitle2,
    setStartDateTime12h,
    setEndDateTime12h,
    setSelectedCategory,
    setAllday,
    setDate,
    setDaterange,
    setNotes,
    setFileIds,
    setCreateTask,
    setSelectedTrelloMember,
    setDaysBeforeDeadline,
    setCurrentlyEditingEvent,
    setTimeZone,
    setUploadedFiles,
    setSelectedFiles,
    setEventsTableVisible,
    setLocation,
    setTrelloCardTitle,
} from '../../store/CalendarInput/action'
import moment, { max, min } from 'moment-timezone'
import { Button } from 'primereact/button'
import ToastContext from '../../ToastContext'

const categories = [
    { name: 'Our Deadline', color: 'red', colorId: 11 },
    { name: 'Opponent Deadline', color: 'green', colorId: 10 },
    { name: 'Court/Arbitrator Deadline', color: 'purple', colorId: 3 },
    { name: 'Our Appearance Required', color: 'yellow', colorId: 5 },
    { name: 'Anything Else', color: 'blue', colorId: 9 }
]

const timeZones = [
    {
        label: "Pacific Time",
        value: "America/Los_Angeles"
    },
    {
        label: "Mountain Time",
        value: "America/Denver"
    },
    {
        label: "Central Time",
        value: "America/Chicago"
    },
    {
        label: "Eastern Time",
        value: "America/New_York"
    }
]

const Actions = ({clientNameRef, matterNumberRef}) => {
    const dispatch = useDispatch()
    const showToast = useContext(ToastContext)

    const clientName = useSelector(state => state.calendarInput.clientName)
    const matterNumber = useSelector(state => state.calendarInput.matterNumber)
    const title = useSelector(state => state.calendarInput.title)
    const title2 = useSelector(state => state.calendarInput.title2)
    const selectedCategory = useSelector(state => state.calendarInput.selectedCategory)
    const allday = useSelector(state => state.calendarInput.allday)
    const date = useSelector(state => state.calendarInput.date)
    const daterange = useSelector(state => {
        let dr = state.calendarInput.daterange
        let dr1 = new Date(dr[0])
        let dr2 = new Date(dr[1])
        return [dr1, dr2]
    })
    const timeZone = useSelector(state => state.calendarInput.timeZone)
    const notes = useSelector(state => state.calendarInput.notes)
    const fileIds = useSelector(state => state.calendarInput.fileIds)
    const createTask = useSelector(state => state.calendarInput.createTask)
    const trelloDate = useSelector(state => state.calendarInput.trelloDate)
    const selectedTrelloMember = useSelector(state => state.calendarInput.selectedTrelloMember)
    const currentlyEditingEvent = useSelector(state => state.calendarInput.currentlyEditingEvent)
    const location = useSelector(state => state.calendarInput.location)
    const trelloCardTitle = useSelector(state => state.calendarInput.trelloCardTitle)
    const addNow = useSelector(state => state.calendarInput.addNow)


    const getMatters = async () => {
        api.get('matters')
            .then((mattersResponse) => {
                const matterNumbers = mattersResponse.data.map(matter => matter.MatterNumber)
                setMatters(mattersResponse.data)
                setMatterNumbers(matterNumbers)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const submitCalendarEntry = async () => {
        console.log('Submitting calendar entry...')
        console.log('fileIds:', fileIds)
        const customTitle = title

        function convertToSameWallTime(date, timeZone) {
            const momentObj = moment(date)
            return momentObj.tz(timeZone, true)
        }

        if (!timeZone && !allday) {
            showToast({ severity: 'error', summary: 'Error', detail: 'Please select a time zone' })
            return
        }

        let startTime, endTime
        try {
            startTime = convertToSameWallTime(daterange[0], timeZone).format()
            endTime = convertToSameWallTime(daterange[1], timeZone).format()
        } catch (error) {
            console.log(error)
        }

        const data = {
            clientName: clientName,
            matterNumber: matterNumber,
            title: title + ' - ' + title2,
            colorId: selectedCategory.colorId,
            allday: allday,
            date: date,
            startTime: startTime,
            endTime: endTime,
            notes: notes,
            fileIds: fileIds,
            customTitle: customTitle,
            createTask: createTask,
            trelloDate: trelloDate,
            selectedTrelloMember: selectedTrelloMember,
            location: location,
            trelloCardTitle: trelloCardTitle,
            addNow: addNow
        }
        try {
            if (currentlyEditingEvent) {
                const response = await api.put(`calendar/${currentlyEditingEvent.id}`, data)
                getMatters()
                console.log(response)
                resetForm()
                // handle success
                showToast({ severity: 'success', summary: 'Successful', detail: 'Calendar Entry Updated' })
                return
            } else {
                const response = await api.post('calendar', data)
                getMatters()
                console.log(response)
                resetForm()
                // handle success
                showToast({ severity: 'success', summary: 'Successful', detail: 'Calendar Entry Created' })
            }
        } catch (error) {
            console.log(error)
            // handle error
            showToast({ severity: 'error', summary: 'Error', detail: 'Unable to create/update calendar entry' })
        }
    }

    const resetForm = () => {
        dispatch(setClientName(''))
        dispatch(setMatterNumber(''))
        dispatch(setTitle(''))
        dispatch(setTitle2(''))
        dispatch(setStartDateTime12h((new Date()).toISOString()))
        let currentDate = new Date()
        currentDate.setHours(currentDate.getHours() + 1)
        dispatch(setEndDateTime12h(currentDate.toISOString()))
        dispatch(setSelectedCategory(categories[0]))
        dispatch(setAllday(false))
        dispatch(setDate((new Date()).toDateString()))
        dispatch(setDaterange([(new Date()).toISOString(), currentDate.toISOString()]))
        dispatch(setTimeZone(null))
        dispatch(setNotes(''))
        dispatch(setSelectedFiles(null))
        dispatch(setFileIds([]))
        dispatch(setUploadedFiles([]))
        matterNumberRef.current = ''
        clientNameRef.current = ''
        dispatch(setCurrentlyEditingEvent(null))
        dispatch(setCreateTask(false))
        dispatch(setSelectedTrelloMember(null))
        dispatch(setDaysBeforeDeadline(1))
        dispatch(setTrelloCardTitle(''))
        dispatch(setLocation(''))
    }

    return (
        <>
            <Button label={`${currentlyEditingEvent ? 'Update' : 'Create'} Calendar Entry`} icon="pi pi-Calendar"
                className="w-auto" onClick={submitCalendarEntry} severity={`${currentlyEditingEvent ? 'help' : 'primary'}`} />
            <Button label="Reset" icon="pi pi-undo" className="w-auto ml-3" onClick={resetForm} outlined />
            <Button label="Show Created Events" className="w-auto ml-3" size="small" outlined severity="help" onClick={() => dispatch(setEventsTableVisible(true))} />
        </>
    )
}

export default Actions