import { useSelector, useDispatch } from 'react-redux'
import api from '../../api'
import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import {
    setUploadedFiles,
    setFileIds,
    setNotes,
    setKey,
    setFileUploading,
} from '../../store/CalendarInput/action'
import { FileUpload } from 'primereact/fileupload'
import { InputTextarea } from 'primereact/inputtextarea'
import { Skeleton } from 'primereact/skeleton'
import { Button } from 'primereact/button'

const ExtraInfo = () => {
    const dispatch = useDispatch()
    const uploadedFiles = useSelector(state => state.calendarInput.uploadedFiles)
    const fileIds = useSelector(state => state.calendarInput.fileIds)
    const notes = useSelector(state => state.calendarInput.notes)
    const key = useSelector(state => state.calendarInput.key)
    const fileUploading = useSelector(state => state.calendarInput.fileUploading)
    const [totalSize, setTotalSize] = useState(0)
    const fileUploadRef = useRef(null)


    const removeFile = (file) => {
        const index = uploadedFiles.indexOf(file)
        const _uploadedFiles = [...uploadedFiles]
        _uploadedFiles.splice(index, 1)
        dispatch(setUploadedFiles(_uploadedFiles))

        const _fileIds = [...fileIds]
        _fileIds.splice(index, 1)
        dispatch(setFileIds(_fileIds))

        dispatch(setKey(Math.random().toString()))
    }

    useEffect(() => {
        console.log('uploadedFiles:', uploadedFiles)
    }, [uploadedFiles])

    const handleFileUpload = async ({ files }) => {
        dispatch(setFileUploading(true))
        const uploaded = []
        const _fileIds = []

        // Create an array of upload promises
        const uploadPromises = files.map(file => {
            return new Promise(async (resolve, reject) => {
                try {
                    const formData = new FormData()
                    formData.append('file', file)

                    const response = await api.post('google_drive_upload', formData)

                    if (response.data && response.data.fileId) {
                        _fileIds.push(response.data.fileId)
                        uploaded.push(file)
                    }
                    resolve()
                } catch (error) {
                    console.error(error)
                    reject(error)
                }
            })
        })

        // Wait for all uploads to complete
        await Promise.all(uploadPromises)

        dispatch(setFileUploading(false))

        // Get the current state values
        const currentFileIds = fileIds
        const currentUploadedFiles = uploadedFiles

        // Calculate the new state values
        const newFileIds = [...currentFileIds, ..._fileIds];
        const newUploadedFiles = [...currentUploadedFiles, ...uploaded];

        // Dispatch the new serializable values
        dispatch(setFileIds(newFileIds));
        dispatch(setUploadedFiles(newUploadedFiles));
        dispatch(setKey(Math.random().toString()));
    }

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options
        const value = totalSize / 10000
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B'

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {/* {uploadButton}
                {cancelButton} */}
            </div>
        )
    }

    return (
        <>

            <div className="field mb-4 col-12 lg:col-6">
                <label htmlFor="notes" className="font-medium text-900">Notes</label>
                <InputTextarea id="notes" spellCheck="true" value={notes} onChange={(e) => dispatch(setNotes(e.target?.value))} autoResize rows={8} />
            </div>
            <div className="field mb-4 col-12 lg:col-6">
                <label htmlFor="notes" className="font-medium text-900">Files</label>
                <FileUpload
                    key={key}
                    name="demo[]"
                    multiple
                    // mode="basic"
                    accept="*"
                    auto={true}
                    maxFileSize={10000000}
                    customUpload={true}
                    uploadHandler={handleFileUpload}
                    headerTemplate={headerTemplate}
                    emptyTemplate={<p className="m-0">Drag and drop files here to upload.</p>}
                />
            </div>

            {((uploadedFiles && Array.isArray(uploadedFiles) && uploadedFiles.length > 0) || fileUploading) && (
                <div className="field mb-4 col-12">
                    <label htmlFor="notes" className="font-medium text-900">Uploaded Files</label>
                    <div className="surface-card border-round border-1 border-300 overflow-x-hidden">
                        <ul className="p-0 mx-0 mt-0 mb-0 list-none">
                            {uploadedFiles.map((file, index) => (
                                <li className="flex align-items-center py-2 border-bottom-1 surface-border" key={index}>
                                    <Button icon="pi pi-times" size="small" className="p-button-text p-button-rounded p-button-danger mr-3" onClick={() => { removeFile(file) }} />
                                    {(file.objectURL || file.iconLink) && (
                                        <div className="w-1rem h-1rem flex align-items-center justify-content-center mx-3 flex-shrink-0">
                                            <img alt={file.name} role="presentation" src={file.objectURL || file.iconLink} width={30} />
                                        </div>
                                    )}
                                    <span className="text-900 line-height-3 font-medium">
                                        {file.name}
                                    </span>
                                </li>
                            ))}
                        </ul>

                        {fileUploading && (
                            <div className="p-4 w-full">
                                <span>
                                    <Skeleton width="2rem" height="2rem" borderRadius="5px" className="inline-block"></Skeleton>
                                </span>
                                <span>
                                    <Skeleton height="2rem" width="20rem" className="ml-2 inline-block" borderRadius="16px"></Skeleton>
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            )}

        </>
    )
}

export default ExtraInfo