import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import api from './api';

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const validateToken = async () => {
    try {
      const response = await api.get('/validate')
      setIsAuthenticated(response.data.valid);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false); // set loading to false after checking the token
    }
  }
  
  useEffect(() => {
    validateToken();
  }, []);

  if (isLoading) {
    // return loading spinner or similar until auth check is complete
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, validateToken }}>
      {children}
    </AuthContext.Provider>
  );
}


