import React, { useContext, useEffect, useState } from 'react';
import api from '../../../api';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import ToastContext from '../../../ToastContext';

function AddNoteForm({ noteForm, setNoteForm, matterNumber, fetchActivities }) {
    const showToast = useContext(ToastContext);

    const [noteContent, setNoteContent] = useState('')
    const [user, setUser] = useState(null);

    useEffect(() => {
        api.get('/user').then(response => {
            setUser(response.data);
            console.log(response.data);
        }).catch(error => {
            console.error(error);
        });
    }, []);

    useEffect(() => {
        console.log(noteForm);
        setNoteContent(noteForm.currentNote);
    }, [noteForm]);

    const submit = () => {
        console.log('submit');
        const payload = {
            ActivityType: 'Note',
            Date: new Date(noteForm.date) || new Date(),
            Details: noteContent,
            AttorneyName: user.Name,
            MatterNumber: matterNumber,
            id: noteForm.currentNoteId
        };

        const apiMethod = noteForm.isEditing ? api.put : api.post;
        const endpoint = noteForm.isEditing ? `/activity/${noteForm.currentNote.id}` : `/activity`;

        apiMethod(endpoint, payload)
            .then(response => {
                console.log(response.data);
                fetchActivities();
                showToast({
                    severity: 'success',
                    summary: 'Successful',
                    detail: noteForm.isEditing ? 'Note updated' : 'Note added to activity log'
                });
                setNoteForm({
                    visible: false,
                    isEditing: false,
                    currentNote: null
                });
            })
            .catch(error => {
                console.error('Error:', error);
                showToast({ severity: 'error', summary: 'Error', detail: error.message });
            });
    }

    const deleteNote = () => {
        api.delete('/activity', { data: { id: noteForm.currentNoteId } })
            .then(response => {
                if (response.status === 201) {  // Added status check
                    fetchActivities();
                    showToast({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Note deleted'
                    });
                    setNoteForm({
                        ...noteForm,
                        visible: false,
                        isEditing: false,
                        currentNote: null
                    });
                } else {
                    showToast({ severity: 'error', summary: 'Error', detail: 'Unexpected status code' });
                }
            })
            .catch(error => {
                console.error('Error:', error);
                showToast({ severity: 'error', summary: 'Error', detail: error.message });
            });
    }

    const footerContent = (
        <div>
            {noteForm.isEditing && <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={deleteNote} />}
            <Button label={noteForm.isEditing ? 'Update' : 'Submit'} icon="pi pi-check" onClick={submit} autoFocus />
        </div>
    );

    return (
        <>
            <Dialog header={noteForm.isEditing ? 'Edit Note' : 'Add Note'}
                visible={noteForm.visible}
                style={{ width: '50vw' }}
                onHide={() => setNoteForm({ ...noteForm, visible: false })}
                footer={footerContent}>
                <div className='grid formgrid p-fluid'>
                    <div className="field mb-4 col-12">
                        <InputTextarea id="noteContent" value={noteContent} onChange={(e) => setNoteContent(e.target?.value)}
                            autoResize
                        />
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default AddNoteForm