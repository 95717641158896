import axios from 'axios';
import Cookies from 'js-cookie';

const developmentEndpoint = 'http://localhost:5100/api';
const productionEndpoint = 'https://internal.kneuppercovey.com/api';

const getEndpoint = () => {
    if (process.env.NODE_ENV === 'development') {
        return developmentEndpoint;
    } else {
        return productionEndpoint;
    }
};

const api = axios.create({
    baseURL: getEndpoint(),
    withCredentials: true, // Add other common configurations if needed
});

api.interceptors.response.use(
    response => {
        console.log('Response:', response);
        return response;
    },
    async error => {
        const originalRequest = error.config;

        if (
            error.response.status === 401 &&
            error.response.data.msg === "Token has expired" &&
            !originalRequest._retry
        ) {
            originalRequest._retry = true;

            try {
                // Attempt refresh token
                console.log('Attempting token refresh...');
                const refreshResponse = await api.post('refresh_token');
                console.log('Refresh token successful:', refreshResponse);

                // Update access token in original request and retry
                const newAccessToken = refreshResponse.data.access_token;
                api.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
                originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

                console.log('Retrying original request with refreshed access token:', originalRequest);

                return api(originalRequest);
            } catch (_error) {
                console.error('Failed to refresh token:', _error);
                // Handle refresh failure, like redirect to login, show error message, etc.
            }
        }

        console.error('Request error:', error);

        return Promise.reject(error);
    }
);

export default api;
