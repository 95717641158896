import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { InputNumber } from "primereact/inputnumber"
import { useContext, useEffect, useState } from "react"
import ToastContext from '../../ToastContext'
import api from "../../api"
import { useSelector } from "react-redux"
import { Calendar } from "primereact/calendar"
import { InputText } from "primereact/inputtext"
import { Checkbox } from "primereact/checkbox"

function DemandSentAttributeForm({ visible, setVisible, attributes, fetchAttributes }) {
    const matter = useSelector(state => state.caseDetails.matter);
    const showToast = useContext(ToastContext)
    const [dateSent, setDateSent] = useState(null)
    const [trackingInformation, setTrackingInformation] = useState(null)
    const [dateResponseDue, setDateResponseDue] = useState(null)
    const [trelloCardNeeded, setTrelloCardNeeded] = useState(null)
    const [trelloCardTitle, setTrelloCardTitle] = useState(null)
    const [trelloCardDueDate, setTrelloCardDueDate] = useState(null)

    useEffect(() => {
        if (attributes && attributes.length > 0) {
            const findAttribute = (name) => {
                const attr = attributes.find(attribute => attribute.Attribute === name);
                return attr ? attr.Value : null;
            };

            const _dateSent = findAttribute('Date Demand Letter Sent') ? new Date(findAttribute('Date Demand Letter Sent')) : null;
            const _dateResponseDue = findAttribute('Date Response Due') ? new Date(findAttribute('Date Response Due')) : null;

            setDateSent(_dateSent);
            setTrackingInformation(findAttribute('Tracking Information'));
            setDateResponseDue(_dateResponseDue);
            setTrelloCardNeeded(findAttribute('Trello Card Needed'));
        }
    }, [attributes]);


    const submit = () => {
        console.log('submit')
        const payload = {
            'Date Demand Letter Sent': dateSent,
            'Tracking Information': trackingInformation,
            'Date Response Due': dateResponseDue,
            'Trello Card Needed': trelloCardNeeded,
            'Trello Card Title': trelloCardTitle,
            'Trello Card Due Date': trelloCardDueDate,
            MatterNumber: matter.MatterNumber,
        }

        api.post('/demand_sent_attributes', payload)
            .then(response => {
                console.log(response.data)
                fetchAttributes()
                showToast({
                    severity: 'success',
                    summary: 'Successful',
                    detail: 'Demand Sent Attributes Saved'
                })
                setVisible(false)
            })
            .catch(error => {
                console.error('Error:', error)
                showToast({ severity: 'error', summary: 'Error', detail: error.message })
            })
    }

    const footerContent = (
        <div>
            <Button label='Save' icon="pi pi-check" outlined onClick={submit} autoFocus disabled={[dateSent, trackingInformation, dateResponseDue, trelloCardNeeded].every(value => value === null)} />
        </div>
    )

    return (
        <>
            <Dialog
                header='Demand Sent Attributes'
                visible={visible}
                onHide={() => setVisible(false)}
                style={{ width: '50vw' }}
                footer={footerContent}
            >
                <div className='grid formgrid p-fluid'>
                    <div className="field col-12">
                        <div className='grid formgrid p-fluid'>
                            <div className="field mb-4 col-12">
                                <div className="py-0">
                                    <label className="font-medium text-900">Date Demand Letter Sent</label>
                                </div>
                                <div className="py-2">
                                    <Calendar value={dateSent} onChange={(e) => setDateSent(e.value)} />
                                </div>
                            </div>
                            <div className="field mb-4 col-12">
                                <div className="py-0">
                                    <label className="font-medium text-900">Tracking Information</label>
                                </div>
                                <div className="py-2">
                                    <InputText value={trackingInformation} onValueChange={(e) => setTrackingInformation(e.value)} />
                                </div>
                            </div>
                            <div className="field mb-4 col-12">
                                <div className="py-0">
                                    <label className="font-medium text-900">Date Response Due</label>
                                </div>
                                <div className="py-2">
                                    <Calendar value={dateResponseDue} onChange={(e) => setDateResponseDue(e.value)} />
                                </div>
                            </div>
                            <div className="field mb-4 col-12">
                                <div className="py-0">
                                    <label className="font-medium text-900">Trello Card Needed</label>
                                </div>
                                <div className="py-2">
                                    <Checkbox checked={trelloCardNeeded} onChange={(e) => setTrelloCardNeeded(e.checked)} />
                                </div>
                            </div>
                            {trelloCardNeeded && (
                                <>
                                    <div className="field mb-4 col-12">
                                        <div className="py-0">
                                            <label className="font-medium text-900">Trello Card Title</label>
                                        </div>
                                        <div className="py-2">
                                            <InputText value={trelloCardTitle} onValueChange={(e) => setTrelloCardTitle(e.value)} />
                                        </div>
                                    </div>
                                    <div className="field mb-4 col-12">
                                        <div className="py-0">
                                            <label className="font-medium text-900">Trello Card Due Date</label>
                                        </div>
                                        <div className="py-2">
                                            <Calendar value={trelloCardDueDate} onChange={(e) => setTrelloCardDueDate(e.value)} />
                                        </div>
                                    </div>
                                </>


                            )}
                        </div>

                    </div>
                </div>
            </Dialog>
        </>
    )

}

export default DemandSentAttributeForm