import { Button } from 'primereact/button';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function NotFoundPage () {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    const handleGoToDashboard = () => {
        navigate('/');
    };

    return (
    
        <div className="flex align-content-center surface-section h-screen">
            <div className="w-12 sm:w-6 px-4 py-8 md:px-6 lg:px-8 flex align-content-center justify-content-center">
                <div className='flex align-items-center'>
                    <div>
                        <div className="border-left-2 border-pink-500">
                            <span className="bg-white text-pink-500 font-bold text-2xl inline-block px-3">404</span>
                        </div>
                        <div className="mt-6 mb-5 font-bold text-6xl text-900">Page Not Found</div>
                        <p className="text-700 text-3xl mt-0 mb-6">Sorry, we couldn't find the page.</p>
                        <div>
                            <Button className="p-button-text mr-2" label="Go Back" icon="pi pi-arrow-left" onClick={handleGoBack} />
                            <Button label="Go Home" icon="pi pi-home" onClick={handleGoToDashboard} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-6 hidden sm:block bg-center" style={{ background: 'url("/briefcase-404.png") no-repeat', backgroundSize: 'cover' }}>
            </div>
        </div>
    );
}

export default NotFoundPage;