import { 
    SET_TIME_ZONE,
    SET_CLIENT_NAME,
    SET_MATTER_NUMBER,
    SET_TITLE,
    SET_TITLE2,
    SET_SELECTED_CATEGORY,
    SET_SELECTED_FILES,
    SET_START_DATETIME_12H,
    SET_END_DATETIME_12H,
    SET_ALLDAY,
    SET_DATE,
    SET_DATERANGE,
    SET_NOTES,
    SET_FILE_IDS,
    SET_CREATE_TASK,
    SET_SELECTED_TRELLO_MEMBER,
    SET_DAYS_BEFORE_DEADLINE,
    SET_TRELLO_DATE,
    SET_EVENTS_TABLE_VISIBLE,
    SET_CURRENTLY_EDITING_EVENT,
    SET_MATTERS,
    SET_MATTER_NUMBERS,
    SET_CLIENTS,
    SET_DEFENDANTS,
    SET_FILTERED_CLIENTS,
    SET_FILTERED_MATTERS,
    SET_TOTAL_SIZE,
    SET_UPLOADED_FILES,
    SET_FILE_UPLOADING,
    SET_KEY,
    SET_ADD_NOW,
    SET_LOCATION,
    SET_TRELLO_CARD_TITLE,
} from './action';

const categories = [
    { name: 'Our Deadline', color: 'red', colorId: 11 },
    { name: 'Opponent Deadline', color: 'green', colorId: 10 },
    { name: 'Court/Arbitrator Deadline', color: 'purple', colorId: 3 },
    { name: 'Our Appearance Required', color: 'yellow', colorId: 5 },
    { name: 'Anything Else', color: 'blue', colorId: 9 }
];

const timeZones = [
    {
        label: "Pacific Time",
        value: "America/Los_Angeles"
    },
    {
        label: "Mountain Time",
        value: "America/Denver"
    },
    {
        label: "Central Time",
        value: "America/Chicago"
    },
    {
        label: "Eastern Time",
        value: "America/New_York"
    }
]


const initialState = {
    timeZone: null,
    timeZones: timeZones,
    categories: categories,
    clientName: '',
    matterNumber: '',
    title: '',
    title2: '',
    selectedCategory: categories[0],
    selectedFiles: null,
    startDatetime12h: (new Date()).toISOString(),
    endDatetime12h: (new Date(new Date().setHours(new Date().getHours() + 1))).toISOString(),
    allday: false,
    date: (new Date()).toISOString(),
    daterange: [(new Date()).toISOString(), (new Date(new Date().setHours(new Date().getHours() + 1))).toISOString()],
    notes: '',
    fileIds: [],
    createTask: false,
    selectedTrelloMember: null,
    daysBeforeDeadline: 1,
    trelloDate: (new Date()).toISOString(),
    eventsTableVisible: false,
    currentlyEditingEvent: null,
    matters: [],
    matterNumbers: [],
    clients: [],
    defendants: [],
    filteredClients: [],
    filteredMatters: [],
    totalSize: 0,
    uploadedFiles: [],
    fileUploading: false,
    key: Math.random().toString(),
    addNow: false,
  };

const calendarInputReducer = (state = initialState, action) => {
	switch (action.type) {
        case SET_TIME_ZONE:
          return { ...state, timeZone: action.payload };
        case SET_CLIENT_NAME:
          return { ...state, clientName: action.payload };
        case SET_MATTER_NUMBER:
            return { ...state, matterNumber: action.payload };
        case SET_TITLE:
            return { ...state, title: action.payload };
        case SET_TITLE2:
            return { ...state, title2: action.payload };
        case SET_SELECTED_CATEGORY:
            return { ...state, selectedCategory: action.payload };
        case SET_SELECTED_FILES:
            return { ...state, selectedFiles: action.payload };
        case SET_START_DATETIME_12H:
            return { ...state, startDatetime12h: action.payload };
        case SET_END_DATETIME_12H:
            return { ...state, endDatetime12h: action.payload };
        case SET_ALLDAY:
            return { ...state, allday: action.payload };
        case SET_DATE:
            return { ...state, date: action.payload };
        case SET_DATERANGE:
            return { ...state, daterange: action.payload };
        case SET_NOTES:
            return { ...state, notes: action.payload };
        case SET_FILE_IDS:
            return { ...state, fileIds: action.payload };
        case SET_CREATE_TASK:
            return { ...state, createTask: action.payload };
        case SET_SELECTED_TRELLO_MEMBER:
            return { ...state, selectedTrelloMember: action.payload };
        case SET_DAYS_BEFORE_DEADLINE:
            return { ...state, daysBeforeDeadline: action.payload };
        case SET_TRELLO_DATE:
            return { ...state, trelloDate: action.payload };
        case SET_EVENTS_TABLE_VISIBLE:
            return { ...state, eventsTableVisible: action.payload };
        case SET_CURRENTLY_EDITING_EVENT:
            return { ...state, currentlyEditingEvent: action.payload };
        case SET_MATTERS:
            return { ...state, matters: action.payload };
        case SET_MATTER_NUMBERS:
            return { ...state, matterNumbers: action.payload };
        case SET_CLIENTS:
            return { ...state, clients: action.payload };
        case SET_DEFENDANTS:
            return { ...state, defendants: action.payload };
        case SET_FILTERED_CLIENTS:
            return { ...state, filteredClients: action.payload };
        case SET_FILTERED_MATTERS:
            return { ...state, filteredMatters: action.payload };
        case SET_TOTAL_SIZE:
            return { ...state, totalSize: action.payload };
        case SET_UPLOADED_FILES:
            return { ...state, uploadedFiles: action.payload };
        case SET_FILE_UPLOADING:
            return { ...state, fileUploading: action.payload };
        case SET_KEY:
            return { ...state, key: action.payload };
        case SET_ADD_NOW:
            return { ...state, addNow: action.payload };
        case SET_LOCATION:
            return { ...state, location: action.payload };
        case SET_TRELLO_CARD_TITLE:
            return { ...state, trelloCardTitle: action.payload };
        default:
          return state;
      }
}

export default calendarInputReducer
