import React, { useEffect, useRef, useState, useContext } from "react";
import { InputText } from "primereact/inputtext";
import { Badge } from "primereact/badge";
import { StyleClass } from "primereact/styleclass";
import { Ripple } from "primereact/ripple";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { Divider } from "primereact/divider";
import { TieredMenu } from 'primereact/tieredmenu';
import { AuthContext } from '../AuthContext';
import logo from '../assets/img/cropped-icon-1-192x192.png';
import api from "../api";

function SidebarLayout({ children }) {
    const { isAuthenticated, setIsAuthenticated, validateToken } = useContext(AuthContext);
    let navigate = useNavigate();

    const btnRef44 = useRef(null);
    const btnRef45 = useRef(null);
    const btnRef46 = useRef(null);
    const btnRef47 = useRef(null);
    const menu = useRef(null);

    const location = useLocation();
    const activeClass = "p-ripple flex align-items-center cursor-pointer p-3 border-round text-primary-500";
    const inactiveClass = "p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors";

    const [user, setUser] = useState(null);
    const [ready, setReady] = useState(false);

    useEffect(() => {
        setTimeout(() => setReady(true), 300);
    }, [])

    useEffect(() => {
        api.get('/user').then(response => {
            setUser(response.data);
        }
        ).catch(error => {
            console.error(error);
        })
    }, [])

    useEffect(() => {
        if (!isAuthenticated) {
            // Wait for the toast to be hidden before navigating
            setTimeout(() => navigate('/login'), 1000);
        }
    }, [isAuthenticated, navigate]);

    const logout = () => {
        api.post('/logout').then(response => {
            setIsAuthenticated(false);
            navigate('/login');
        }
        ).catch(error => {
            console.error(error);
        })
    }

    return (
        <div className="min-h-screen flex relative lg:static surface-ground">
            <div id="app-sidebar-10" className="h-screen hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 select-none surface-section border-right-1 surface-border" style={{ width: '280px' }}>
                <div className="flex flex-column h-full">
                    <div className="flex align-items-center px-5 flex-shrink-0" style={{ height: '60px' }}>
                        {/* <img src={logo} alt="Logo" height="30" /> */}
                        <p className="text-700 text-xl font-bold pt-7">Kneupper & Covey Internal</p>
                    </div>
                    <div className="overflow-y-auto">
                        {/* <div className="p-3">
                            <div className="p-input-icon-left w-full">
                                <i className="pi pi-search"></i>
                                <InputText className="border-1 surface-border w-full" placeholder="Search" style={{ borderRadius: '30px' }} />
                            </div>
                        </div> */}
                        <Divider />
                        <ul className="list-none p-3 m-0">
                            <li>
                                {/* <div className="p-3 flex align-items-center justify-content-between text-600r">
                                    <span className="font-bold text-800">FAVORITES</span>
                                    <Ripple />
                                </div> */}
                                <ul className="list-none p-0 m-0 overflow-hidden no-underline">
                                    <li>
                                        <Link
                                            to="/matters"
                                            className={(location.pathname === "/" || location.pathname == "/matters") ? activeClass : inactiveClass}
                                        >
                                            <i className="pi pi-table mr-2"></i>
                                            <span className="font-medium">Matters</span>
                                            <Ripple />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="/calendar_input"
                                            className={location.pathname === "/calendar_input" ? activeClass : inactiveClass}
                                        >
                                            <i className="pi pi-calendar mr-2"></i>
                                            <span className="font-medium">Calendar Input</span>
                                            <Ripple />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="/utilities"
                                            className={location.pathname === "/utilities" ? activeClass : inactiveClass}
                                        >
                                            <i className="pi pi-wrench mr-2"></i>
                                            <span className="font-medium">Utilities</span>
                                            <Ripple />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="/admin"
                                            className={location.pathname === "/admin" ? activeClass : inactiveClass}
                                        >
                                            <i className="pi pi-cog mr-2"></i>
                                            <span className="font-medium">Admin</span>
                                            <Ripple />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="/trello_tasks"
                                            className={location.pathname === "/trello_tasks" ? activeClass : inactiveClass}
                                        >
                                            <i className="pi pi-check-square mr-2"></i>
                                            <span className="font-medium">Trello Tasks</span>
                                            <Ripple />
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        {user && (
                            <div className="mt-auto">
                                <hr className="mb-3 mx-3 border-top-1 border-none surface-border" />
                                <TieredMenu model={[{
                                    label: 'Logout',
                                    icon: 'pi pi-fw pi-power-off',
                                    command: () => { logout() },  // Call the logout function here
                                }]} popup ref={menu} breakpoint="767px" />
                                <a className="p-ripple m-3 flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                    style={{ borderRadius: '12px' }}
                                    onClick={(e) => menu.current.toggle(e)}>
                                    <img src={user.ProfilePic} className="mr-2 border-circle" style={{ width: '28px', height: '28px' }} alt="avatar-f-1" />
                                    <span className="font-medium">{user.Name}</span>
                                    <Ripple />
                                </a>
                            </div>
                        )}

                    </div>
                    {/* <div className="mt-auto">
                        <a className="p-ripple m-3 flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors">
                            <img src="/demo/images/blocks/avatars/circle/avatar-f-1.png" className="mr-2" style={{ width: '28px', height: '28px' }} alt="avatar-f-1" />
                            <span className="font-medium">Amy Elsner</span>
                            <Ripple />
                        </a>
                    </div> */}
                </div>
            </div>
            {ready && (
                <div className="min-h-screen flex flex-column relative flex-auto">
                    <div className="flex align-items-center justify-content-between lg:justify-content-start px-5 relative lg:hidden" style={{ height: '60px' }}>
                        <StyleClass nodeRef={btnRef46} selector="#app-sidebar-10" enterClassName="hidden" enterActiveClassName="fadeinleft" leaveToClassName="hidden" leaveActiveClassName="fadeoutleft" hideOnOutsideClick>
                            <a ref={btnRef46} className="p-ripple cursor-pointer block lg:hidden text-700 mr-3">
                                <i className="pi pi-bars text-4xl"></i>
                                <Ripple />
                            </a>
                        </StyleClass>
                        <StyleClass nodeRef={btnRef47} selector="@next" enterClassName="hidden" enterActiveClassName="fadein" leaveToClassName="hidden" leaveActiveClassName="fadeout" hideOnOutsideClick>
                            <a ref={btnRef47} className="p-ripple cursor-pointer block lg:hidden text-700">
                                <i className="pi pi-ellipsis-v text-2xl"></i>
                                <Ripple />
                            </a>
                        </StyleClass>

                    </div>
                    <div className=" flex flex-column flex-auto">
                        <div className="flex-auto" style={{ maxHeight: "calc(100vh - 0px)", overflowY: "auto" }}>
                            {children}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default SidebarLayout;