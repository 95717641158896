import React, { useRef, useContext, useState, useEffect } from 'react';
import ToastContext from '../../ToastContext';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import api from '../../api';

function FileUploadURL() {
    const showToast = useContext(ToastContext);
    const [pipedriveId, setPipedriveId] = useState(null)
    const [fileUploadUrl, setFileUploadUrl] = useState(null)

    useEffect(() => {
        const debounceDelay = 200; // 500 milliseconds
        const timerId = setTimeout(() => {
            if (pipedriveId) {
                api.get(`/file_upload_url/${pipedriveId}`)
                    .then(res => {
                        setFileUploadUrl(res.data.file_upload_url);
                    })
                    .catch(err => {
                        showToast({ severity: 'error', summary: 'Error', detail: 'Error getting file upload URL' });
                    });
            } else {
                setFileUploadUrl(null);
            }
        }, debounceDelay);

        // Clear the timeout if the component is unmounted or if pipedriveId changes
        return () => clearTimeout(timerId);
    }, [pipedriveId]); // Dependency array

    return (
        <>
            <span className="block text-3xl font-medium text-900 mb-4">File Upload URL</span>
            <div className="grid formgrid p-fluid">

                <div className="field mb-4 col-6">
                    <label htmlFor="client_name" className="font-medium text-900">Pipedrive ID</label>
                    <InputText value={pipedriveId} onChange={(e) => setPipedriveId(e.target.value)} placeholder="Pipedrive ID" />
                </div>
                <div className="field mb-4 col-12">
                    <label htmlFor="client_name" className="font-medium text-900">File Upload URL</label>

                    <div>
                        <div className="flex items-center py-3">
                            <div className="flex-1 min-w-0">
                                <div
                                    className='text-gray-500'
                                    style={{
                                        // border: '2px solid #2a70a5',
                                        // padding: '20px 0',
                                        whiteSpace: 'nowrap',
                                        width: '100%',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        // fontSize: '50px'
                                    }}>
                                    {fileUploadUrl}
                                </div>
                            </div>
                        </div>
                        <div>
                            <Button
                                className="w-max bg-primary text-white p-2 rounded"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    navigator.clipboard.writeText(fileUploadUrl);
                                    showToast({ severity: 'success', summary: 'Copied', detail: 'File Upload URL copied to clipboard' });
                                }}
                                disabled={!fileUploadUrl}
                            >
                                Copy
                            </Button>
                    </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default FileUploadURL