import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import api from '../api';
import ToastContext from '../ToastContext';
import { GoogleOAuthProvider, GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { AuthContext } from '../AuthContext';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

let google_client_id = null
if (process.env.NODE_ENV === 'development') {
    google_client_id = "906611250509-r2scuo0tc51tlv78fm5u7l1tg4l4e9i8.apps.googleusercontent.com" // KC Test (Part of Kevin's organization)
    google_client_id = "586707673353-qks5slhuu2dcs1qqaan12j30cnb34rnf.apps.googleusercontent.com" // KC Internal Test (Part of kneuppercovey.com organization)
} else {
    google_client_id = "799562548601-s6h4sc8cij4j8bpr53nb1la4co8aqp0v.apps.googleusercontent.com" // Cyclone set this one up
}

function Goog() {
    const showToast = useContext(ToastContext);
    const { isAuthenticated, setIsAuthenticated, validateToken } = useContext(AuthContext);


    useEffect(() => {
        validateToken();
    }, [validateToken]);


    const login = useGoogleLogin({
        onSuccess: (response) => {
            const { code } = response;
            api.post('/login', { code }, { withCredentials: true }).then(response => {
                if (response.data.message === 'User logged in') {
                    showToast({ severity: 'success', summary: 'Successful', detail: 'Welcome in' });
                    setIsAuthenticated(true);
                } else {
                    showToast({ severity: 'error', summary: 'Error', detail: 'Unable to sign in' });
                }
            }).catch(error => {
                console.error(error);
                showToast({ severity: 'error', summary: 'Error', detail: error.toString() });
            });
        },
        onError: (error) => {
            console.error(error);
            // send error to your backend for debugging
        },
        flow: 'auth-code',
        scope: 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/drive'
    });

    return (
        <div>
            <Button onClick={login} icon="pi pi-google" label="Sign in with Google" />
        </div>
    )
}

function Login() {
    const showToast = useContext(ToastContext);
    const { isAuthenticated, setIsAuthenticated, validateToken } = useContext(AuthContext);
    let navigate = useNavigate();
    let location = useLocation();
    let from = location?.pathname || "/";

    useEffect(() => {
        document.title = "Login"
        localStorage.setItem('postLoginRedirectPath', location.state?.from?.pathname || '/');
        const validateToken = async () => {
            try {
                const response = await api.get('/validate')
                setIsAuthenticated(response.data.valid);
            } catch (error) {
                console.error(error);
            }
        }
        validateToken();
    }, []);

    useEffect(() => {
        console.log('from:', from)
        if (isAuthenticated) {
            // Wait for the toast to be hidden before navigating
            setTimeout(() => {
                const redirectPath = localStorage.getItem('postLoginRedirectPath') || '/';
                navigate(redirectPath, { replace: true });
                localStorage.removeItem('postLoginRedirectPath');
            }, 1000);
        }
    }, [isAuthenticated, navigate]);



    return (
        <GoogleOAuthProvider clientId={google_client_id}>
            <div className="w-screen h-screen">
                <div className="surface-ground px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center w-full h-full">
                    <div className="p-col-12 p-md-6 p-lg-4">
                        <div className="p-card p-fluid p-4">
                            <div className="flex flex-column align-items-center">
                                <div style={{ width: '48px', height: '48px', borderRadius: '50px' }} className="bg-primary-100 flex align-items-center justify-content-center mb-3">
                                    <i className="pi pi-lock text-primary-500 text-3xl"></i>
                                </div>
                            </div>
                            <p className="p-text-center text-xl font-bold p-0 mt-0">Sign in to your account</p>
                            {/* <GoogleLogin
                                onSuccess={responseGoogle}
                                onError={responseGoogle}
                                className='p-mt-4 w-full'
                            /> */}
                            <Goog />
                        </div>
                    </div>
                </div>
            </div>
        </GoogleOAuthProvider >
    );
}



export default Login