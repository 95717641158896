import { InputText } from 'primereact/inputtext'
import React, { useRef, useContext, useState, useEffect } from 'react'
import api from "../../api";

const sampleMessages = [
    {
        sender: 'John Doe',
        message: 'Hello, how are you?'
    },
    {
        sender: 'AI',
        message: 'I am good, thank you.'
    }
]

function DocumentChat() {
    const [messages, setMessages] = useState(sampleMessages)
    const [user, setUser] = useState(null)

    useEffect(() => {
        api.get('/user').then(response => {
            setUser(response.data);
        }
        ).catch(error => {
            console.error(error);
        })
    }, [])

    const sendMessage = (e) => {
        setMessages([...messages, { sender: user.Name, message: e.target.value }])
    }



    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100vh',
                    width: '100%',
                    overflow: 'hidden',
                }}
            >
                <div
                    style={{
                        padding: '1rem 1rem',
                        boxSizing: 'border-box',
                        maxWidth: '100%',
                    }}
                >
                    <div className="surface-card p-0 shadow-2 border-round overflow-hidden matters-table"
                        style={{
                            boxSizing: 'border-box',
                            height: 'calc(100vh - 30px)',
                            width: 'calc(100vw - 310px)'
                        }}
                    >
                        <div
                            style={{
                                height: 'calc(100% - 100px)',
                                width: '100%'
                            }}
                        >
                            {messages.length > 0 && messages.map((message, index) => {
                                return (
                                    <div key={index} style={{ padding: '1rem', borderBottom: '1px solid #f9f9f9' }}>
                                        <div style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>{message.sender}</div>
                                        <div style={{ fontSize: '1.5rem' }}>{message.message}</div>
                                    </div>
                                )
                            })}
                        </div>
                        <div
                            style={{
                                height: '100px',
                                width: '100%',
                                backgroundColor: '#f9f9f9',
                            }}
                        >
                            <InputText placeholder="Type a message" style={{ width: '100%', height: '100%', padding: '1rem', fontSize: '2rem' }} 
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault()
                                    sendMessage(e)
                                    e.target.value = ''
                                }
                            }}/>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default DocumentChat