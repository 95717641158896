import React, { useRef, useContext, useState, useEffect } from 'react'


function NotificationRuleChips({ notificationRules, openNotificationRuleFormInEditMode, slackChannels }) {
    const [rulesWithExamples, setRulesWithExamples] = useState([])

    const slackBodyTemplate = (rowData) => {
        if (Array.isArray(slackChannels) && slackChannels.length) {
            const foundChannel = slackChannels.find(channel => channel.value === rowData.SlackChannel);
            return foundChannel ? foundChannel.label : '';
        } else {
            return rowData.SlackChannel;
        }
    }

    useEffect(() => {
        let _rulesWithExamples = notificationRules.map(rule => {
            if (rule.CaseAttributes?.length > 0) {
                let example = `[Attorney] has [n] cases that are missing some of the following important attributes:`
                rule.CaseAttributes.forEach(attribute => {
                    example += `\n- ${attribute}`
                })
                return { ...rule, Example: example }
            } else {
                let example = `[Attorney] has [n] cases that have been in ${rule.State} for at least ${rule.Days} days`
                return { ...rule, Example: example }
            }
        })
        setRulesWithExamples(_rulesWithExamples)
    }, [notificationRules])

    return (
        <>
            <ul className='p-0 m-0'>
                {rulesWithExamples.map((notificationRule, index) => {
                    return (
                        <>
                            <li className={`flex w-full flex-column md:flex-row md:align-items-center md:justify-content-between p-2 shadow-1 my-3 mr-0 bg-bluegray-50 cursor-pointer hover:bg-bluegray-100`}
                                style={{ borderRadius: '10px' }}
                                onClick={() => openNotificationRuleFormInEditMode(notificationRule)}
                            >
                                <div>
                                    <div>
                                        <span className={`text-bluegray-700 font-bold`}>{slackBodyTemplate(notificationRule)}</span>
                                    </div>
                                    <p className={`text-bluegray-700 mt-2 mb-0`}
                                    style={{ whiteSpace: 'pre-wrap' }}
                                    >{notificationRule.Example}</p>
                                </div>
                            </li>
                        </>
                    )
                })}
            </ul>
        </>
    )
}

export default NotificationRuleChips