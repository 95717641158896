import React, { useEffect, useState, useRef } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { FilterMatchMode, FilterOperator } from 'primereact/api'

import { Tag } from 'primereact/tag'
import { SelectButton } from 'primereact/selectbutton'

import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import api from '../../api'
import { MultiSelect } from 'primereact/multiselect'
import CaseAttributeSearch from './CaseAttributeSearch'
import { Calendar } from 'primereact/calendar'
import { Dialog } from 'primereact/dialog'
import { RadioButton } from 'primereact/radiobutton'
import { Checkbox } from 'primereact/checkbox'
import { ProgressSpinner } from 'primereact/progressspinner'

const stages3 = [
    'Pre-Demand',
    'Demand Sent',
    'Arbitration-AAA',
    'Arbitration-JAMS',
    'Arbitration-Other',
    'State Court',
    'Federal Court',
    'Appeal',
    'Post Judgment',
    'Settled/Resolved',
    'Withdrawn'
]

const substages3 = [
    'Complaint Filed',
    'Service Complete',
    'Motion to Dismiss Filed',
    'Discovery',
    'Stayed',
    'Dispositive Motion Pending',
    'Trial Upcoming',
    'Appeal',
    'Settled in Principal',
    'Agreement Executed & Payment(s) Pending',
    'Payments Complete/Fully Resolved',
    'EMPTY'
]

const getStageColor = (stage) => {
    switch (stage) {
        case 'Pre-Demand':
            return 'yellow'
        case 'Demand Sent':
            return 'cyan'
        case 'Arbitration-AAA':
            return 'primary'
        case 'Arbitration-JAMS':
            return 'blue'
        case 'Arbitration-Other':
            return 'purple'
        case 'State Court':
            return 'red'
        case 'Federal Court':
            return 'orange'
        case 'Appeal':
            return 'teal'
        case 'Post Judgment':
            return 'indigo'
        case 'Settled/Resolved':
            return 'green'
        case 'Withdrawn':
            return 'bluegray'
    }
}



function MattersExtendable() {
    const [user, setUser] = useState(null)
    const [possibleColumns, setPossibleColumns] = useState(defaultColumns)
    const [preferredColumns, setPreferredColumns] = useState([])
    const [matters, setMatters] = useState([])
    const [loading, setLoading] = useState(true)
    const [globalFilter, setGlobalFilter] = useState(null)
    const [possibleJurisdictions, setPossibleJurisdictions] = useState([])
    const [stageFilterValue, setStageFilterValue] = useState(null)

    const [filters, setFilters] = useState({
        MatterNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Attorney: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Client: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Defendant: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Jurisdiction: { value: null, matchMode: FilterMatchMode.IN },
        DateEngaged: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        Stage: { value: null, matchMode: FilterMatchMode.IN },
        Substage: { value: null, matchMode: FilterMatchMode.IN }
    })

    const [substageFilterOptions, setSubstageFilterOptions] = useState(substages3)

    useEffect(() => {
        if (stageFilterValue) {
            if ((stageFilterValue.includes('State Court') || stageFilterValue.includes('Federal Court')) && !stageFilterValue.includes('Settled/Resolved')) {
                setSubstageFilterOptions(substages3.slice(0, 7).concat('EMPTY'))
            } else if (stageFilterValue.includes('Settled/Resolved') && !stageFilterValue.includes('State Court') && !stageFilterValue.includes('Federal Court')) {
                setSubstageFilterOptions(substages3.slice(8, 12))
            } else if (!stageFilterValue) {
                setSubstageFilterOptions(substages3)
            } else {
                setSubstageFilterOptions(substages3)
            }
        } else {
            setSubstageFilterOptions(substages3)
        }
    }, [stageFilterValue])

    const [searchForm, setSearchForm] = useState({
        visible: false,
        type: null,
        attribute: null,
        value: null,
    })

    const [preferredColumnsMenu, setPreferredColumnsMenu] = useState({
        visible: false,
    })

    const navigate = useNavigate()  // using React Router's navigate function
    const dt = useRef(null)

    const [sizeOptions] = useState([
        { label: 'Small', value: 'small' },
        { label: 'Large', value: 'normal' },
    ])
    const [size, setSize] = useState(sizeOptions[0].value)

    const [displayTypeOptions] = useState([
        { label: 'Row', value: 'row' },
        { label: 'Menu', value: 'menu' },
    ])
    const [displayType, setDisplayType] = useState(displayTypeOptions[0].value)


    const stageBodyTemplate = (rowData) => {
        return <Tag value={rowData.Stage} className={`bg-${getStageColor(rowData.Stage)}-500`} />
    }

    const stageItemTemplate = (option) => {
        return <Tag value={option} className={`bg-${getStageColor(option)}-500`} />
    }

    const substageBodyTemplate = (rowData) => {
        if (rowData.Stage === 'State Court' || rowData.Stage === 'Federal Court') {
            if (rowData.Substage === 'EMPTY') {
                return <Tag value="EMPTY" className="bg-gray-500" />
            } else {
                return <Tag value={rowData.Substage} className="bg-red-500" />
            }
        } else if (rowData.Stage === 'Settled/Resolved') {
            return <Tag value={rowData.Substage} className="bg-green-500" />
        } else {
            return <Tag value="N/A" className="bg-gray-500" />
        }
    }

    const substageItemTemplate = (option) => {
        if (substages3.slice(0, 7).includes(option)) {
            return <Tag value={option} className="bg-red-500" />
        } else if (substages3.slice(8, 11).includes(option)) {
            return <Tag value={option} className="bg-green-500" />
        } else if (option === 'EMPTY') {
            return <Tag value="EMPTY" className="bg-gray-500" />
        } else {
            return <Tag value="N/A" className="bg-gray-500" />
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                document.title = "Internal";

                // Fetch user data
                const userResponse = await api.get('/user');
                console.log(userResponse.data);
                console.log(userResponse.data.PreferredMattersTableColumns);
                setUser(userResponse.data);
                const preferredColumnNames = userResponse.data.PreferredMattersTableColumns.split(',');
                console.log('Preferred Column Names:', preferredColumnNames);

                // Fetch case attribute types
                const attributeResponse = await api.get('/case_attribute_types');
                const attributeData = attributeResponse.data;
                const newColumns = attributeData.map(attribute => ({
                    label: attribute.Attribute,
                    value: attribute.Attribute,
                    Type: attribute.Type
                }));
                const _possibleColumns = defaultColumns.concat(newColumns);
                const _preferredColumns = _possibleColumns.filter(column => preferredColumnNames.includes(column.value));
                console.log('Attribute Types:', attributeData);
                console.log('Preferred Columns:', _preferredColumns);
                console.log('Possible Columns:', _possibleColumns);
                setPossibleColumns(_possibleColumns);
                setPreferredColumns(_preferredColumns);

                // Fetch matters data
                const mattersResponse = await api.get('/matters');
                const formattedMatters = mattersResponse.data.map(matter => {
                    // matter.DateEngaged = new Date(matter.DateEngaged);

                    Object.keys(matter).forEach(key => {
                        // Check if the property name contains 'Date' and the value is present
                        if (key.includes('Date') && matter[key]) {
                            matter[key] = new Date(matter[key]);
                        }
                    });

                    return matter;
                });
                
                console.log('Matters:', formattedMatters);
                setMatters(formattedMatters);
                const sortedJurisdictions = [...new Set(
                    mattersResponse.data.map(matter => matter.Jurisdiction)
                        .filter(j => typeof j === 'string' && j.length > 0)
                )].sort();
                setPossibleJurisdictions(sortedJurisdictions);
                // setLoading(false);

            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        // If user, possibleColumns, preferredColumns, matters and possibleJurisdictions are all set
        if (user && possibleColumns.length > 0 && preferredColumns.length > 0 && matters.length > 0 && possibleJurisdictions.length > 0) {
            setLoading(false);
        }
    }, [user, possibleColumns, preferredColumns, matters, possibleJurisdictions])

    useEffect(() => {
        initFilters()
    }, [preferredColumns])


    const actionBodyTemplate = (rowData) => {
        return (
            <Button label="View" onClick={() => navigate(`/case_details/${rowData.MatterNumber}`)} />
        )
    }

    const jurisdictionRowFilterTemplate = (options) => {
        return (
            <MultiSelect
                value={options.value}
                options={possibleJurisdictions}
                onChange={(e) => options.filterApplyCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                filter
            />
        )
    }

    const stageRowFilterTemplate = (options) => {
        return (
            <MultiSelect
                value={options.value}
                options={stages3}
                itemTemplate={stageItemTemplate}
                onChange={(e) => {
                    console.log(e.value)
                    setStageFilterValue(e.value)
                    options.filterApplyCallback(e.value)
                }}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                filter
            />
        )
    }

    const substageRowFilterTemplate = (options) => {
        return (
            <MultiSelect
                value={options.value}
                options={substageFilterOptions}
                itemTemplate={substageItemTemplate}
                onChange={(e) => {
                    console.log(e.value)
                    options.filterApplyCallback(e.value)
                }}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                filter
            />
        )
    }

    const dateBodyTemplate = (value) => {
        console.log('Date:', value)
        return formatDate(value);
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => {
            options.filterCallback(e.value, options.index)
            if (displayType === 'row') {
                alert('There is a bug in PrimeReact that does not allow the Date filter to be applied when using the "Row" display type. Please use the "Menu" display type instead. You\'ll find selector in the upper left corner.')
            }
        }} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    };

    const formatDate = (value) => {
        try {
            return value.toLocaleDateString('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            });
        } catch {
            return ''
        }
    };

    const onRowSelect = (event) => {
        const matterNumber = event.data.MatterNumber
        // history.push(`/case_details/${matterNumber}`)
        navigate(`/case_details/${matterNumber}`)
        console.log(matterNumber)
    }

    const openCaseAttributeSearch = () => {
        setSearchForm({ ...searchForm, visible: true })
    }

    const openPreferredColumnsMenu = () => {
        setPreferredColumnsMenu({ ...preferredColumnsMenu, visible: true })
    }

    const setNewPreferredColumns = (columns) => {
        setPreferredColumns(columns)
        setFiltersBasedOnPreferredColumns()
    }

    const getMatchMode = (column) => {
        switch (column.Type.toLowerCase()) {
            case 'text':
                return FilterMatchMode.CONTAINS;
            case 'number':
                return FilterMatchMode.EQUALS;
            case 'jurisdiction':
                return FilterMatchMode.IN;
            case 'stage':
                return FilterMatchMode.IN;
            case 'substage':
                return FilterMatchMode.IN;
            default:
                return FilterMatchMode.CONTAINS;
        }
    }

    // {
    //     MatterNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
    //     Attorney: { value: null, matchMode: FilterMatchMode.CONTAINS },
    //     Client: { value: null, matchMode: FilterMatchMode.CONTAINS },
    //     Defendant: { value: null, matchMode: FilterMatchMode.CONTAINS },
    //     Jurisdiction: { value: null, matchMode: FilterMatchMode.IN },
    //     DateEngaged: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
    //     Stage: { value: null, matchMode: FilterMatchMode.IN },
    //     Substage: { value: null, matchMode: FilterMatchMode.IN }
    // }


    const setFiltersBasedOnPreferredColumns = () => {
        const newFilters = {}
        preferredColumns.forEach(column => {
            if (column.Type.toLowerCase() === 'date') {
                newFilters[column.value] = {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
                }
            } else {
                newFilters[column.value] = {
                    value: null,
                    matchMode: getMatchMode(column)
                }
            }
        })
        console.log('filters:', newFilters)
        setFilters(newFilters)
    }

    const initFilters = () => {
        setFiltersBasedOnPreferredColumns()
    }

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly })
    }

    const clearFilter = () => {
        initFilters()
        // Set 'show' as 1 on every Matter
        const mattersWithShow = matters.map(matter => {
            matter.Show = 1
            return matter
        })
        setMatters(mattersWithShow)
    }

    const filteredMatters = matters.length === 0 ? [] :
        ('Show' in matters[0] ? matters.filter(matter => matter.Show === 1) : matters)

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" size="small" label="Select Columns" outlined onClick={openPreferredColumnsMenu} />
            {/* <Button type="button" size="small" label="Attribute Search" outlined onClick={openCaseAttributeSearch} /> */}
            <Button type="button" size="small" icon="pi pi-download" outlined rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
            <Button type="button" icon="pi pi-filter-slash" label="Clear" outlined onClick={clearFilter} />
            {/* <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
            <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf} data-pr-tooltip="PDF" /> */}
        </div>
    )

    const getColumnBodyTemplate = (column) => {
        switch (column.Type.toLowerCase()) {
            case 'date':
                return (rowData) => {
                    return dateBodyTemplate(rowData[column.value])
                }
            case 'stage':
                return stageBodyTemplate
            case 'substage':
                return substageBodyTemplate
            default:
                return (rowData) => {
                    return rowData[column.value]
                }
        }
    }

    const getColumnFilterTemplate = (column) => {
        switch (column.Type.toLowerCase()) {
            case 'date':
                return dateFilterTemplate
            case 'stage':
                return stageRowFilterTemplate
            case 'substage':
                return substageRowFilterTemplate
            case 'jurisdiction':
                return jurisdictionRowFilterTemplate
            default:
                return null
        }
    }

    const num_cols = preferredColumns.length > 8 ? 8 : preferredColumns.length
    const columns = preferredColumns.map((column, index) => {
        const dt = column.Type.toLowerCase() === 'date' ? 'date' : undefined
        return (
            <Column
                key={column.value}
                field={column.value}
                header={column.label}
                style={{ minWidth: '8rem', fontSize: '12px', width: `${100 / num_cols}%` }}
                sortable
                filterPlaceholder={`Search by ${column.label}`}
                filter
                filterElement={getColumnFilterTemplate(column)}
                body={getColumnBodyTemplate(column)}
                // showFilterMatchModes
                dataType={dt}
            />
        )
    })

    return (
        <div className='w-full'>
            {/* <div className="surface-ground px-4 py-5">
                <div className="border-bottom-1 surface-border">
                    <span className="block text-3xl font-medium text-900 mb-4">Matters</span>
                </div>
            </div> */}
            <div
                style={{
                    padding: '1rem 1rem',
                    boxSizing: 'border-box',
                    maxWidth: '100%',
                }}
            >
                <div className="surface-card p-0 shadow-2 border-round overflow-hidden matters-table"
                    style={{
                        boxSizing: 'border-box',
                        height: 'calc(100vh - 30px)',
                        width: 'calc(100vw - 310px)'
                    }}
                >
                    {loading &&
                        <div className="flex align-items-center justify-content-center" style={{ height: '100%' }}>
                            <ProgressSpinner />
                        </div>
                    }
                    {!loading &&
                        <DataTable value={filteredMatters} paginator rows={25} loading={loading} dataKey="id" globalFilter={globalFilter}
                            emptyMessage="No matters found." selectionMode="single" onRowSelect={onRowSelect}
                            sortMode="multiple" removableSort rowsPerPageOptions={[5, 10, 15, 25, 50, 100]} paginatorPosition='top'
                            filters={filters}
                            filterDisplay={displayType} size={size} paginatorLeft={
                                <SelectButton value={displayType} onChange={(e) => setDisplayType(e.value)} options={displayTypeOptions} />
                            }
                            header={header}
                            ref={dt}
                            stateStorage="session" stateKey="table-state-live"
                            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                            CurrentPageReport currentPageReportTemplate="{first} to {last} of {totalRecords}"
                            scrollable scrollHeight="calc(100vh - 145px)"
                            key="MattersTable"
                        >
                            {columns}

                            {/* <Column field="MatterNumber" header="Matter Number" style={{ minWidth: '8rem', fontSize: '14px', width: `${100 / num_cols}%}` }} sortable filterPlaceholder="Search by Matter Number" filter />
                            <Column field="Attorney" header="Attorney" style={{ minWidth: '8rem', fontSize: '14px', width: `${100 / num_cols}%}` }} sortable filterPlaceholder="Search by Attorney" filter />
                            <Column field="Client" header="Client" style={{ minWidth: '8rem', fontSize: '14px', width: `${100 / num_cols}%}` }} sortable filterPlaceholder="Search by Client" filter />
                            <Column field="Defendant" header="Defendant" style={{ minWidth: '8rem', fontSize: '14px', width: `${100 / num_cols}%}` }} sortable filterPlaceholder="Search by Defendant" filter />
                            <Column field="Jurisdiction" header="Jurisdiction" style={{ minWidth: '8rem', fontSize: '14px', width: `${100 / num_cols}%}` }} sortable filterPlaceholder="Search by Jurisdiction" filter filterElement={jurisdictionRowFilterTemplate} />
                            <Column header="DateEngaged" field="DateEngaged" dataType="date" style={{ minWidth: '8rem', fontSize: '14px', width: `${100 / num_cols}%}` }} sortable body={dateBodyTemplate} filter filterElement={dateFilterTemplate} showFilterMatchModes />

                            <Column filterField="Stage" header="Stage" style={{ minWidth: '8rem', fontSize: '14px', width: `${100 / num_cols}%}` }} body={stageBodyTemplate}
                                filterPlaceholder="Search by Stage" showFilterMenu={false} filterMenuStyle={{ width: '14rem' }} filter filterElement={stageRowFilterTemplate} />

                            <Column filterField="Substage" header="Substage" style={{ minWidth: '8rem', fontSize: '14px', width: `${100 / num_cols}%}` }} body={substageBodyTemplate}
                                filterPlaceholder="Search by Substage" showFilterMenu={false} filterMenuStyle={{ width: '14rem' }} filter filterElement={substageRowFilterTemplate} /> */}

                        </DataTable>
                    }
                </div>
            </div>
            {/* <CaseAttributeSearch searchForm={searchForm} setSearchForm={setSearchForm} setMatters={setMatters} /> */}
            <PreferredColumnsMenu preferredColumns={preferredColumns} setPreferredColumns={setNewPreferredColumns} preferredColumnsMenu={preferredColumnsMenu} setPreferredColumnsMenu={setPreferredColumnsMenu} possibleColumns={possibleColumns} />
        </div>
    )
}

export default MattersExtendable

const defaultColumns = [
    { label: 'Matter Number', value: 'MatterNumber', Type: 'text' },
    { label: 'Attorney', value: 'Attorney', Type: 'text' },
    { label: 'Client', value: 'Client', Type: 'text' },
    { label: 'Defendant', value: 'Defendant', Type: 'text' },
    { label: 'Jurisdiction', value: 'Jurisdiction', Type: 'jurisdiction' },
    { label: 'Date Engaged', value: 'DateEngaged', Type: 'date' },
    { label: 'Stage', value: 'Stage', Type: 'stage' },
    { label: 'Substage', value: 'Substage', Type: 'substage' }
]

const PreferredColumnsMenu = ({ preferredColumns, setPreferredColumns, preferredColumnsMenu, setPreferredColumnsMenu, possibleColumns }) => {

    const updatePreferredColumns = (columns) => {
        api.put('/preferred_matters_table_columns', { columns: columns.map(col => col.value).join(',') }).then(response => {
            console.log(response.data);
        }).catch(error => {
            console.error(error);
        })
    }

    const hide = () => {
        setPreferredColumnsMenu({ ...preferredColumnsMenu, visible: false })
        updatePreferredColumns(preferredColumns)
    }

    const save = () => {
        setPreferredColumnsMenu({ ...preferredColumnsMenu, visible: false })
        setPreferredColumns(preferredColumns)
    }

    // const fetchAttributes = async () => {
    //     api.get('/case_attribute_types').then(response => {
    //         const data = response.data;
    //         const newColumns = data.map(attribute => {
    //             return { label: attribute.Attribute, value: attribute.Attribute }
    //         })
    //         setPossibleColumns(defaultColumns.concat(newColumns))
    //         console.log('Attribute Types:', data);
    //     }).catch(error => {
    //         console.error(error);
    //     });
    // }

    // useEffect(() => {
    //     fetchAttributes();
    // }, [])

    useEffect(() => {
        console.log('Possible Columns:', possibleColumns)
    }, [possibleColumns])

    const onColumnToggle = (e) => {
        let _preferredColumns = [...preferredColumns];
        if (e.checked)
            _preferredColumns.push(possibleColumns.find(col => col.value === e.value));
        else
            _preferredColumns = _preferredColumns.filter(col => col.value !== e.value);

        console.log(_preferredColumns)
        setPreferredColumns(_preferredColumns);
    }

    const headerContent = (
        <div>
            Select Columns
        </div>
    );

    const footerContent = (
        <div>
            <Button
                label="Save"
                onClick={save}
                // disabled={searchForm.type === null || searchForm.searchType === null}
                icon="pi pi-search"
            />
        </div>
    );

    const checked = (column) => {
        return preferredColumns.map(col => col.value).includes(column.value)
    }

    return (
        <Dialog header={headerContent} visible={preferredColumnsMenu.visible} style={{ width: '50vw' }} onHide={hide}
        // footer={footerContent}
        >
            <div className='grid formgrid p-fluid'>
                <div className="field mb-4 col-12">
                    {/* <MultiSelect value={preferredColumns} options={possibleColumns} onChange={onColumnToggle} optionLabel="label" placeholder="Select Columns" /> */}

                    <div className="flex flex-wrap justify-content-center gap-3">
                        {possibleColumns.map(column => {
                            return (
                                <div key={column.value} className="flex align-items-center w-12rem">
                                    <Checkbox inputId={column.value} value={column.value}
                                        onChange={onColumnToggle} checked={checked(column)} />
                                    <label htmlFor={column.value} className="ml-2">{column.label}</label>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
