import React, { useRef, useContext, useState, useEffect } from 'react';
import FileUploadURL from './FileUploadURL';
import NewCase from './NewCase';
import { Button } from 'primereact/button';
import api from '../../api';
import ToastContext from '../../ToastContext';
import AllTrelloTasks from './AllTrelloTasks';


function Utilities() {

    useEffect(() => {
        document.title = "Utilities"
    },)

    return (
        <div>
            <div className="surface-ground px-4 py-5">
                <div className="border-bottom-1 surface-border">
                    <span className="block text-3xl font-medium text-900 mb-4">Utilities</span>
                </div>
            </div>
            <div className='px-4'>
                <div className='grid'>
                    <div className="surface-card p-4 m-2 shadow-2 border-round col-12 lg:col-6">
                        <NewCase />
                    </div>

                    <div className="surface-card p-4 m-2 shadow-2 border-round col-12 lg:col-6">
                        <FileUploadURL />
                    </div>

                    <div className="surface-card p-4 m-2 shadow-2 border-round col-12 lg:col-6">
                        <ResyncClockifyData />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Utilities

function ResyncClockifyData() {
    const showToast = useContext(ToastContext)

    const resync = () => {
        api.post('/resync_clockify_billing_data')
            .then(response => {
                console.log('billing:', response.data)
                showToast({ severity: 'success', summary: 'Successful', detail: 'Full Clockify Data Resync is Underway' })
            })
            .catch(error => {
                console.error('Error:', error)
                showToast({ severity: 'error', summary: 'Error', detail: error.message })
            })
    }

    return (
        <>
            <Button label="Resync All Clockify Data" onClick={resync} />
        </>
    )
}