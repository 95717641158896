import { useSelector, useDispatch } from 'react-redux'
import api from '../../api'
import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import {
    setTrelloDate,
    setSelectedTrelloMember,
    setDaysBeforeDeadline,
    setCreateTask,
    setAddNow,
    setTrelloCardTitle
} from '../../store/CalendarInput/action'
import { InputSwitch } from 'primereact/inputswitch'
import { Dropdown } from 'primereact/dropdown'
import { InputNumber } from 'primereact/inputnumber'
import { Calendar } from 'primereact/calendar'
import { InputText } from 'primereact/inputtext'

const TrelloTaskInfo = () => {
    const dispatch = useDispatch()
    const startDate = useSelector(state => state.calendarInput.startDatetime12h)
    const date = useSelector(state => state.calendarInput.date)
    const allDay = useSelector(state => state.calendarInput.allday)

    const [trelloMembers, setTrelloMembers] = useState([])
    const [minMaxDays, setMinMaxDays] = useState({ min: 1, max: 1 })
    // const [addNow, setAddNow] = useState(false)
    // const [selectedTrelloMember, setSelectedTrelloMember] = useState(null)
    // const [daysBeforeDeadline, setDaysBeforeDeadline] = useState(1)
    // const [trelloDate, setTrelloDate] = useState(new Date())

    const createTask = useSelector(state => state.calendarInput.createTask)
    const addNow = useSelector(state => state.calendarInput.addNow)
    const selectedTrelloMember = useSelector(state => state.calendarInput.selectedTrelloMember)
    const daysBeforeDeadline = useSelector(state => state.calendarInput.daysBeforeDeadline)
    const matterNumber = useSelector(state => state.calendarInput.matterNumber)
    const trelloDate = useSelector(state => state.calendarInput.trelloDate)
    const currentlyEditingEvent = useSelector(state => state.calendarInput.currentlyEditingEvent)
    const trlloCardTitle = useSelector(state => state.calendarInput.trelloCardTitle)
    const [atttorneysForMatter, setAttorneysForMatter] = useState([])


    // Get trello members
    useEffect(() => {
        api.get('get_trello_board_members')
            .then((response) => {
                console.log(response)
                let members = response.data.map(member => {
                    return {
                        name: member.fullName,
                        value: member.id
                    }
                })
                console.log('members:', members)
                setTrelloMembers(members)
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    const differenceInDays = () => {
        const today = new Date();
        const _startDate = allDay ? new Date(date) : new Date(startDate)
        console.log('today:', today)
        console.log('_startDate:', _startDate)

        // Ensure the time component is set to 00:00:00 for both dates to get accurate day difference
        today.setHours(0, 0, 0, 0);
        _startDate.setHours(0, 0, 0, 0);

        const differenceInTime = _startDate.getTime() - today.getTime();
        const differenceInDays = Math.round(differenceInTime / (1000 * 60 * 60 * 24));

        return differenceInDays - 1
    }

    const [trelloDateNotString, setTrelloDateNotString] = useState(new Date())
    useEffect(() => {
        const newDate = allDay ? new Date(date) : new Date(startDate)
        newDate.setDate(newDate.getDate() - (daysBeforeDeadline || 0))

        const today = new Date()
        today.setHours(0, 0, 0, 0)

        const _differenceInDays = differenceInDays()
        let newMaxDays = Math.max(0, _differenceInDays)
        setMinMaxDays({ min: 0, max: newMaxDays })
        console.log('newMaxDays:', newMaxDays)

        if (newDate >= today) {
            dispatch(setTrelloDate(newDate.toISOString()))
            setTrelloDateNotString(newDate)

        } else {
            console.log('newDate is today or a past date')
        }

    }, [startDate, date, daysBeforeDeadline, allDay, setTrelloDate])


    useEffect(() => {
        if (daysBeforeDeadline > minMaxDays.max) {
            dispatch(setDaysBeforeDeadline(minMaxDays.max))
        } else if (daysBeforeDeadline < minMaxDays.min) {
            dispatch(setDaysBeforeDeadline(minMaxDays.min))
        }
    }, [minMaxDays, daysBeforeDeadline])

    useEffect(() => {
        if (matterNumber) {
            api.get(`get_attorneys_for_matter/${matterNumber}`)
                .then((response) => {
                    console.log(response)
                    setAttorneysForMatter(response.data.attorneyNames)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }, [matterNumber])

    useEffect(() => {
        if (atttorneysForMatter.length > 0) {
            const att = trelloMembers.find(member => member.name === atttorneysForMatter[0])
            console.log('att:', att)
            if (att && att.value) {
                dispatch(setSelectedTrelloMember(att.value))
            }
        }
    }, [atttorneysForMatter])

    useEffect(() => {
        console.log('selectedTrelloMember:', selectedTrelloMember)
    }, [selectedTrelloMember])

    return (
        <>

            <div className="field mb-4 col-12 flex justify-content-start">
                <div>
                    <div className="py-2">
                        <label htmlFor="item" className="font-medium text-900">Create Task</label>
                    </div>
                    <div className="py-2">
                        <InputSwitch checked={createTask} onChange={(e) => dispatch(setCreateTask(e.value))} disabled={currentlyEditingEvent} />
                    </div>
                </div>

                {createTask && (
                    <div className='ml-3'>
                        <div className="py-2">
                            <label htmlFor="item" className="font-medium text-900">Add Now</label>
                        </div>
                        <div className="py-2">
                            <InputSwitch checked={addNow} onChange={(e) => dispatch(setAddNow(e.value))} />
                        </div>
                    </div>
                )}
            </div>

            {createTask && (
                <>

                    <div className="field mb-4 col-6 lg:col-3">
                        <label htmlFor="trelloMember" className="font-medium text-900">Trello Member</label>
                        <Dropdown value={selectedTrelloMember} onChange={(e) => dispatch(setSelectedTrelloMember(e.value))} options={trelloMembers} optionLabel="name"
                            placeholder="Select a Trello Member" />
                    </div>

                    <div className="field mb-4 col-6 lg:col-3">
                        <label htmlFor="daysBeforeDeadline" className="font-medium text-900">Card Title (Optional)</label>
                        <InputText value={trlloCardTitle} onChange={(e) => dispatch(setTrelloCardTitle(e.target.value))}
                            placeholder="Card Title" />
                    </div>

                    {!addNow && (
                        <>
                            <div className="field mb-4 col-6 lg:col-3">
                                <label htmlFor="daysBeforeDeadline" className="font-medium text-900">Days Before Deadline</label>
                                <InputNumber placeholder="Days Before Deadline to Add to Trello" value={daysBeforeDeadline} onChange={(e) => dispatch(setDaysBeforeDeadline(e.value))} min={minMaxDays.min} max={minMaxDays.max} showButtons />
                            </div>

                            <div className="field mb-4 col-6 lg:col-3">
                                <label htmlFor="trelloDate" className="font-medium text-900">Trello Date</label>
                                <Calendar value={trelloDateNotString} disabled />
                            </div>
                        </>
                    )}
                </>
            )}

        </>
    )
}

export default TrelloTaskInfo