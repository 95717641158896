import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import Matters from "./components/Matters/Matters";
import CaseDetails from "./components/CaseDetails/CaseDetails";
import SidebarLayout from "./components/SidebarLayout";
import CalendarInput from "./components/CalendarInput/0_CalendarInput";
import ToastProvider from './ToastProvider';
import Settings from "./components/Settings";
import Login from "./components/Login";
import { AuthProvider } from './AuthContext';
import PrivateRoute from './PrivateRoute';
import { useContext, useEffect } from 'react';
import { AuthContext } from './AuthContext';
import { useNavigate } from 'react-router-dom';
// import NewCase from "./components/NewCase";
import Admin from "./components/Admin/Admin";
import Utilities from "./components/Utilities/Utilities";
import { Tooltip } from "primereact/tooltip";
import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from "primereact/progressspinner";
import { useSelector } from "react-redux";
import NotFoundPage from "./components/NotFoundPage";
import AllTrelloTasks from "./components/Utilities/AllTrelloTasks";
import MattersExtendable from "./components/Matters/MattersExtendable";
import DocumentChat from "./components/DocumentChat/DocumentChat";

function ProtectedLayout({ pageComponent }) {
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    console.log('location:', location)
    if (!isAuthenticated) {
      navigate('/login', { state: { from: location } });
    }
  }, [isAuthenticated, navigate]);

  return (
    <LayoutWrapper pageComponent={pageComponent} />
  );
}

// Wrapper component with SidebarLayout
function LayoutWrapper({ pageComponent }) {
  return (
    <SidebarLayout>
      {pageComponent}
    </SidebarLayout>
  );
}

// Your main App component
function App() {
  const appLoading = useSelector(state => state.app.loading);

  return (
    <AuthProvider>
      <ToastProvider>
        <BlockUI blocked={appLoading} fullScreen template={
          <ProgressSpinner />
        } />

        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<ProtectedLayout pageComponent={<MattersExtendable />} />} />
            <Route path="/matters" element={<ProtectedLayout pageComponent={<MattersExtendable />} />} />
            <Route path="/case_details/:MatterNumber" element={<ProtectedLayout pageComponent={<CaseDetails />} />} />
            <Route path="/calendar_input" element={<ProtectedLayout pageComponent={<CalendarInput />} />} />
            <Route path="/document_chat" element={<ProtectedLayout pageComponent={<DocumentChat />} />} />
            <Route path="/settings" element={<ProtectedLayout pageComponent={<Settings />} />} />
            <Route path="/utilities" element={<ProtectedLayout pageComponent={<Utilities />} />} />
            <Route path="/admin" element={<ProtectedLayout pageComponent={<Admin />} />} />
            <Route path="/trello_tasks" element={<ProtectedLayout pageComponent={<AllTrelloTasks />} />} />
            <Route path="/404" element={<NotFoundPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Router>
      </ToastProvider>
    </AuthProvider >
  );
}

export default App;
