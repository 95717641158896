import { useSelector, useDispatch } from 'react-redux'
import api from '../../api'
import { useEffect, useRef, useState } from 'react'
import {
    setCurrentlyEditingEvent,
    setEventsTableVisible,

} from '../../store/CalendarInput/action'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'

const EventsTableDialog = () => {
    const dispatch = useDispatch()
    const visible = useSelector(state => state.calendarInput.eventsTableVisible)
    const currentlyEditingEvent = useSelector(state => state.calendarInput.currentlyEditingEvent)
    const [pageToken, setPageToken] = useState(null)
    const [events, setEvents] = useState([])


    function truncateText(text, length) {
        if (text && text.length > length) {
            return text.substring(0, length - 3) + '...'
        }
        return text
    }

    const fetchCalendarEvents = async () => {
        const url = pageToken ?
            `calendar?pageToken=${pageToken}&limit=10` :
            `calendar?limit=10`
        api.get(url)
            .then((response) => {
                let items = response.data.events.items
                // Format dates and long descriptions
                items = items.map(item => {
                    item.start.formattedDateTime = new Date(item.start.dateTime || item.start.date).toLocaleString()
                    item.end.formattedDateTime = new Date(item.end.dateTime || item.start.date).toLocaleString()
                    item.shortDescription = truncateText(item.description, 50)
                    return item
                })
                setEvents(items)
                console.log(response)
            })
            .catch(error => {
                console.log(error)
            })
    }

    useEffect(() => {
        fetchCalendarEvents()
    }, [])

    useEffect(() => {
        console.log('Events:', events)
    }, [events])


    return (
        <>
            <Dialog className="created-events-panel" header="Choose an Event to Edit" visible={visible} onHide={() => dispatch(setEventsTableVisible(false))} style={{ padding: '0', width: '70%', height: '90%' }}>
                <div className="surface-card p-0 shadow-2 border-round overflow-hidden matters-table">
                    <DataTable value={events} className="p-datatable-striped"
                        selectionMode="single" selection={currentlyEditingEvent}
                        onSelectionChange={(e) => {
                            dispatch(setCurrentlyEditingEvent(e.value))
                            dispatch(setEventsTableVisible(false))
                        }}
                        size="small" paginator rows={25} paginatorPosition="both"
                        removableSort
                    >
                        <Column field="summary" header="Title" sortable style={{ width: '25%' }}></Column>
                        <Column field="shortDescription" header="Description" sortable style={{ width: '30%' }}></Column>
                        <Column field="start.formattedDateTime" header="Start" sortable style={{ width: '20%' }}></Column>
                        <Column field="end.formattedDateTime" header="End" sortable style={{ width: '20%' }}></Column>
                        <Column field="attachments.length" header="Files" sortable style={{ width: '5%' }}></Column>
                    </DataTable>
                </div>
            </Dialog>
        </>
    )
}

export default EventsTableDialog