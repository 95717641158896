import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import ToastContext from "../../ToastContext"
import api from "../../api"
import { InputText } from "primereact/inputtext"
import DatePicker from "react-date-picker"
import { Dialog } from "primereact/dialog"
import { Tag } from "primereact/tag"
import { Calendar } from "primereact/calendar"
import { Dropdown } from "primereact/dropdown"
import { InputSwitch } from 'primereact/inputswitch'
import {
    setRefreshTrelloTasks
} from '../../store/CaseDetails/action'

function addTimezoneOffset(date) {
    // Get the user's current timezone offset in minutes
    const timezoneOffsetInMinutes = new Date().getTimezoneOffset();
    console.log('timezoneOffsetInMinutes:', timezoneOffsetInMinutes)
    // Convert the offset to hours (negate because getTimezoneOffset returns negative for UTC- and positive for UTC+)
    const timezoneOffsetInHours = timezoneOffsetInMinutes / 60;
    console.log('timezoneOffsetInHours:', timezoneOffsetInHours)
    // Create a new Date object based on the input date and add the offset in hours
    const newDate = new Date(date);
    newDate.setHours(newDate.getHours() + timezoneOffsetInHours);
    return newDate;
}

function TrelloTaskForm({ substage, visible, setVisible, currentlyEditing, defaultType }) {
    const dispatch = useDispatch()
    const matter = useSelector(state => state.caseDetails.matter)
    const showToast = useContext(ToastContext)
    const [dueDates, setDueDates] = useState([])
    const [key, setKey] = useState(1)
    const [formContent, setFormContent] = useState({})

    const setInitialFormContent = () => {
        if (currentlyEditing) {
            console.log('currentlyEditing:', currentlyEditing)
            const utcDate = new Date(currentlyEditing.Date)
            const localDate = addTimezoneOffset(utcDate)
            const utcDueDate = new Date(currentlyEditing.Deadline)
            const localDueDate = addTimezoneOffset(utcDueDate)
            const ce = {
                dueDate: localDueDate,
                date: localDate,
                name: currentlyEditing.Name,
                details: currentlyEditing.Details,
                type: currentlyEditing.TaskType
            }
            setFormContent(ce)
        } else if (substage === 'Settled in Principal') {
            setFormContent(
                {
                    dueDate: new Date(),
                    date: new Date(new Date().setDate(new Date().getDate() - 10)),
                    name: 'Deadline to Execute Settlement Agreement',
                    details: '',
                    type: defaultType || null
                }
            )
        } else if (substage === 'Agreement Executed & Payment(s) Pending') {
            setFormContent(
                {
                    dueDate: new Date(),
                    date: new Date(new Date().setDate(new Date().getDate() - 5)),
                    name: 'Settlement Payment Due',
                    details: '',
                    type: defaultType || null
                }
            )
        } else {
            setFormContent(
                {
                    dueDate: new Date(),
                    date: new Date(),
                    name: '',
                    details: '',
                    type: defaultType || null
                }
            )
        }
    }

    useEffect(() => {
        setInitialFormContent()
    }, [])

    useEffect(() => {
        setInitialFormContent()
    }, [substage, currentlyEditing])

    useEffect(() => {
        console.log('formContent:', formContent)
    }, [formContent])


    const submit = () => {
        let apiMethod, endpoint
        const payload = {
            Name: formContent.name,
            Details: formContent.details,
            DueDate: formContent.dueDate,
            Date: formContent.date,
            AttorneyName: matter.attorneys[0].Name,
            TaskType: formContent.type || 'Not a DueDate', // Adjusted for edit
            MatterNumber: matter.MatterNumber,
        }

        // Check if editing
        if (currentlyEditing) {
            apiMethod = api.put
            endpoint = `/update_trello_task/${currentlyEditing.id}` // Assuming 'id' is the identifier for the task
            payload.OldDetails = { // Example, adjust based on actual data needed for update
                Name: currentlyEditing.Name,
                Details: currentlyEditing.Details,
                DueDate: currentlyEditing.DueDate,
                Date: currentlyEditing.Date,
                AttorneyName: currentlyEditing.AttorneyName,
                TaskType: currentlyEditing.TaskType,
                MatterNumber: currentlyEditing.MatterNumber,
            }
        } else {
            apiMethod = api.post
            endpoint = '/queue_trello_task'
        }

        console.log('payload:', payload)
        apiMethod(endpoint, payload)
            .then(response => {
                if (response.status === 201 || response.status === 200) { // Assuming 200 for successful update
                    showToast({
                        severity: 'success',
                        summary: 'Successful',
                        detail: currentlyEditing ? 'DueDate updated' : 'DueDate added'
                    })
                    dispatch(setRefreshTrelloTasks(true))
                    setVisible(false)
                } else {
                    console.error('Unexpected status code:', response.status)
                    showToast({ severity: 'error', summary: 'Error', detail: 'Unexpected status code' })
                }
            })
            .catch(error => {
                console.error('Error:', error)
                showToast({ severity: 'error', summary: 'Error', detail: error.message })
            })
    }

    const deleteTrelloTask = () => {
        api.delete(`/delete_trello_task/${currentlyEditing.id}`)
            .then(response => {
                if (response.status === 201) {
                    setVisible(false)
                    showToast({ severity: 'success', summary: 'Successful', detail: 'Task deleted' })
                    dispatch(setRefreshTrelloTasks(true))
                } else {
                    console.error('Unexpected status code:', response.status)
                    showToast({ severity: 'error', summary: 'Error', detail: 'Unexpected status code' })
                }
            })
            .catch(error => {
                console.error('Error:', error)
                showToast({ severity: 'error', summary: 'Error', detail: 'Unable to delete task' })
            })
    }

    useEffect(() => {
        // update date based on dueDate
        // For Settled In Principal should have a dueDate to execute agreement and a Trello card created 10 days before the dueDate
        //For the Payment Pending we should have the ability to enter one or more dueDates and Trello cards created 5 days before each dueDate.
        if (formContent.type === 'DueDate') {
            if (substage === 'Settled in Principal') {
                setFormContent(prev => ({ ...prev, date: new Date(new Date(formContent.dueDate).setDate(new Date(formContent.dueDate).getDate() - 10)) }))
            } else if (substage === 'Agreement Executed & Payment(s) Pending') {
                setFormContent(prev => ({ ...prev, date: new Date(new Date(formContent.dueDate).setDate(new Date(formContent.dueDate).getDate() - 5)) }))
            }
        }
    }, [formContent.type, formContent.dueDate])


    const footerContent = (
        <div>
            {currentlyEditing && <Button label="Delete" icon="pi pi-times" className="p-button-danger" outlined onClick={deleteTrelloTask} disabled={currentlyEditing.Popped} />}
            <Button label={currentlyEditing ? 'Update' : 'Submit'} icon="pi pi-check" outlined onClick={submit} autoFocus disabled={formContent.name === null || currentlyEditing?.Popped} />
        </div>
    )

    return (
        <Dialog
            className='w-30rem'
            header={currentlyEditing ? (currentlyEditing.Popped ? 'Task Already Created' : 'Edit Queued Task') : 'Queue Trello Task'}
            visible={visible}
            onHide={() => setVisible(false)}
            footer={footerContent}
        >

            <div className='grid formgrid p-fluid' key={key}>

                <div className="field mb-4 col-12">
                    <div className="py-0">
                        <label htmlFor="type" className="font-medium text-900">Due Date</label>
                    </div>
                    <div className="py-2">
                        <InputSwitch
                            checked={formContent.type === 'DueDate'}
                            onChange={(e) => setFormContent(prev => ({ ...prev, type: e.value ? 'DueDate' : 'Not a DueDate' }))}
                            disabled={currentlyEditing && currentlyEditing.Popped}
                        />
                    </div>
                </div>

                <>
                    {formContent.type === 'DueDate' &&
                        <div className="field mb-4 col-12">
                            <div className="py-0">
                                <label htmlFor="dueDate" className="font-medium text-900">Due Date</label>
                            </div>
                            <div className="py-2">
                                <Calendar value={formContent.dueDate} onChange={(e) => setFormContent(prev => ({ ...prev, dueDate: e.value }))} disabled={currentlyEditing && currentlyEditing.Popped} />
                            </div>
                        </div>
                    }

                    <div className="field mb-4 col-12">
                        <div className="py-0">
                            <label htmlFor="date" className="font-medium text-900">Date Trello Card Will Be Created</label>
                        </div>
                        <div className="py-2">
                            <Calendar value={formContent.date} onChange={(e) => setFormContent(prev => ({ ...prev, date: e.value }))} disabled={currentlyEditing && currentlyEditing.Popped} />
                        </div>
                    </div>

                    <div className="field mb-4 col-12">
                        <label htmlFor="name" className="font-medium text-900">Name</label>
                        <InputText
                            id="name"
                            value={formContent.name}
                            onChange={(e) => setFormContent(prev => ({ ...prev, name: e.target?.value }))}
                            disabled={currentlyEditing && currentlyEditing.Popped}
                        />
                    </div>

                    <div className="field mb-4 col-12">
                        <label htmlFor="details" className="font-medium text-900">Details</label>
                        <InputText
                            id="details"
                            value={formContent.details}
                            onChange={(e) => setFormContent(prev => ({ ...prev, details: e.target?.value }))}
                            disabled={currentlyEditing && currentlyEditing.Popped}
                        />
                    </div>
                </>


            </div>

        </Dialog>
    )
}

export default TrelloTaskForm