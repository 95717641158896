import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useState, useEffect, useContext, useRef } from 'react'
import api from '../../api'
import { Tag } from 'primereact/tag'
import { Button } from 'primereact/button'
import ToastContext from '../../ToastContext'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { InputNumber } from 'primereact/inputnumber'
import { Calendar } from 'primereact/calendar'
import { Chip } from 'primereact/chip'
import { InputTextarea } from 'primereact/inputtextarea'
import { useDispatch, useSelector } from 'react-redux'
import DatePicker from 'react-date-picker'
import {
    setRefreshTrelloTasks
} from '../../store/CaseDetails/action'
import TrelloTaskForm from '../CaseDetails/TrelloTaskForm'
import moment from 'moment'
import { Menubar } from 'primereact/menubar'

function AllTrelloTasks() {
    const dispatch = useDispatch()
    const refreshTrelloTasks = useSelector(state => state.caseDetails.refreshTrelloTasks)
    const showToast = useContext(ToastContext)
    const [queuedTasks, setQueuedTasks] = useState([])
    const [filteredTasks, setFilteredTasks] = useState([])
    const [currentlyEditingTrelloTask, setCurrentlyEditingTrelloTask] = useState(null)
    const [trelloTaskFormVisible, setTrelloTaskFormVisible] = useState(false)

    useEffect(() => {
        document.title = "Trello Tasks"
        getTasks()
    }, [])

    useEffect(() => {
        console.log('refreshTrelloTasks:', refreshTrelloTasks)
        if (refreshTrelloTasks) {
            getTasks()
            dispatch(setRefreshTrelloTasks(false))
        }
    }, [refreshTrelloTasks])

    const openTrelloTaskFormInEditMode = (queuedTask) => {
        setCurrentlyEditingTrelloTask(queuedTask)
        setTrelloTaskFormVisible(true)
    }

    const getTasks = (matterNumber, taskType) => {
        let url = `/get_trello_tasks?`

        if (matterNumber) {
            url += `MatterNumber=${matterNumber}&`
        }
        if (taskType) {
            url += `TaskType=${taskType}`
        }

        api.get(url)
            .then(response => {
                if (response.status === 200) {
                    console.log('queued tasks:', response.data)
                    setQueuedTasks(response.data)
                    setFilteredTasks(response.data)
                } else {
                    console.error('Unexpected status code:', response.status)
                    showToast({ severity: 'error', summary: 'Error', detail: 'Unexpected status code' })
                }
            })
            .catch(error => {
                console.error('Error:', error)
            })
    }

    return (
        <>
            <TrelloTaskForm visible={trelloTaskFormVisible} setVisible={setTrelloTaskFormVisible} currentlyEditing={currentlyEditingTrelloTask} />
            <div className="surface-ground px-4 py-5">
                <div className="border-bottom-1 surface-border">
                    <span className="block text-3xl font-medium text-900 mb-4">Trello Tasks</span>
                </div>
            </div>
            <div class="flex align-items-center justify-content-center text-red-500 pb-5">
                <FilterTasksMenu setFilteredTasks={setFilteredTasks} allTasks={queuedTasks} openSettlementForm={() => openTrelloTaskFormInEditMode({ TaskType: 'Settlement' })} openAddNoteForm={() => openTrelloTaskFormInEditMode({ TaskType: 'Note' })} openBriefFiledForm={() => openTrelloTaskFormInEditMode({ TaskType: 'Brief Filed' })} />
            </div>

            {filteredTasks.length > 0 && (
                <div className='px-5'>
                    <div class="grid m-0 p-0 py-3 bg-bluegray-50 border-round border-1 border-bluegray-100">
                        {filteredTasks.filter(queuedTask => queuedTask.TaskType === 'DueDate').length > 0 && (
                            <div class="col">
                                <div className='px-3'>
                                    <div className="text-500 font-bold pb-1">With Due Date</div>
                                    <QueuedTaskChips queuedTasks={queuedTasks.filter(queuedTask => queuedTask.TaskType === 'DueDate')} openTrelloTaskFormInEditMode={openTrelloTaskFormInEditMode} />
                                </div>
                            </div>
                        )}
                        {filteredTasks.filter(queuedTask => queuedTask.TaskType !== 'DueDate').length > 0 && (
                            <div class="col">
                                <div className='px-3'>
                                    <div className="text-500 font-bold pb-1">Without Due Date</div>
                                    <QueuedTaskChips queuedTasks={queuedTasks.filter(queuedTask => queuedTask.TaskType !== 'DueDate')} openTrelloTaskFormInEditMode={openTrelloTaskFormInEditMode} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

export default AllTrelloTasks

function truncateText(text, length) {
    if (text.length > length) {
        return text.substring(0, length - 3) + '...'
    }
    return text
}

const formatDate = (value) => {
    // Parse the date in UTC
    const date = new Date(value)
    const utcDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())

    // Format the date
    return utcDate.toLocaleDateString()
}


const getColor = (queuedTask) => {
    switch (queuedTask.TaskType) {
        case 'Passed':
            return 'red'
        default:
            return 'green'
    }
}

function QueuedTaskChips({ queuedTasks, openTrelloTaskFormInEditMode }) {

    return (
        <>
            <ul className='p-0 m-0'>
                {queuedTasks.map((queuedTask, index) => {
                    return (
                        <>
                            <li className={`flex w-full flex-column md:flex-row md:align-items-center md:justify-content-between p-2 shadow-1 my-3 mr-0 bg-${queuedTask.Popped ? 'gray' : 'blue'}-50 cursor-pointer hover:bg-${queuedTask.Popped ? 'gray' : 'blue'}-100`}
                                style={{ borderRadius: '10px' }}
                                onClick={() => openTrelloTaskFormInEditMode(queuedTask)}
                            >
                                <div>
                                <div className='my-2'>
                                        <span className={`text-${queuedTask.Popped ? 'bluegray' : 'bluegray'}-700 font-bold`}>{queuedTask.MatterNumber}</span>
                                    </div>
                                    <div>
                                        <span className={`text-${queuedTask.Popped ? 'gray' : 'blue'}-700 font-bold`}>{queuedTask.Name}</span>
                                    </div>
                                    <p className={`text-${queuedTask.Popped ? 'gray' : 'blue'}-700 mt-2 mb-0`}>{queuedTask.Details}</p>
                                    <p className={`text-${queuedTask.Popped ? 'gray' : 'blue'}-700 mt-2 mb-0`}>{formatDate(queuedTask.Date)}</p>
                                </div>
                            </li>
                        </>
                    )
                })}
            </ul>
        </>
    )
}

function FilterTasksMenu({ setFilteredTasks, allTasks }) {
    const [filterItem, setFilterItem] = useState({ label: 'All', value: null })
    const [keyword, setKeyword] = useState('')
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [matterNumbers, setMatterNumbers] = useState([])
    const [taskNames, setFilteredTasksNames] = useState([])
    const [filterItems, setFilterItems] = useState([])

    // Find all unique task Names
    useEffect(() => {
        const _taskNames = allTasks.reduce((acc, curr) => {
            if (!acc.includes(curr.Name)) {
                acc.push(curr.Name)
            }
            return acc
        }, [])
        setFilteredTasksNames(_taskNames)
    }, [allTasks])

    // Find all unique matter numbers
    useEffect(() => {
        const _matterNumbers = allTasks.reduce((acc, curr) => {
            if (!acc.includes(curr.MatterNumber)) {
                acc.push(curr.MatterNumber)
            }
            return acc
        }, [])
        setMatterNumbers(_matterNumbers)
    }, [allTasks])

    useEffect(() => {
        const _filterItems = taskNames.map(taskName => {
            return {
                label: taskName,
                command: () => {
                    setFilterItem({ label: taskName, value: taskName });
                }
            }
        })
        setFilterItems(_filterItems)
    }, [taskNames])

    const items = [
        {
            label: filterItem.label,
            icon: 'pi pi-fw pi-filter',
            items: [
                {
                    label: 'All',
                    command: () => {
                        setFilterItem({ label: 'All', value: null });
                    }
                },
                {
                    label: 'Task Name',
                    items: filterItems
                },
                {
                    label: 'Matter Number',
                    items: matterNumbers.map(matterNumber => {
                        return {
                            label: matterNumber,
                            command: () => {
                                setFilterItem({ label: matterNumber, value: matterNumber });
                            }
                        }
                    })
                }
            ]
        }
    ];

    useEffect(() => {
        if (startDate && endDate) {
            setFilteredTasks(allTasks.filter(a => {
                return moment(a.Date).isBetween(startDate, endDate)
            }))
        }
    }, [startDate, endDate])

    const resetFilters = () => {
        setFilterItem({ label: 'All', value: null })
        setKeyword('')
        setStartDate(null)
        setEndDate(null)
    }

    const end = () => {
        return (
            <>
                <Calendar className="ml-2" value={startDate} onChange={(e) => setStartDate(e.value)} placeholder='Start Date' />
                <Calendar className="ml-2" value={endDate} onChange={(e) => setEndDate(e.value)} placeholder='End Date' />
                <InputText placeholder="Search" type="text" className="ml-2" value={keyword} onChange={(e) => setKeyword(e.target.value)} />
                <Button label="Clear" icon="pi pi-filter-slash" className="p-button-secondary ml-2" outlined onClick={() => resetFilters()} />
            </>
        )
    }

    useEffect(() => {
        if (filterItem.value) {
            let acc = allTasks.filter(a => {
                return (
                    filterItem.value.includes(a.Name) ||
                    filterItem.value.includes(a.MatterNumber)
                )
            })
            if (acc.length > 0) {
                setFilteredTasks(acc);
            }
        } else {
            setFilteredTasks(allTasks);
        }
    }, [filterItem])

    useEffect(() => {
        if (keyword) {
            setFilteredTasks(allTasks.filter(a => {
                return (a.AttorneyName && a.AttorneyName.toLowerCase().includes(keyword.toLowerCase())) ||
                    (a.Name && a.Name.toLowerCase().includes(keyword.toLowerCase())) ||
                    (a.Details && a.Details.toLowerCase().includes(keyword.toLowerCase())) ||
                    (a.MatterNumber && a.MatterNumber.toLowerCase().includes(keyword.toLowerCase()))
            }))
        } else {
            setFilteredTasks(allTasks);
        }
    }, [keyword])

    return (
        <>
            <Menubar model={items} end={end} />
        </>
    )
}