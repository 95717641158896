import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Chip } from 'primereact/chip'
import { Chart } from 'primereact/chart'
import axios from 'axios'
import api from '../../api'
import { ProgressSpinner } from 'primereact/progressspinner'
import BillingTable from './BillingTable'

const backgroundColors = [
    'rgba(255, 159, 64, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(153, 102, 255, 0.2)'
]

const borderColors = [
    'rgb(255, 159, 64)',
    'rgb(75, 192, 192)',
    'rgb(54, 162, 235)',
    'rgb(153, 102, 255)'
]

function Billing() {
    let { MatterNumber } = useParams()
    const [billChartData, setBillChartData] = useState({})
    const [hoursChartData, setHoursChartData] = useState({})
    const [billChartOptions, setBillChartOptions] = useState({})
    const [hoursChartOptions, setHoursChartOptions] = useState({})
    const [loading, setLoading] = useState(false)
    const [billingData, setBillingData] = useState(null)


    useEffect(() => {
        getBilling()
    }, [])

    const getBilling = () => {
        setLoading(true)
        api.get(`/clockify_billing_data/${MatterNumber}`)
            .then(response => {
                const data = response.data
                console.log('billing:', data)
                setBillingData(data)
                setLoading(false)
            })
            .catch(error => {
                console.error('Error:', error)
                setLoading(false)
            })
    }

    const getBackgroundColors = (length) => {
        let colors = []
        for (let i = 0; i < length; i++) {
            colors.push(backgroundColors[i % backgroundColors.length])
        }
        return colors
    }

    const getBorderColors = (length) => {
        let colors = []
        for (let i = 0; i < length; i++) {
            colors.push(borderColors[i % borderColors.length])
        }
        return colors
    }

    useEffect(() => {
        if (billingData && billingData.clockify_user_totals && Object.keys(billingData.clockify_user_totals).length > 0) {
            const billLabels = Object.keys(billingData.clockify_user_totals)
            const billData = billLabels.map(label => billingData.clockify_user_totals[label].total_bill)

            const billChartData = {
                labels: billLabels,
                datasets: [
                    {
                        label: 'Amount Billed',
                        data: billData,
                        backgroundColor: getBackgroundColors(billLabels.length),
                        borderColor: getBorderColors(billLabels.length),
                        borderWidth: 1
                    }
                ]
            }

            const billOptions = {
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            }

            setBillChartData(billChartData)
            setBillChartOptions(billOptions)

            const hoursLabels = Object.keys(billingData.clockify_user_totals)
            const hoursData = hoursLabels.map(label => billingData.clockify_user_totals[label].total_duration_hours.toFixed(2))

            const hoursChartData = {
                labels: hoursLabels,
                datasets: [
                    {
                        label: 'Hours Worked',
                        data: hoursData,
                        backgroundColor: getBackgroundColors(hoursLabels.length),
                        borderColor: getBorderColors(hoursLabels.length),
                        borderWidth: 1
                    }
                ]
            }

            const hoursOptions = {
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            }

            setHoursChartData(hoursChartData)
            setHoursChartOptions(hoursOptions)
        }
    }, [billingData])

    if (loading) return (
        <div className="flex align-items-center justify-content-center" style={{ height: '100%' }}>
            <ProgressSpinner />
        </div>
    )

    if (!loading && billingData) return (

        <>
            <BillingTable matterNumber={MatterNumber} billingData={billingData} setBillingData={setBillingData} />


            <div className="col-12 pt-0">
                <div className="grid">

                    <div className="col-12 xl:col-6 mb-6 xl:mb-0">
                        <div className="py-3 flex align-items-center mb-4 w-max">
                            <div style={{ width: '48px', height: '48px', borderRadius: '10px' }} className="bg-cyan-100 inline-flex align-items-center justify-content-center mr-3">
                                <i className="pi pi-clock text-cyan-500 text-3xl"></i>
                            </div>
                            <div>
                                <span className="text-600 text-md font-medium mb-2">Total Hours</span>
                                <p className="mt-1 mb-0 text-900 font-medium text-lg">
                                    {billingData
                                        ? (billingData.grand_total_hours || billingData.grand_total_hours === 0
                                            ? `${billingData.grand_total_hours.toFixed(2)} hrs`
                                            : <span className="p-text-secondary">Not defined</span>)
                                        : 'Loading...'}
                                </p>
                            </div>
                        </div>
                        <Chart type="bar" data={hoursChartData} options={hoursChartOptions} />
                    </div>

                    <div className="col-12 xl:col-6">
                        <div className="py-3 flex align-items-center mb-4 w-max">
                            <div style={{ width: '48px', height: '48px', borderRadius: '10px' }} className="bg-green-100 inline-flex align-items-center justify-content-center mr-3">
                                <i className="pi pi-dollar text-green-500 text-3xl"></i>
                            </div>
                            <div>
                                <span className="text-600 text-md font-medium mb-2">Total Bill</span>
                                <p className="mt-1 mb-0 text-900 font-medium text-lg">
                                    {billingData
                                        ? (billingData.grand_total_bill || billingData.grand_total_bill === 0
                                            ? `$${billingData.grand_total_bill}`
                                            : <span className="p-text-secondary">Not defined</span>)
                                        : 'Loading...'}
                                </p>
                            </div>
                        </div>
                        <Chart type="bar" data={billChartData} options={billChartOptions} />
                    </div>

                </div>
            </div >
        </>

    )

    return (
        <div className="flex align-items-center justify-content-center py-3">
            <span className="text-500">No Clockify data found</span>
        </div>
    )
}

export default Billing