import React, { useRef, useContext, useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { Rating } from 'primereact/rating';
import { Knob } from 'primereact/knob';
import ToastContext from '../../ToastContext';
import { Tag } from 'primereact/tag';
import api from '../../api';
import CaseAttributeTypes from './CaseAttributeTypes';
import NotificationRules from './NotificationRules';


function Admin() {
    const [loading, setLoading] = useState(false)
    const [result, setResult] = useState(null)

    const showToast = useContext(ToastContext);

    useEffect(() => {
        document.title = "Admin"
    },)

    const startTask = async () => {
        setLoading(true)

        try {
            // Trigger the background task
            const { data } = await api.post('/task', { name: 'John' })
            const taskId = data.task_id

            // Poll for task completion
            let taskResult = null
            while (true) {
                const { data } = await api.get(`/result/${taskId}`)
                if (data.state !== 'PENDING') {
                    taskResult = data.result
                    break
                }
                await new Promise(resolve => setTimeout(resolve, 2000)) // Polling interval
            }

            setResult(taskResult)
        } catch (error) {
            console.error('An error occurred:', error)
        }

        setLoading(false)
    }

    const resyncClockify = () => {
        startTask()
    }

    return (
        <div>
            <div className="surface-ground px-4 py-5">
                <div className="border-bottom-1 surface-border">
                    <span className="block text-3xl font-medium text-900 mb-4">Admin</span>
                </div>
            </div>
            <div className='px-4'>
                {/* <div className="surface-card p-4 shadow-2 border-round">
                    <div className="flex align-items-center">
                        <Button label={loading ? 'Resyncing... This will take a while. You can leave the page.' : 'Resync Clockify'} icon="pi pi-refresh" onClick={resyncClockify} />
                    </div>
                    <div>
                        <button onClick={startTask} disabled={loading}>
                            {loading ? 'Loading...' : 'Start Task'}
                        </button>
                        {result && <div>Result: {result}</div>}
                    </div>
                </div> */}

                <div className="surface-card p-4 shadow-2 border-round">
                    <CaseAttributeTypes />
                </div>

                <div className="surface-card p-4 my-4 shadow-2 border-round">
                    <NotificationRules />
                </div>
                
            </div>
        </div>
    )
}

export default Admin