import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useState, useEffect, useContext } from 'react'
import api from '../../api'
import { Tag } from 'primereact/tag'
import { Button } from 'primereact/button'
import ToastContext from '../../ToastContext'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { InputNumber } from 'primereact/inputnumber'
import { Calendar } from 'primereact/calendar'
import { Chip } from 'primereact/chip'
import { InputTextarea } from 'primereact/inputtextarea'
import ResolvedAttributeForm from './ResolvedAttributeForm'
import { useSelector } from 'react-redux'
import DemandSentAttributeForm from './DemandSentAttributeForm'

function Attributes({ matterNumber }) {
    const matter = useSelector(state => state.caseDetails.matter);
    const [attributes, setAttributes] = useState([])
    const [resolvedAttributeFormVisible, setResolvedAttributeFormVisible] = useState(false)
    const [demandSentAttributeFormVisible, setDemandSentAttributeFormVisible] = useState(false)

    const [attributeForm, setAttributeForm] = useState({
        visible: false,
        isEditing: false,
        currentAttribute: null,
    })

    const fetchAttributes = async () => {
        api.get(`/case_attributes/${matterNumber}`)
            .then(response => {
                const data = response.data
                setAttributes(data)
                console.log("Case Attributes:", data)
            })
            .catch(error => {
                console.error(error)
            })
    }

    useEffect(() => {
        fetchAttributes()
    }, [])

    const openAttributeForm = () => {
        setAttributeForm({
            visible: true,
            isEditing: false,
            attribute: null,
            type: null,
            value: null
        })
    }

    const openAttributeFormInEditMode = (attribute) => {
        setAttributeForm({
            visible: true,
            isEditing: true,
            attribute: attribute.Attribute,
            type: attribute.Type,
            value: attribute.Value,
            oldValue: attribute.Value
        })
    }

    return (
        <>
        
            <AttributeForm attributeForm={attributeForm} setAttributeForm={setAttributeForm} matterNumber={matterNumber} fetchAttributes={fetchAttributes} />
            <ResolvedAttributeForm visible={resolvedAttributeFormVisible} setVisible={setResolvedAttributeFormVisible} attributes={attributes} fetchAttributes={fetchAttributes} />
            <DemandSentAttributeForm visible={demandSentAttributeFormVisible} setVisible={setDemandSentAttributeFormVisible} attributes={attributes} fetchAttributes={fetchAttributes} />

            <div class="flex align-items-center justify-content-center text-red-500 py-5">
                <Button severity="secondary" icon='pi pi-plus' label="Add Attribute" raised onClick={() => openAttributeForm()} />
                {(matter.Stage === 'Settled/Resolved' && matter.Substage === 'Payments Complete/Fully Resolved') && (
                    <Button severity="success" icon='pi pi-plus' label="Add Resolution Attributes" raised onClick={() => setResolvedAttributeFormVisible(true)} className='ml-3' />
                )}
                {(matter.Stage === 'Demand Sent') && (
                    <Button severity="info" icon='pi pi-plus' label="Add Demand Sent Attributes" raised onClick={() => setDemandSentAttributeFormVisible(true)} className='ml-3' />
                )}
            </div>

            {attributes.length > 0 && (
                <div className='px-5'>

                    <div class="grid m-0 p-0 py-3 bg-bluegray-50 border-round border-1 border-bluegray-100">
                        {attributes.filter(attribute => attribute.Type === 'Date').length > 0 && (
                            <div class="col">
                                <div className='px-3'>
                                    <div className="text-500 font-bold pb-1">Dates</div>
                                    <AttributeChips attributes={attributes.filter(attribute => attribute.Type === 'Date')} openAttributeFormInEditMode={openAttributeFormInEditMode} />
                                </div>
                            </div>
                        )}
                        {attributes.filter(attribute => attribute.Type === 'Int' || attribute.Type === 'Float').length > 0 && (
                            <div class="col">
                                <div className='px-3'>
                                    <div className="text-500 font-bold pb-1">Numbers</div>
                                    <AttributeChips attributes={attributes.filter(attribute => attribute.Type === 'Int' || attribute.Type === 'Float')} openAttributeFormInEditMode={openAttributeFormInEditMode} />
                                </div>
                            </div>
                        )}
                        {attributes.filter(attribute => attribute.Type === 'Text').length > 0 && (
                            <div class="col">
                                <div className='px-3'>
                                    <div className="text-500 font-bold pb-1">Text</div>
                                    <AttributeChips attributes={attributes.filter(attribute => attribute.Type === 'Text')} openAttributeFormInEditMode={openAttributeFormInEditMode} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

export default Attributes

function truncateText(text, length) {
    if (text && text.length > length) {
        return text.substring(0, length - 3) + '...'
    }
    return text
}

function AttributeChips({ attributes, openAttributeFormInEditMode }) {
    const renderValue = (attribute) => {
        if (attribute.Type === 'Date') {
            // Just the date, no time
            return new Date(attribute.Value).toLocaleDateString()
        } else if (attribute.Type === 'Text') {
            return truncateText(attribute.Value, 30)
        }
        return attribute.Value
    }
    return (
        <>
            <ul className='p-0 m-0'>
                {attributes.map((attribute, index) => {
                    return (
                        <>
                            <li className="flex w-full flex-column md:flex-row md:align-items-center md:justify-content-between p-2 my-3 mr-0 surface-0 shadow-1 cursor-pointer hover:bg-bluegray-100"
                                style={{ borderRadius: '7px' }}
                                onClick={() => openAttributeFormInEditMode(attribute)}
                            >
                                <div>
                                    <div>
                                        <span className="text-bluegray-700 font-bold">{attribute.Attribute}</span>
                                    </div>
                                    <p className="text-bluegray-700 mt-2 mb-0">{renderValue(attribute)}</p>
                                </div>
                                {/* <span className="inline-flex justify-content-center align-items-center w-2rem h-2rem ml-3 border-circle border-1 border-bluegray-200 cursor-pointer hover:bg-bluegray-100">
                                    <i className="pi pi-times text-bluegray-700"></i>
                                </span> */}
                            </li>
                        </>
                    )
                })}
            </ul>
        </>
    )
}

function AttributeForm({ attributeForm, setAttributeForm, matterNumber, fetchAttributes }) {
    const showToast = useContext(ToastContext)
    const [attributeTypes, setAttributeTypes] = useState([]) // example: [{ Attribute: 'Date', Type: 'Date' }, { Attribute: 'Time', Type: 'Int' }, { Attribute: 'Description', Type: 'Text' }]
    const [selectedOption, setSelectedOption] = useState(null)

    useEffect(() => {
        api.get('/case_attribute_types').then(response => {
            const data = response.data
            setAttributeTypes(data)
            console.log('Attribute Types:', data)
        }).catch(error => {
            console.error(error)
        })
    }, [])

    useEffect(() => {
        console.log('attributeForm:', attributeForm)
    }, [attributeForm])

    const submit = () => {
        console.log('submit')
        const payload = {
            Attribute: attributeForm.attribute,
            Type: attributeForm.type,
            Value: attributeForm.value,
            MatterNumber: matterNumber,
        }

        if (attributeForm.isEditing) {
            payload.OldValue = attributeForm.oldValue
        }

        const apiMethod = attributeForm.isEditing ? api.put : api.post
        const endpoint = `/case_attributes`

        apiMethod(endpoint, payload)
            .then(response => {
                console.log(response.data)
                fetchAttributes()
                showToast({
                    severity: 'success',
                    summary: 'Successful',
                    detail: attributeForm.isEditing ? 'Attribute updated' : 'Attribute added to activity log'
                })
                setAttributeForm({
                    visible: false,
                    isEditing: false,
                    currentAttribute: null
                })
            })
            .catch(error => {
                console.error('Error:', error)
                showToast({ severity: 'error', summary: 'Error', detail: error.message })
            })
    }

    const deleteAttribute = () => {
        api.delete(`/case_attributes`, {
            data: {
                Attribute: attributeForm.attribute,
                Type: attributeForm.type,
                Value: attributeForm.value,
                MatterNumber: matterNumber,
            }
        })
            .then(response => {
                console.log(response.data)
                fetchAttributes()
                showToast({ severity: 'success', summary: 'Successful', detail: 'Attribute deleted' })
                setAttributeForm({
                    visible: false,
                    isEditing: false,
                    currentAttribute: null
                })
            })
            .catch(error => {
                console.error('Error:', error)
                showToast({ severity: 'error', summary: 'Error', detail: error.message })
            })
    }

    useEffect(() => {
        console.log(attributeForm.type)
        console.log(attributeForm.attribute)
        const so = {
            Attribute: attributeForm.attribute,
            Type: attributeForm.type
        }
        setSelectedOption(so)
    }, [attributeForm.visible])

    useEffect(() => {
        console.log('selectedOption:', selectedOption)
    }, [selectedOption])

    const formatDate = (date) => {
        // Need to change it from this format: 2023-10-21T06:00:00.000Z
        // to this format: Tue Oct 10 2023 00:00:00 GMT-0600 (Mountain Daylight Time)
        const dateObj = new Date(date)
        return dateObj
    }

    const getInputComponent = () => {
        switch (attributeForm.type) {
            case 'Date':
                return <Calendar id="attributeContent" value={formatDate(attributeForm.value)} onChange={(e) => setAttributeForm({ ...attributeForm, value: e.value })} showIcon />
            case 'Int':
                return <InputNumber id="attributeContent" value={attributeForm.value} onValueChange={(e) => setAttributeForm({ ...attributeForm, value: e.value })} />
            case 'Float':
                return <InputNumber id="attributeContent" value={attributeForm.value} onValueChange={(e) => setAttributeForm({ ...attributeForm, value: e.value })} minFractionDigits={2} maxFractionDigits={10} />
            case 'Text':
                return <InputTextarea id="attributeContent" value={attributeForm.value} onChange={(e) => setAttributeForm({ ...attributeForm, value: e.target?.value })} rows={5} cols={30} autoResize />
            default:
                return null
        }
    }

    const selectAttributeType = (e) => {
        setSelectedOption(e.value)
        if (e.value.Type === 'Date') {
            setAttributeForm({ ...attributeForm, value: new Date(), type: e.value.Type, attribute: e.value.Attribute })
        } else {
            setAttributeForm({ ...attributeForm, type: e.value.Type, attribute: e.value.Attribute })
        }
    }

    const footerContent = (
        <div>
            {attributeForm.isEditing && <Button label="Delete" icon="pi pi-times" className="p-button-danger" outlined onClick={deleteAttribute} />}
            <Button label={attributeForm.isEditing ? 'Update' : 'Submit'} icon="pi pi-check" outlined onClick={submit} autoFocus disabled={attributeForm.value === null} />
        </div>
    )

    return (
        <>
            <Dialog
                header={attributeForm.isEditing ? 'Edit Attribute' : 'Add Attribute'}
                visible={attributeForm.visible}
                style={{ width: '50vw' }}
                onHide={() => setAttributeForm({
                    visible: false,
                    isEditing: false,
                    attribute: null,
                    type: null,
                    value: null
                })}
                footer={footerContent}
            >
                <div className='grid formgrid p-fluid'>
                    <div className="field mb-4 col-12">
                        <Dropdown id="attributeType"
                            className='mb-4'
                            value={selectedOption}
                            options={attributeTypes}
                            onChange={(e) => {
                                selectAttributeType(e)
                            }}
                            disabled={attributeForm.isEditing}
                            optionLabel="Attribute"
                            placeholder="Select a type" />
                        {(attributeForm.type !== null) && getInputComponent()}
                    </div>
                </div>
            </Dialog>
        </>
    )

}

