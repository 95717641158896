import { useSelector, useDispatch } from 'react-redux';
import {
    currentlyEditingEvent,
    setCurrentlyEditingEvent,
} from '../../store/CalendarInput/action';
import { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import PrimaryInfo from './1_PrimaryInfo';
import DateTimeInfo from './2_DateTimeInfo';
import ExtraInfo from './3_ExtraInfo';
import Actions from './5_Actions';
import EventsTableDialog from './6_EventsTableDialog';
import TrelloTaskInfo from './4_TrelloTaskInfo';



function CalendarInput() {
    const dispatch = useDispatch();
    const currentlyEditingEvent = useSelector(state => state.calendarInput.currentlyEditingEvent);

    const clientNameRef = useRef("")
    const matterNumberRef = useRef("")

    return (
        <div className='pb-8'>
            <div className='pb-8'>
                <div className="surface-ground px-4 py-5">
                    <div className="border-bottom-1 surface-border">
                        <span className="block text-3xl font-medium text-900 mb-2">Calendar Input</span>
                        <div className="mb-4">
                            {currentlyEditingEvent && (
                                <Button label={`Currently Editing: ${currentlyEditingEvent.summary}`} severity="help" size="small" icon='pi pi-times' className="p-button-text" onClick={() => dispatch(setCurrentlyEditingEvent(null))} />
                            )}
                        </div>
                    </div>
                </div>
                <div className='px-4 pb-4'>
                    <div className="surface-card p-4 shadow-2 border-round p-fluid">
                        <div className="grid formgrid p-fluid">

                            <PrimaryInfo clientNameRef={clientNameRef} matterNumberRef={matterNumberRef} />

                            <div className="surface-border border-top-1 opacity-50 mb-4 col-12"></div>

                            <DateTimeInfo />

                            <div className="surface-border border-top-1 opacity-50 mb-4 col-12"></div>

                            <ExtraInfo />

                            <div className="surface-border border-top-1 opacity-50 mb-4 col-12"></div>

                            {!currentlyEditingEvent && (
                                <>
                                    <TrelloTaskInfo />
                                    <div className="surface-border border-top-1 opacity-50 mb-4 col-12"></div>
                                </>
                            )}

                        </div>

                        <Actions clientNameRef={clientNameRef} matterNumberRef={matterNumberRef} />

                    </div>
                </div>
            </div>

            <EventsTableDialog />

        </div>
    )
}

export default CalendarInput













