// Action Types
export const SET_TIME_ZONE = 'SET_TIME_ZONE';
export const SET_CLIENT_NAME = 'SET_CLIENT_NAME';
export const SET_MATTER_NUMBER = 'SET_MATTER_NUMBER';
export const SET_TITLE = 'SET_TITLE';
export const SET_TITLE2 = 'SET_TITLE2';
export const SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY';
export const SET_SELECTED_FILES = 'SET_SELECTED_FILES';
export const SET_START_DATETIME_12H = 'SET_START_DATETIME_12H';
export const SET_END_DATETIME_12H = 'SET_END_DATETIME_12H';
export const SET_ALLDAY = 'SET_ALLDAY';
export const SET_DATE = 'SET_DATE';
export const SET_DATERANGE = 'SET_DATERANGE';
export const SET_NOTES = 'SET_NOTES';
export const SET_FILE_IDS = 'SET_FILE_IDS';
export const SET_CREATE_TASK = 'SET_CREATE_TASK';
export const SET_SELECTED_TRELLO_MEMBER = 'SET_SELECTED_TRELLO_MEMBER';
export const SET_DAYS_BEFORE_DEADLINE = 'SET_DAYS_BEFORE_DEADLINE';
export const SET_TRELLO_DATE = 'SET_TRELLO_DATE';
export const SET_EVENTS_TABLE_VISIBLE = 'SET_EVENTS_TABLE_VISIBLE';
export const SET_CURRENTLY_EDITING_EVENT = 'SET_CURRENTLY_EDITING_EVENT';
export const SET_MATTERS = 'SET_MATTERS';
export const SET_MATTER_NUMBERS = 'SET_MATTER_NUMBERS';
export const SET_CLIENTS = 'SET_CLIENTS';
export const SET_DEFENDANTS = 'SET_DEFENDANTS';
export const SET_FILTERED_CLIENTS = 'SET_FILTERED_CLIENTS';
export const SET_FILTERED_MATTERS = 'SET_FILTERED_MATTERS';
export const SET_TOTAL_SIZE = 'SET_TOTAL_SIZE';
export const SET_UPLOADED_FILES = 'SET_UPLOADED_FILES';
export const SET_FILE_UPLOADING = 'SET_FILE_UPLOADING';
export const SET_KEY = 'SET_KEY';
export const SET_CATEGORY = 'SET_CATEGORY';
export const SET_ADD_NOW = 'SET_ADD_NOW';
export const SET_LOCATION = 'SET_LOCATION';
export const SET_TRELLO_CARD_TITLE = 'SET_TRELLO_CARD_TITLE';


// Action Creators
export const setTimeZone = timeZone => ({ type: SET_TIME_ZONE, payload: timeZone });
export const setClientName = clientName => ({ type: SET_CLIENT_NAME, payload: clientName });
export const setMatterNumber = matterNumber => ({ type: SET_MATTER_NUMBER, payload: matterNumber });
export const setTitle = title => ({ type: SET_TITLE, payload: title });
export const setTitle2 = title2 => ({ type: SET_TITLE2, payload: title2 });
export const setSelectedCategory = category => ({ type: SET_SELECTED_CATEGORY, payload: category });
export const setSelectedFiles = files => ({ type: SET_SELECTED_FILES, payload: files });
export const setStartDateTime12h = startDateTime => ({ type: SET_START_DATETIME_12H, payload: startDateTime });
export const setEndDateTime12h = endDateTime => ({ type: SET_END_DATETIME_12H, payload: endDateTime });
export const setAllday = allday => ({ type: SET_ALLDAY, payload: allday });
export const setDate = date => ({ type: SET_DATE, payload: date });
export const setDaterange = daterange => ({ type: SET_DATERANGE, payload: daterange });
export const setNotes = notes => ({ type: SET_NOTES, payload: notes });
export const setFileIds = fileIds => ({ type: SET_FILE_IDS, payload: fileIds });
export const setCreateTask = createTask => ({ type: SET_CREATE_TASK, payload: createTask });
export const setSelectedTrelloMember = member => ({ type: SET_SELECTED_TRELLO_MEMBER, payload: member });
export const setDaysBeforeDeadline = days => ({ type: SET_DAYS_BEFORE_DEADLINE, payload: days });
export const setTrelloDate = date => ({ type: SET_TRELLO_DATE, payload: date });
export const setEventsTableVisible = isVisible => ({ type: SET_EVENTS_TABLE_VISIBLE, payload: isVisible });
export const setCurrentlyEditingEvent = event => ({ type: SET_CURRENTLY_EDITING_EVENT, payload: event });
export const setMatters = matters => ({ type: SET_MATTERS, payload: matters });
export const setMatterNumbers = numbers => ({ type: SET_MATTER_NUMBERS, payload: numbers });
export const setClients = clients => ({ type: SET_CLIENTS, payload: clients });
export const setDefendants = defendants => ({ type: SET_DEFENDANTS, payload: defendants });
export const setFilteredClients = clients => ({ type: SET_FILTERED_CLIENTS, payload: clients });
export const setFilteredMatters = matters => ({ type: SET_FILTERED_MATTERS, payload: matters });
export const setTotalSize = size => ({ type: SET_TOTAL_SIZE, payload: size });
export const setUploadedFiles = files => ({ type: SET_UPLOADED_FILES, payload: files });
export const setFileUploading = isUploading => ({ type: SET_FILE_UPLOADING, payload: isUploading });
export const setKey = key => ({ type: SET_KEY, payload: key });
export const setCategory = category => ({ type: SET_CATEGORY, payload: category });
export const setAddNow = addNow => ({ type: SET_ADD_NOW, payload: addNow });
export const setLocation = location => ({ type: SET_LOCATION, payload: location });
export const setTrelloCardTitle = title => ({ type: SET_TRELLO_CARD_TITLE, payload: title });
