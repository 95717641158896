import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { Chart } from 'primereact/chart';
import { Tag } from 'primereact/tag';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputSwitch } from 'primereact/inputswitch';
import { TabView, TabPanel } from 'primereact/tabview';
import ToastContext from '../../ToastContext';
import styled from 'styled-components';
import api from '../../api';

const CustomInputText = styled(InputText)`
    border-radius: 0 !important;
    border-top: none !important;
    border-right: none !important;
    border-bottom: none !important;
    height: 100%;
    width: 100%;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }
`;

const CustomInputTextarea = styled(InputTextarea)`
    border-radius: 0 !important;
    border-top: none !important;
    border-right: none !important;
    border-bottom: none !important;
    height: 100%;
    width: 100%;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }
    `;



function Clients({ clients, setClients, matterNumber, fetchMatter }) {
	const [activeIndex, setActiveIndex] = useState(0)
	const [isEditing, setIsEditing] = useState(false)
	const showToast = useContext(ToastContext);

	const addNewClient = () => {
		const newClient = {
			ClientName: "",
			ClientPhone: "",
			ClientEmail: "",
			AdditionalPhone: "",
			AdditionalEmails: "",
			Notes: "",
			PrimaryClient: false,
			IsNew: true
		};

		setClients([...clients, newClient]);
		setActiveIndex(clients.length); // Switch to the new tab
	}

	const setClient = (updatedClient, index) => {
		const newClients = [...clients];
		newClients[index] = updatedClient;
		setClients(newClients);
	}

	const removeClient = (index) => {
		const newClients = [...clients]
		newClients.splice(index, 1)
		setClients(newClients)
		if (index === activeIndex) {
			setActiveIndex(0)
		}
	}

	const handleSave = async () => {
		setIsEditing(false);
		try {
			// Remove isNew property from clients and add MatterNumber
			const clientsToSave = clients.map(client => {
				const { IsNew, ...rest } = client;
				rest.MatterNumber = matterNumber;
				return rest;
			});
			const response = await api.post('clients', clientsToSave);
			fetchMatter();

			if (response.status !== 201) {  // assuming 201 for successful resource creation
				showToast({ severity: 'error', summary: 'Error', detail: 'Failed to update client information' });
				return;
			}

			showToast({ severity: 'success', summary: 'Successful', detail: 'Updated client information' });
		} catch (error) {
			console.error(error);
			showToast({ severity: 'error', summary: 'Error', detail: 'An error occurred while updating client information' });
		}
	}


	return (
		<>
			<div className="col-12 xl:col-6">
				<div className="surface-card p-4 shadow-2 border-round overflow-hidden">
					<div className="flex justify-content-between align-items-center mb-1">
						<span className="text-xl text-900 font-medium">Clients</span>
						<div>
							{isEditing ? (
								<Button icon="pi pi-check" severity="success" className="mr-2" rounded outlined onClick={handleSave} />
							) : null}
							<Button icon="pi pi-plus" severity="secondary" label="Add Client" rounded outlined onClick={addNewClient} />
						</div>
					</div>
					<TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
						{clients.map((client, index) => (
							<TabPanel header={client.IsNew ? "New Client" : client.ClientName} key={index}>
								<ClientInfo client={client} setClient={(updatedClient) => setClient(updatedClient, index)} isEditing={isEditing} setIsEditing={setIsEditing} removeClient={() => removeClient(index)} />
							</TabPanel>
						))}
					</TabView>
				</div>
			</div>
		</>
	)
}


function ClientInfo({ client, setClient, isEditing, setIsEditing, removeClient }) {
	const attributes = [
		{ label: "Name", key: "ClientName", value: client.ClientName },
		{ label: "Phone", key: "ClientPhone", value: client.ClientPhone },
		{ label: "Email", key: "ClientEmail", value: client.ClientEmail },
		{ label: "Additional Phones", key: "AdditionalPhone", value: client.AdditionalPhone },
		{ label: "Additional Emails", key: "AdditionalEmails", value: client.AdditionalEmails },
		{ label: "Notes", key: "Notes", value: client.Notes },
	];

	if (client.PipedriveID) {
		attributes.push({ label: "Pipedrive ID", key: "PipedriveID", value: client.PipedriveID });
	} else {
		attributes.push({ label: "Pipedrive ID", key: "PipedriveID", value: "" });
	}
	if (client.FileUploadURL) {
		attributes.push({ label: "File Upload URL", key: "FileUploadURL", value: client.FileUploadURL });
	}

	attributes.push({ label: "Primary Client", key: "PrimaryClient", value: client.PrimaryClient })

	const handleInputChange = (e, attributeKey) => {
		let newValue;
		if (attributeKey === 'PrimaryClient') {
			newValue = e.value; // e.value will be true or false
		} else {
			newValue = e.target?.value;
		}
		const newClient = { ...client };
		newClient[attributeKey] = newValue;
		setClient(newClient);
	};


	useEffect(() => {
		console.log("Client:", client);
	}, [client]);



	return (
		<div onClick={() => setIsEditing(true)}>
			<ul className="list-none p-0 m-0">
				{attributes.map((attribute, index) => (
					<li
						key={index}
						className="flex align-items-center py-0 px-2 border-top-1 surface-border flex-wrap"
					>
						<div className="text-500 w-12 md:w-6 py-3 font-medium">
							{attribute.label}
						</div>
						<div className={`text-900 w-12 md:w-6 md:flex-order-0 flex-order-1`}>
							{isEditing ? (
								attribute.key === 'Notes' ? (
									<CustomInputTextarea
										value={attribute.value || ''}
										onChange={(e) => handleInputChange(e, attribute.key)}
									/>
								) : attribute.key === 'PrimaryClient' ? (
									<InputSwitch
										checked={attribute.value === true}
										onChange={(e) => handleInputChange(e, attribute.key)}
									/>
								) : attribute.key === 'FileUploadURL' ? (
									<FileUploadURL attribute={attribute} />
								) : (
									<CustomInputText
										value={attribute.value || ''}
										onChange={(e) => handleInputChange(e, attribute.key)}
									/>
								)
							) : (
								attribute.key === 'PrimaryClient' ? (
									<InputSwitch
										disabled
										checked={attribute.value === true}
										onChange={(e) => handleInputChange(e, attribute.key)}
									/>
								) :
									attribute.value === null || attribute.value === undefined || attribute.value === ''
										? (attribute.key === 'PipedriveID' ?
											<span className="p-text-secondary"><i className="pi pi-exclamation-triangle text-yellow-500"></i> Pipedrive ID is needed for File Upload URL</span> :
											<span className="p-text-secondary">Not Available</span>
										)
										: (
											attribute.key === 'FileUploadURL' ?
												<FileUploadURL attribute={attribute} /> :
												attribute.value.toString()
										)
							)}
						</div>
					</li>
				))}
			</ul>
			{isEditing ? (
				<div className="mt-4">
					<Button icon="pi pi-times" label="Detach client" severity="danger" rounded outlined onClick={removeClient} />
				</div>
			) : null}
		</div>
	);

}


export default Clients;

function FileUploadURL({ attribute }) {
	const showToast = useContext(ToastContext);

	return (
		<div className="flex items-center py-3">
			<div className="flex-1 min-w-0">
				<div
					className='py-2 text-gray-500'
					style={{
						// border: '2px solid #2a70a5',
						// padding: '20px 0',
						whiteSpace: 'nowrap',
						width: '100%',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						// fontSize: '50px'
					}}>
					{attribute.value}
				</div>
			</div>
			<Button
				className="ml-2 bg-primary text-white p-2 rounded"
				onClick={(e) => {
					e.stopPropagation();
					navigator.clipboard.writeText(attribute.value);
					showToast({ severity: 'success', summary: 'Copied', detail: 'File Upload URL copied to clipboard' });
				}}
			>
				Copy
			</Button>
		</div>
	)
}