import {
    SET_MATTER,
    SET_REFRESH_TRELLO_TASKS
} from './action';

const initialState = {}

const caseDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MATTER:
            return { ...state, matter: action.payload };
        case SET_REFRESH_TRELLO_TASKS:
            return { ...state, refreshTrelloTasks: action.payload };
        default:
            return state;
    }
}

export default caseDetailsReducer;