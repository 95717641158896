import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { Chart } from 'primereact/chart';
import { Tag } from 'primereact/tag';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { AutoComplete } from 'primereact/autocomplete';
import { MultiSelect } from 'primereact/multiselect';
import ToastContext from '../../ToastContext';
import styled from 'styled-components';
import api from '../../api';
import { Tooltip } from 'primereact/tooltip';
import DatePicker from 'react-date-picker';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { setMatter } from '../../store/CaseDetails/action';
import { useDispatch, useSelector } from 'react-redux';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TabPanel, TabView } from 'primereact/tabview';
import TrelloTaskForm from './TrelloTaskForm';
import {
	setLoading as setAppLoading,
} from '../../store/App/action';


const CustomInputText = styled(InputText)`
    border-radius: 0 !important;
    border-top: none !important;
    border-right: none !important;
    border-bottom: none !important;
    height: 100%;
    width: 100%;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }
`;

const CustomDropdown = styled(Dropdown)`
	border-radius: 0 !important;
	border-top: none !important;
	border-right: none !important;
	border-bottom: none !important;
	height: 100%;
	width: 100%;
	padding-top: 0.25rem !important;
	padding-bottom: 0.25rem !important;
	margin-bottom: 0rem !important;
	margin-top: 0rem !important;
	&:focus {
		outline: none !important;
		box-shadow: none !important;
	}
`;

const CustomAutoComplete = styled(AutoComplete)`
	ul {
		border-radius: 0 !important;
		border-top: none !important;
		border-right: none !important;
		border-bottom: none !important;
		height: 100%;
		width: 100%;
		padding-top: 0rem !important;
		padding-bottom: 0rem !important;
		margin-bottom: 0rem !important;
		margin-top: 0rem !important;
		&:focus {
			outline: none !important;
			box-shadow: none !important;
		}
		outline: none !important;
		box-shadow: none !important;
	}
	span {
		&:focus {
			outline: none !important;
			box-shadow: none !important;
		}
		outline: none !important;
		box-shadow: none !important;
	}
	outline: none !important;
	box-shadow: none !important;
`;

const stages = {
	1: 'Pre-Demand',
	2: 'Demand Sent',
	3: 'Arbitration-AAA',
	4: 'Arbitration-JAMS',
	5: 'Arbitration-Other',
	6: 'State Court',
	7: 'Federal Court',
	8: 'Appeal',
	9: 'Post Judgment',
	10: 'Settled/Resolved',
	11: 'Withdrawn'
};

const courtSubstages = {
	1: 'Complaint Filed',
	2: 'Service Complete',
	3: 'Motion to Dismiss Filed',
	4: 'Discovery',
	5: 'Stayed',
	6: 'Dispositive Motion Pending',
	7: 'Trial Upcoming',
	8: 'Appeal'
};

const settledSubstages = {
	9: 'Settled in Principal',
	10: 'Agreement Executed & Payment(s) Pending',
	11: 'Payments Complete/Fully Resolved'
};

const getOptions = (items, labelField, valueField, keyField) =>
	items.map(item => ({
		label: item[labelField],
		value: item[valueField],
		key: item[keyField]
	}))

const useMatterAttributes = (matter, allAttorneys, allDefendants) => {
	const [attributes, setAttributes] = useState([])
	const [selectedAttorneys, setSelectedAttorneys] = useState([])
	const [selectedDefendants, setSelectedDefendants] = useState([])
	const [localJurisdiction, setLocalJurisdiction] = useState('')
	const [localCaseNumber, setLocalCaseNumber] = useState('')
	const [localStage, setLocalStage] = useState('')
	const [localSubstage, setLocalSubstage] = useState('')

	useEffect(() => {
		console.log('useMatterAttributes', matter, allAttorneys, allDefendants);
		const newAttributes = [
			{
				label: 'Attorneys',
				key: 'Attorneys',
				value: matter.attorneys,
				options: getOptions(allAttorneys, 'Name', 'id', 'id')
			},
			{
				label: 'Defendants',
				key: 'Defendants',
				value: matter.defendants,
				options: getOptions(allDefendants, 'PartyName', 'id', 'id')
			},
			{ label: 'Jurisdiction', key: 'Jurisdiction', value: matter.Jurisdiction },
			{ label: 'Case Number', key: 'CaseNumber', value: matter.CaseNumber },
			{ label: 'Stage', key: 'Stage', value: matter.Stage },
		]

		if (['State Court', 'Federal Court', 'Settled/Resolved'].includes(matter.Stage)) {
			newAttributes.push({ label: 'Substage', key: 'Substage', value: matter.Substage })
		}

		setAttributes(newAttributes)
		// If different attorneys or defendants are selected, update the state
		if (selectedAttorneys !== matter.attorneys) setSelectedAttorneys(matter.attorneys)
		if (selectedDefendants !== matter.defendants) setSelectedDefendants(matter.defendants)
		if (localJurisdiction !== matter.Jurisdiction) setLocalJurisdiction(matter.Jurisdiction)
		if (localCaseNumber !== matter.CaseNumber) setLocalCaseNumber(matter.CaseNumber)
		if (localStage !== matter.Stage) setLocalStage(matter.Stage)
		if (localSubstage !== matter.Substage) setLocalSubstage(matter.Substage)
	}, [matter, allAttorneys, allDefendants])

	useEffect(() => {
		// If user changes stage, update the substage
		if (localStage !== matter.Stage) {
			const newAttributes = [...attributes];
			// If the new stage is not a court stage, remove the substage
			if (!['State Court', 'Federal Court', 'Settled/Resolved'].includes(localStage)) {
				const substageIndex = newAttributes.findIndex(attribute => attribute.key === 'Substage');
				if (substageIndex > -1) {
					newAttributes.splice(substageIndex, 1);
				}
			} else {
				// If the new stage is a court stage, add the substage if it doesn't already exist
				const substageIndex = newAttributes.findIndex(attribute => attribute.key === 'Substage');
				if (substageIndex === -1) {
					newAttributes.push({ label: 'Substage', key: 'Substage', value: '' });
				}
			}
			setAttributes(newAttributes);
		}
	}, [localStage])

	return {
		attributes,
		selectedAttorneys,
		selectedDefendants,
		localJurisdiction,
		localCaseNumber,
		localStage,
		localSubstage,
		setSelectedAttorneys,
		setSelectedDefendants,
		setLocalJurisdiction,
		setLocalCaseNumber,
		setLocalStage,
		setLocalSubstage
	}
}

function CaseInfo({ fetchMatter }) {
	const dispatch = useDispatch();
	const matter = useSelector(state => state.caseDetails.matter);
	const showToast = useContext(ToastContext)
	const [isEditing, setIsEditing] = useState(false)
	const [allAttorneys, setAllAttorneys] = useState([]);
	const [allDefendants, setAllDefendants] = useState([]);

	const {
		attributes,
		selectedAttorneys,
		selectedDefendants,
		localJurisdiction,
		localCaseNumber,
		localStage,
		localSubstage,
		setSelectedAttorneys,
		setSelectedDefendants,
		setLocalJurisdiction,
		setLocalCaseNumber,
		setLocalStage,
		setLocalSubstage
	} = useMatterAttributes(matter, allAttorneys, allDefendants)

	const handleSelectChange = (label, value, attributeKey) => {
		if (attributeKey === 'Stage') {
			setLocalStage(value);
			// if (['State Court', 'Federal Court'].includes(value)) {
			// 	setLocalSubstage('Complaint Filed');
			// } else {
			// 	setLocalSubstage('');
			// }
		} else if (attributeKey === 'Substage') {
			setLocalSubstage(value);
		}
	};

	useEffect(() => {
		console.log('selectedDefendants', selectedDefendants);
	}, [selectedDefendants]);

	useEffect(() => {
		console.log('selectedAttorneys', selectedAttorneys);
	}, [selectedAttorneys]);

	useEffect(() => {
		// Fetch the attorneys from your API
		api.get('attorneys')
			.then(response => setAllAttorneys(response.data))
			.catch(error => console.error('An error occurred while fetching attorneys:', error));

		// Fetch the defendants from your API
		api.get('defendants')
			.then(response => setAllDefendants(response.data))
			.catch(error => console.error('An error occurred while fetching defendants:', error));
	}, []);

	const handleSave = async () => {
		dispatch(setAppLoading(true))
		setIsEditing(false);
		try {
			let m = { ...matter };
			m.defendants = selectedDefendants;
			m.attorneys = selectedAttorneys;
			m.Jurisdiction = localJurisdiction;
			m.CaseNumber = localCaseNumber;
			m.Stage = localStage;
			m.Substage = localSubstage;
			const response = await api.post('case-info', m);
			fetchMatter();

			if (response.status !== 201) {  // assuming 201 for successful resource creation
				showToast({ severity: 'error', summary: 'Error', detail: 'Failed to update case information' });
				dispatch(setAppLoading(false))
				return;
			}

			showToast({ severity: 'success', summary: 'Successful', detail: 'Updated case information' });
			dispatch(setAppLoading(false))
		} catch (error) {
			console.error(error);
			showToast({ severity: 'error', summary: 'Error', detail: 'An error occurred while updating case information' });
			dispatch(setAppLoading(false))
		}
	}

	const substage = () => {
		if (['State Court', 'Federal Court'].includes(localStage)) {
			return (
				<CustomDropdown
					value={localSubstage}
					options={Object.entries(courtSubstages).map(([key, value]) => ({ label: value, value: value }))}
					onChange={(e) => handleSelectChange(e.label, e.value, 'Substage')}
				/>
			)
		} else if (localStage === 'Settled/Resolved') {
			return (
				<CustomDropdown
					value={localSubstage}
					options={Object.entries(settledSubstages).map(([key, value]) => ({ label: value, value: value }))}
					onChange={(e) => handleSelectChange(e.label, e.value, 'Substage')}
				/>
			)
		}
	}

	return (
		<div className="col-12 xl:col-6">
			<div className="surface-card p-4 shadow-2 border-round">
				<div className="flex justify-content-between align-items-center mb-5">
					<span className="text-xl text-900 font-medium">Case Information</span>
					{isEditing && <Button icon="pi pi-check" severity="success" rounded outlined onClick={handleSave} />}
				</div>
				<div onClick={() => setIsEditing(true)}>
					<ul className="list-none p-0 m-0">
						{attributes.map((attribute, index) => (
							<li
								key={attribute.key}
								className="flex align-items-center py-0 px-2 border-top-1 surface-border flex-wrap"
							>
								<div className="text-500 w-6 md:w-6 py-3 font-medium">
									{attribute.label}
								</div>
								<div className="text-900 w-full md:w-6 md:flex-order-0 flex-order-1">
									{isEditing ? (
										{
											'Stage': (
												<CustomDropdown
													value={localStage}
													options={Object.entries(stages).map(([key, value]) => ({ label: value, value: value }))}
													onChange={(e) => handleSelectChange(e.label, e.value, attribute.key)}
												/>
											),
											'Substage': (
												substage()
											),
											'Attorneys': (
												<AttorneysSelect
													selectedAttorneys={selectedAttorneys}
													setSelectedAttorneys={setSelectedAttorneys}
													allAttorneys={allAttorneys}
												/>
											),
											'Defendants': (
												<DefendantsSelect
													selectedDefendants={selectedDefendants}
													setSelectedDefendants={setSelectedDefendants}
													allDefendants={allDefendants}
												/>
											),
											'Jurisdiction': (
												<CustomInputText
													value={localJurisdiction}
													onChange={(e) => setLocalJurisdiction(e.target.value)}
												/>
											),
											'CaseNumber': (
												<CustomInputText
													value={localCaseNumber}
													onChange={(e) => setLocalCaseNumber(e.target.value)}
												/>
											),
											'default': (
												<CustomInputText
													value={attribute.value || ''}
												/>
											)
										}[attribute.key] || <CustomInputText value={attribute.value || ''} />
									) : (
										{
											'Attorneys': Array.isArray(attribute.value) && attribute.value.length > 0
												? attribute.value.map((attorney) => attorney.Name).join(', ')
												: <span className="p-text-secondary">Not Available</span>,
											'Defendants': Array.isArray(attribute.value) && attribute.value.length > 0
												? attribute.value.map((defendant) => defendant.PartyName).join(', ')
												: <span className="p-text-secondary">Not Available</span>,
											'default': attribute.value === null || attribute.value === undefined
												? <span className="p-text-secondary">Not Available</span>
												: attribute.value.toString()
										}[attribute.key] || (attribute.value === null || attribute.value === undefined ? <span className="p-text-secondary">Not Available</span> : attribute.value.toString())
									)}
								</div>
							</li>
						))}

					</ul>
				</div>
			</div>
		</div>
	);

}


export default CaseInfo;


function AttorneysSelect({ selectedAttorneys, setSelectedAttorneys, allAttorneys }) {
	const [filteredAttorneys, setFilteredAttorneys] = useState([]);
	const [query, setQuery] = useState('');

	const search = (event) => {
		setQuery(event.query); // update the query here
		setTimeout(() => {
			let _filteredAttorneys;
			if (!event.query.trim().length) {
				_filteredAttorneys = [...allAttorneys];
			} else {
				_filteredAttorneys = allAttorneys.filter((attorney) => {
					return attorney.Name.toLowerCase().includes(event.query.toLowerCase());
				});
			}
			setFilteredAttorneys(_filteredAttorneys);
		}, 250);
	}

	const handleKeyPress = (e) => {
		if (e.key === 'Enter' && query.length > 0) {
			const newItem = { Name: query };
			setSelectedAttorneys([...selectedAttorneys, newItem], 'attorneys');
			setQuery('');  // Clear the query
		}
	}



	return (
		<CustomAutoComplete
			value={selectedAttorneys}
			suggestions={filteredAttorneys}
			completeMethod={search}
			onChange={(e) => setSelectedAttorneys(e.value)}
			onKeyPress={handleKeyPress} // Listen for the Enter key
			field="Name"
			multiple
			className="w-full"
		/>
	);
}

function DefendantsSelect({ selectedDefendants, setSelectedDefendants, allDefendants }) {
	const [filteredDefendants, setFilteredDefendants] = useState([]);
	const [query, setQuery] = useState('');
	const [autoCompleteKey, setAutoCompleteKey] = useState(0);

	const search = (event) => {
		setQuery(event.query); // update the query here
		setTimeout(() => {
			let _filteredDefendants;
			if (!event.query.trim().length) {
				_filteredDefendants = [...allDefendants];
			} else {
				_filteredDefendants = allDefendants.filter((defendant) => {
					return defendant.PartyName.toLowerCase().includes(event.query.toLowerCase());
				});
			}
			setFilteredDefendants(_filteredDefendants);
		}, 250);
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter' && query.length > 0) {
			const newItem = { PartyName: query };
			setSelectedDefendants([...selectedDefendants, newItem]);
			setQuery('');  // Clear the query
			setAutoCompleteKey(autoCompleteKey + 1);
		}
	};

	return (
		<CustomAutoComplete
			value={selectedDefendants}
			suggestions={filteredDefendants}
			completeMethod={search}
			onChange={(e) => setSelectedDefendants(e.value)}
			onKeyPress={handleKeyPress} // Listen for the Enter key
			field="PartyName"
			multiple
			className="w-full"
			key={autoCompleteKey}
		/>
	);
}

function SettledSubstage({ localSubstage, handleSelectChange }) {

	// Ok so I need to make this show different inputs depending on the substage
	// For Settled In Principal should have a deadline to execute agreement and a Trello card created 10 days before the deadline
	// For the Payment Pending we should have the ability to enter one or more deadlines and Trello cards created 5 days before each deadline.

	const [deadlineFormVisible, setDeadlineFormVisible] = useState(false);

	return (
		<>
			<TrelloTaskForm substage={localSubstage} visible={deadlineFormVisible} setVisible={setDeadlineFormVisible} />

			<li
				key="Substage"
				className="flex align-items-center py-0 px-2 border-top-1 surface-border flex-wrap"
			>
				<div className="text-500 w-6 md:w-6 py-3 font-medium">
					Substage
				</div>
				<div className="text-900 w-full md:w-6 md:flex-order-0 flex-order-1">
					<CustomDropdown
						value={localSubstage}
						options={Object.entries(settledSubstages).map(([key, value]) => ({ label: value, value: value }))}
						onChange={(e) => handleSelectChange(e.label, e.value, 'Substage')}
					/>
				</div>
			</li>

			{/* {localSubstage === 'Settled in Principal' && (
				<li
					key="Deadline"
					className="flex align-items-center py-0 px-2 border-top-1 surface-border flex-wrap"
				>
					<div className="text-500 w-6 md:w-6 py-3 font-medium">
						Deadlines
					</div>
					<div className="text-900 w-full md:w-6 md:flex-order-0 flex-order-1">
						<Button label="View/Edit Deadlines" size="small" onClick={() => setDeadlineFormVisible(true)} />
					</div>
				</li>
			)}

			{localSubstage === 'Agreement Executed & Payment(s) Pending' && (
				<li
					key="Deadline"
					className="flex align-items-center py-0 px-2 border-top-1 surface-border flex-wrap"
				>
					<div className="text-500 w-6 md:w-6 py-3 font-medium">
						Deadline
					</div>
					<div className="text-900 w-full md:w-6 md:flex-order-0 flex-order-1">
						<DatePicker />
					</div>
				</li>
			)} */}
		</>
	)
}

