import React, { useContext, useEffect, useRef, useState } from 'react';
import api from '../../../api';
import moment from 'moment'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Menu } from 'primereact/menu';
import { Tooltip } from 'primereact/tooltip';
import { Badge } from 'primereact/badge';
import AddBriefFiledForm from './AddBriefFiledForm';
import AddNoteForm from './AddNoteForm';
import SettlementTrackerForm from './SettlementTrackerForm';
import { useSelector } from 'react-redux';
import { AutoComplete } from 'primereact/autocomplete';
import { InputText } from 'primereact/inputtext';
import { Menubar } from 'primereact/menubar';
import { Calendar } from 'primereact/calendar';

function getActivityStyles(activityType, direction, clientName, amount) {
    const baseStyles = {
        TextMessage: {
            inbound: {
                icon: 'pi-comments',
                borderColor: 'border-orange-200',
                textColor: 'text-orange-700'
            },
            outbound: {
                icon: 'pi-comments',
                borderColor: 'border-teal-200',
                textColor: 'text-teal-700'
            }
        },
        "PhoneCall - Answered": {
            inbound: {
                icon: 'pi-phone',
                borderColor: 'border-orange-200',
                textColor: 'text-orange-700'
            },
            outbound: {
                icon: 'pi-phone',
                borderColor: 'border-teal-200',
                textColor: 'text-teal-700'
            }
        },
        "PhoneCall - Not Answered": {
            inbound: {
                icon: 'pi-phone',
                borderColor: 'border-red-200',
                textColor: 'text-red-700'
            },
            outbound: {
                icon: 'pi-phone',
                borderColor: 'border-red-200',
                textColor: 'text-red-700'
            }
        },
        Email: {
            inbound: {
                icon: 'pi-envelope',
                borderColor: 'border-orange-200',
                textColor: 'text-orange-700'
            },
            outbound: {
                icon: 'pi-envelope',
                borderColor: 'border-teal-200',
                textColor: 'text-teal-700'
            }
        },
        FileUpload: {
            inbound: {
                icon: 'pi-file',
                borderColor: 'border-blue-200',
                textColor: 'text-blue-700'
            }
        },
        Settlement: {
            icon: 'pi-dollar',
            borderColor: 'border-green-200',
            textColor: 'text-green-700'
        },
        Note: {
            icon: 'pi-pencil',
            borderColor: 'border-yellow-200',
            textColor: 'text-yellow-700'
        },
        BriefFiled: {
            icon: 'pi-briefcase',
            borderColor: 'border-indigo-200',
            textColor: 'text-indigo-700'
        },
        StageChanged: {
            icon: 'pi-arrows-v',
            borderColor: 'border-purple-200',
            textColor: 'text-purple-700'
        },
        SubstageChanged: {
            icon: 'pi-arrows-v',
            borderColor: 'border-purple-200',
            textColor: 'text-purple-700'
        },
        RuleMatched: {
            icon: 'pi-exclamation-triangle',
            borderColor: 'border-red-200',
            textColor: 'text-red-700'
        }
    }

    let styles = direction ? baseStyles[activityType][direction] : baseStyles[activityType]
    if (!styles) {
        styles = {
            icon: 'pi-question',
            borderColor: 'border-bluegray-200',
            textColor: 'text-bluegray-700'
        }
    }
    let label = ''
    if (activityType === 'Settlement') {

        const formattedAmount = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(amount);

        label = `made a settlement offer for ${formattedAmount}`
    } else if (activityType === 'Note') {
        label = `added a note`
    } else if (activityType === 'FileUpload') {
        label = `uploaded a file`
    } else if (activityType === 'PhoneCall - Answered' || activityType === 'PhoneCall - Not Answered') {
        label = `${direction === 'inbound' ? 'received' : 'made'} a call ${direction === 'inbound' ? 'from' : 'to'} ${clientName}`
    } else if (activityType === 'TextMessage') {
        label = `${direction === 'inbound' ? 'received' : 'sent'} a text message ${direction === 'inbound' ? 'from' : 'to'} ${clientName}`
    } else if (activityType === 'Email') {
        label = `${direction === 'inbound' ? 'received' : 'sent'} an email ${direction === 'inbound' ? 'from' : 'to'} ${clientName}`
    } else if (activityType === 'BriefFiled') {
        label = `filed a brief`
    }
    styles.label = label
    return styles
}



function Activity({ matterNumber }) {
    const matter = useSelector(state => state.caseDetails.matter);
    const [allActivities, setAllActivities] = useState([])
    const [activity, setActivity] = useState([])
    const [addNoteModalVisible, setAddNoteModalVisible] = useState(false)
    const [detailsModalVisible, setDetailsModalVisible] = useState(false)
    const [detailsForModal, setDetailsForModal] = useState('')


    const [noteForm, setNoteForm] = useState({
        visible: false,
        isEditing: false,
        currentNote: null,
        currentNoteId: null
    });

    const [settlementForm, setSettlementForm] = useState({
        visible: false,
        isEditing: false,
        currentSettlement: {
            date: new Date(),
        },
        currentSettlementId: null
    });

    const [briefFiledForm, setBriefFiledForm] = useState({
        visible: false,
        isEditing: false,
        currentBriefFiled: {
            date: new Date(),
        },
        currentBriefFiledId: null
    });

    const fetchActivities = () => {
        api.get(`/activity/${matterNumber}`)
            .then(response => {
                console.log(response.data)
                setActivity(response.data)
                setAllActivities(response.data)
            })
            .catch(error => {
                console.error('Error:', error);
            })
    }

    useEffect(() => {
        fetchActivities()
    }, [])

    const LinkExtractor = ({ linkString }) => {
        const fileMatch = linkString.match(/file\/d\/([a-zA-Z0-9_-]+)/)
        const folderMatch = linkString.match(/folders\/([a-zA-Z0-9_-]+)/)

        const fileID = fileMatch ? fileMatch[1] : null
        const folderID = folderMatch ? folderMatch[1] : null

        return (
            <div>
                {fileID && (
                    <a
                        href={`https://drive.google.com/file/d/${fileID}/view`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Open File
                    </a>
                )}
                {fileID && folderID && ' | '}
                {folderID && (
                    <a
                        href={`https://drive.google.com/drive/folders/${folderID}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Open Folder
                    </a>
                )}
            </div>
        )
    }

    function truncateText(text, length) {
        if (typeof text == 'string' && text.length > length) {
            return text.substring(0, length - 3) + '...'
        }
        return text
    }

    const openDetailsModal = (details) => {
        setDetailsForModal(details)
        setDetailsModalVisible(true)
    }

    const openAddNoteForm = () => {
        setNoteForm({
            visible: true,
            isEditing: false,
            currentNote: null
        });
    }

    const openAddNoteFormInEditMode = (note, id, date) => {
        console.log(id);
        setNoteForm({
            visible: true,
            isEditing: true,
            currentNote: note,
            currentNoteId: id,
            date: date
        });
    }

    const openSettlementForm = () => {
        setSettlementForm({
            visible: true,
            isEditing: false,
            currentSettlement: {
                date: new Date(),
            }
        });
    }

    const openSettlementFormInEditMode = (settlement, id) => {
        console.log(id);
        setSettlementForm({
            visible: true,
            isEditing: true,
            currentSettlement: settlement,
            currentSettlementId: id
        });
    }

    const openBriefFiledForm = () => {
        setBriefFiledForm({
            visible: true,
            isEditing: false,
            currentBriefFiled: {
                date: new Date(),
            }
        });
    }

    const openBriefFiledFormInEditMode = (briefFiled, id) => {
        console.log(id);
        setBriefFiledForm({
            visible: true,
            isEditing: true,
            currentBriefFiled: briefFiled,
            currentBriefFiledId: id
        });
    }

    return (
        <>
            <div>
                <div class="flex align-items-center justify-content-center text-red-500 pt-3">
                    <ActivitiesMenu setActivity={setActivity} allActivities={allActivities} openSettlementForm={openSettlementForm} openAddNoteForm={openAddNoteForm} openBriefFiledForm={openBriefFiledForm} />
                </div>
                <SettlementTrackerForm settlementForm={settlementForm} setSettlementForm={setSettlementForm} matterNumber={matterNumber} fetchActivities={fetchActivities}
                    partyMakingOfferOptions={[...matter.defendants.map(d => d.PartyName), ...matter.clients.map(c => c.ClientName)]} />
                <AddNoteForm noteForm={noteForm} setNoteForm={setNoteForm} matterNumber={matterNumber} fetchActivities={fetchActivities} />
                <AddBriefFiledForm briefFiledForm={briefFiledForm} setBriefFiledForm={setBriefFiledForm} matterNumber={matterNumber} fetchActivities={fetchActivities} />
            </div>
            <ul className="list-none p-3 m-0">
                {(activity && activity.length > 0) ? activity.map((act, index) => {

                    let clientName = act.ClientName || act.ClientContact || 'Client'
                    let attorneyName = act.AttorneyName || act.attorneyContact || matter.attorneys[0].Name || 'Attorney'

                    const styles = getActivityStyles(act.ActivityType, act.Direction, clientName, act.Amount) || {}
                    const { icon, borderColor, textColor, label } = styles

                    let details = act.Details
                    let name = act.AttorneyName || act.PartyMakingOffer || act.AttorneyContact || matter.attorneys[0].Name || 'Attorney'
                    let attorneyTooltipText = null
                    let clientTooltipText = act.ClientContact
                    if (!act.PartyMakingOffer) {
                        attorneyTooltipText = act.AttorneyContact
                    }
                    if (act.ActivityType === 'FileUpload') {
                        details = <LinkExtractor linkString={act.Details} />
                        name = act.ClientName
                    } else if (act.ActivityType === 'Note') {
                        details = (
                            <a className="text-primary cursor-pointer" onClick={() => openAddNoteFormInEditMode(act.Details, act.id, act.Date)}>
                                <span>{truncateText(act.Details, 150)}</span>
                            </a>
                        )
                    } else if (act.ActivityType === 'Settlement') {
                        details = (
                            <div
                                className="text-primary cursor-pointer"
                                onClick={() => openSettlementFormInEditMode(act, act.id)}
                            >
                                <span>{truncateText(act.Details, 150) || <em>Click to edit</em>}</span>
                            </div>
                        )
                    } else if (act.ActivityType === 'Email') {
                        let modalDetails = (
                            <div className='w-full'>
                                <div className="text-700 text-lg font-bold mb-3">{act.Subject}</div>
                                <div className="text-500 mb-2 w-full" style={{ whiteSpace: 'pre-line' }}>{act.Details}</div>
                                {act.HasAttachments > 0 && (
                                    <Badge value="Has Attachments" severity="secondary" className="mt-3" />
                                )}
                            </div>
                        )
                        details = (
                            <a className="text-primary cursor-pointer" onClick={() => openDetailsModal(modalDetails)}>
                                <div>{truncateText(act.Subject, 150)}</div>
                                <div className='text-sm'>{truncateText(act.Details, 150)}</div>
                            </a>
                        )
                    } else if (act.ActivityType === 'BriefFiled') {
                        details = (
                            <div
                                className="text-primary cursor-pointer"
                                onClick={() => openBriefFiledFormInEditMode(act, act.id)}
                            >
                                {/* <span>{truncateText(act.Details, 150) || <em>Click to edit</em>}</span> */}
                                <div>{act.Location}</div>
                                <div>{
                                    act.Link
                                }</div>
                                <div>{
                                    act.Tags && act.Tags.length > 0 && act.Tags.map(tag => (
                                        <Badge value={tag} severity="secondary" className="mt-2 mr-2" />
                                    ))
                                }</div>
                            </div>
                        )
                    } else {
                        details = (
                            <a className="text-primary cursor-pointer" onClick={() => openDetailsModal(act.Details)}>
                                <span>{truncateText(act.Details, 150)}</span>
                            </a>
                        )
                    }

                    return (
                        <li key={index} className="py-3 border-bottom-1 surface-border">
                            <div class="flex overflow-hidden">
                                <div class="flex-none flex align-items-center justify-content-center font-bold m-2 py-0 border-round">
                                    <span className={`inline-flex justify-content-center align-items-center mr-3 h-3rem w-3rem border-circle border-1 ${borderColor}`}>
                                        <i className={`pi ${icon} ${textColor}`}></i>
                                    </span>
                                </div>
                                <div class="flex-grow-1 flex align-items-center justify-content-left m-2 py-0 border-round">
                                    <Tooltip target={`.tooltip-target-${index}`} />
                                    <div>
                                        <span
                                            className={`text-900 w-max font-medium block mb-2 pr-2 tooltip-target-${index}`}
                                            data-pr-tooltip={attorneyTooltipText}
                                            data-pr-position="right"
                                        // data-pr-at="right+5 top"
                                        // data-pr-my="left center-2"
                                        >{name}</span>
                                        <Tooltip target={`.tooltip-target-${index}-label`} />
                                        <div
                                            className={`text-700 mb-2 w-max pr-2 tooltip-target-${index}-label`}
                                            data-pr-tooltip={clientTooltipText}
                                            data-pr-position="right"
                                        >{label}</div>
                                        {details}
                                    </div>
                                </div>
                                <div class="flex-none flex align-items-center justify-content-center font-bold m-2 py-0 border-round">
                                    <div>
                                        <p className="block text-500 font-medium ml-7 md:ml-5 mt-2 md:mt-0">{moment(act.Date).fromNow()}</p>
                                        <p className="block text-500 font-medium ml-7 md:ml-5 mt-2 md:mt-0">{
                                            (new Date(act.Date)).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric'
                                            })
                                        }</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                    )
                }) : (
                    <div className="flex align-items-center justify-content-center py-3">
                        <span className="text-500">No activity found</span>
                    </div>
                )}
            </ul>
            <DetailsModal details={detailsForModal} detailsModalVisible={detailsModalVisible} setDetailsModalVisible={setDetailsModalVisible} />
        </>
    )
}

export default Activity;

function DetailsModal({ details, detailsModalVisible, setDetailsModalVisible }) {
    return (
        <Dialog header="Details" visible={detailsModalVisible} style={{ width: '50vw' }} onHide={() => setDetailsModalVisible(false)}>
            <p className="mb-5">
                {details}
            </p>
        </Dialog>
    )
}

function ActivitiesMenu({ setActivity, allActivities, openSettlementForm, openAddNoteForm, openBriefFiledForm }) {
    const filterMenu = useRef(null);
    const [filterItem, setFilterItem] = useState({ label: 'All', value: null })
    const [keyword, setKeyword] = useState('')
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [tags, setTags] = useState([])
    const filterItems = [
        {
            items: [
                {
                    label: 'Text Messages',
                    command: () => {
                        setFilterItem({ label: 'Text Messages', value: 'TextMessage' });
                    }
                },
                {
                    label: 'Phone Calls',
                    command: () => {
                        setFilterItem({ label: 'Phone Calls', value: 'PhoneCall' });
                    }
                },
                {
                    label: 'Emails',
                    command: () => {
                        setFilterItem({ label: 'Emails', value: 'Email' });
                    }
                },
                {
                    label: 'Notes',
                    command: () => {
                        setFilterItem({ label: 'Notes', value: 'Note' });
                    }
                },
                {
                    label: 'Settlements',
                    command: () => {
                        setFilterItem({ label: 'Settlements', value: 'Settlement' });
                    }
                },
                {
                    label: 'File Uploads',
                    command: () => {
                        setFilterItem({ label: 'File Uploads', value: 'FileUpload' });
                    }
                },
                {
                    label: 'Brief Filed',
                    command: () => {
                        setFilterItem({ label: 'Brief Filed', value: 'BriefFiled' });
                    }
                }
            ]
        }
    ];

    const items = [
        {
            label: 'Add Activity',
            icon: 'pi pi-fw pi-plus',
            items: [
                {
                    label: 'Settlement Event',
                    command: () => {
                        openSettlementForm()
                    }
                },
                {
                    label: 'Brief Filed',
                    command: () => {
                        openBriefFiledForm()
                    }
                },
                {
                    label: 'Note',
                    command: () => {
                        openAddNoteForm()
                    }
                }
            ]
        },
        {
            label: filterItem.label,
            icon: 'pi pi-fw pi-filter',
            items: [
                {
                    label: 'All',
                    command: () => {
                        setFilterItem({ label: 'All', value: null });
                    }
                },
                {
                    label: 'Activity Type',
                    items: filterItems[0].items
                },
                {
                    label: 'Tag',
                    items: tags
                }
            ]
        }
    ];

    useEffect(() => {
        if (allActivities && allActivities.length > 0) {
            const tags = allActivities.reduce((acc, curr) => {
                if (curr.Tags && curr.Tags.length > 0) {
                    curr.Tags.forEach(tag => {
                        if (!acc.includes(tag)) {
                            acc.push(tag)
                        }
                    })
                }
                return acc
            }, [])
            setTags(tags.map(tag => {
                return {
                    label: tag,
                    command: () => {
                        setFilterItem({ label: tag, value: tag });
                    }
                }
            }))
        }
    }, [allActivities])

    useEffect(() => {
        if (startDate && endDate) {
            setActivity(allActivities.filter(a => {
                return moment(a.Date).isBetween(startDate, endDate)
            }))
        }
    }, [startDate, endDate])

    const resetFilters = () => {
        setFilterItem({ label: 'All', value: null })
        setKeyword('')
        setStartDate(null)
        setEndDate(null)
    }

    const end = () => {
        return (
            <>
                <Calendar className="ml-2" value={startDate} onChange={(e) => setStartDate(e.value)} placeholder='Start Date' />
                <Calendar className="ml-2" value={endDate} onChange={(e) => setEndDate(e.value)} placeholder='End Date' />
                <InputText placeholder="Search" type="text" className="ml-2" value={keyword} onChange={(e) => setKeyword(e.target.value)} />
                <Button label="Clear" icon="pi pi-filter-slash" className="p-button-secondary ml-2" outlined onClick={() => resetFilters()} />
            </>
        )
    }

    useEffect(() => {
        if (filterItem.value) {
            let acc = allActivities.filter(a => filterItem.value.includes(a.ActivityType))
            if (acc.length > 0) {
                setActivity(acc);
            } else {
                acc = allActivities.filter(a => {
                    if (a.Tags && a.Tags.length > 0) {
                        return a.Tags.includes(filterItem.value)
                    }
                    return false
                })
                setActivity(acc);
            }

        } else {
            setActivity(allActivities);
        }
    }, [filterItem])

    useEffect(() => {
        if (keyword) {
            setActivity(allActivities.filter(a => {
                return (a.ClientName && a.ClientName.toLowerCase().includes(keyword.toLowerCase())) ||
                    (a.AttorneyName && a.AttorneyName.toLowerCase().includes(keyword.toLowerCase())) ||
                    (a.Subject && a.Subject.toLowerCase().includes(keyword.toLowerCase())) ||
                    (a.Details && a.Details.toLowerCase().includes(keyword.toLowerCase())) ||
                    // tags
                    (a.Tags && a.Tags.length > 0 && a.Tags.filter(t => t.toLowerCase().includes(keyword.toLowerCase())).length > 0)
            }))
        } else {
            setActivity(allActivities);
        }
    }, [keyword])

    return (
        <>
            <Menubar model={items} end={end} />

            {/* <Menu model={filterItems} popup ref={filterMenu} id="popup_menu_left" />
            <Button label={filterItem.label} icon="pi pi-filter" severity='secondary' rounded outlined onClick={(event) => filterMenu.current.toggle(event)} aria-controls="popup_menu_left" aria-haspopup /> */}
        </>
    )
}