import React, { useState, useEffect, useContext, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Tag } from 'primereact/tag'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import ToastContext from '../../ToastContext'
import { InputText } from 'primereact/inputtext'
import axios from 'axios'
import Billing from './Billing'
import Invoices from './Invoices'
import Clients from './Clients'
import CaseInfo from './CaseInfo'
import Activity from './Activity/Activity'
import api from '../../api'
import styled from 'styled-components'
import { TabPanel, TabView } from 'primereact/tabview'
import { Button } from 'primereact/button'
import Attributes from './Attributes'
import QueuedTasks from './QueuedTasks'
import TrelloTaskForm from './TrelloTaskForm'
import {
	setMatter
} from '../../store/CaseDetails/action'
import ResolvedAttributeForm from './ResolvedAttributeForm'
import { Menu } from 'primereact/menu'
import { Badge } from 'primereact/badge'
import NotFoundPage from '../NotFoundPage'
import DemandSentAttributeForm from './DemandSentAttributeForm'

const CustomInputText = styled(InputText)`
	background: #0000 !important
    padding-top: 0.5rem !important
    padding-bottom: 0.5rem !important
    &:focus {
        outline: none !important
        box-shadow: none !important
    }
`

function usePrevious(value) {
	const ref = useRef()
	useEffect(() => {
		ref.current = value
	})
	return ref.current
}

function CaseDetails() {
	const dispatch = useDispatch()
	let { MatterNumber } = useParams()
	const [matterNumber, setMatterNumber] = useState(MatterNumber)
	const matter = useSelector(state => state.caseDetails.matter)
	const [clients, setClients] = useState([])
	const [attorneys, setAttorneys] = useState(null)
	const [invoices, setInvoices] = useState([])
	const [journals, setJournals] = useState([])
	const [isEditing, setIsEditing] = useState(false)
	const inputRef = useRef(null)
	const [tabIndex, setTabIndex] = useState(0)
	const [trelloTaskFormVisible, setTrelloTaskFormVisible] = useState(false)
	const [resolvedAttributeFormVisible, setResolvedAttributeFormVisible] = useState(false)
	const [demandSentAttributeFormVisible, setDemandSentAttributeFormVisible] = useState(false)
	const prevSubstage = usePrevious(matter?.Substage)
	const prevStage = usePrevious(matter?.Stage)
	const prevMatterNumber = usePrevious(MatterNumber)
	const showToast = useContext(ToastContext)
	const matterMenu = useRef(null)
	const [matterMenuItems, setMatterMenuItems] = useState([])
	const [error, setError] = useState(false)

	useEffect(() => {
		const handleBeforeUnload = (event) => {
		  // Your code here, for example:
		  console.log('Leaving the page');
		  // Optionally, you can show a confirmation dialog
		//   event.preventDefault();
		//   event.returnValue = '';
		};
	
		window.addEventListener('beforeunload', handleBeforeUnload);
	
		// Cleanup the event listener when the component unmounts
		return () => {
		  window.removeEventListener('beforeunload', handleBeforeUnload);
		  dispatch(setMatter(null))
		};
	  }, []);

	const fetchMatter = async () => {
		try {
			const response = await api.get(`/case_details/${MatterNumber}`);
			const data = response.data;
			let m = data.matter;
			m.attorneys = data.attorneys;
			m.defendants = data.defendants;
			m.clients = data.clients;
			dispatch(setMatter(m));
			console.log("Matter:", data.matter);

			setClients(data.clients);
			console.log("Clients:", data.clients);

			setAttorneys(data.attorneys);
			console.log("Attorneys:", data.attorneys);

			setInvoices(data.invoices);
			console.log("Invoices:", data.invoices);

			setJournals(data.journals);
			console.log("Journals:", data.journals);
		} catch (error) {
			// Check if the error is due to an expired token and is being handled
			if (error.response && error.response.status === 401 && error.config._retry) {
				console.log('Waiting for token refresh...');
				// You might want to implement a delay or a mechanism to wait for the token refresh to complete
			} else {
				// Handle other errors
				setError(true);
			}
		}
	};


	useEffect(() => {
		fetchMatter()
		document.title = MatterNumber
	}, [])

	useEffect(() => {
		if (clients.length === 0) {
			setClients([{
				ClientName: "",
				ClientPhone: "",
				ClientEmail: "",
				AdditionalPhone: "",
				AdditionalEmails: "",
				Notes: "",
				PrimaryClient: false,
				IsNew: true
			}])
		}
		console.log("Clients:", clients)
	}, [clients])

	useEffect(() => {
		if (isEditing && inputRef.current) {
			inputRef.current.focus()
		}

		const handleClickOutside = (event) => {
			if (isEditing && inputRef.current && !inputRef.current.contains(event.target)) {
				setIsEditing(false)
			}
		}
		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [isEditing])

	useEffect(() => {
		if (
			matter?.Substage !== prevSubstage &&
			prevSubstage &&
			(!prevMatterNumber || MatterNumber === prevMatterNumber)
		) {
			if (
				(matter?.Substage === 'Agreement Executed & Payment(s) Pending' ||
					matter?.Substage === 'Settled in Principal')
			) {
				setTabIndex(4)
				setTrelloTaskFormVisible(true)
			} else if (
				matter?.Substage === 'Payments Complete/Fully Resolved'
			) {
				setTabIndex(4)
				setResolvedAttributeFormVisible(true)
				showToast({ severity: 'info', summary: 'Resolved', detail: 'Be sure to delete any queued Trello Tasks' })
			}
		}
	}, [matter?.Substage])

	useEffect(() => {
		if (
			matter?.Stage !== prevStage &&
			prevStage &&
			(!prevMatterNumber || MatterNumber === prevMatterNumber)
		) {
			if (
				matter?.Stage === 'Withdrawn'
			) {
				setTabIndex(4)
				showToast({ severity: 'info', summary: 'Withdrawn', detail: 'Be sure to delete any queued Trello Tasks' })
			} else if (
				matter?.Stage === 'Demand Sent'
			) {
				setTabIndex(3)
				setDemandSentAttributeFormVisible(true)
			}
		}
	}, [matter?.Stage])

	const handleChange = (e) => {
		setMatterNumber(e.target?.value)
	}

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			// Invoke your submit function here
			submitMatterNumber()
		}
	}

	const submitMatterNumber = async () => {
		try {
			const response = await api.post('/api/matter_number', {
				MatterNumber: MatterNumber,
				NewMatterNumber: matterNumber
			})
			if (response.status === 201) {
				console.log('Matter number updated successfully')
				setMatterNumber(matterNumber)  // Updating the local state to reflect the new matter number
				setIsEditing(false)
			} else {
				console.error('Error:', response.data.message)
			}
		} catch (e) {
			console.error('Error:', e)
		}
	}


	const deleteMatter = () => {
		api.delete(`/matter/${MatterNumber}`)
			.then(response => {
				if (response.status === 200) {
					showToast({ severity: 'success', summary: 'Success', detail: 'Matter Deleted' });
					fetchMatter();
				} else {
					showToast({ severity: 'error', summary: 'Error', detail: 'Matter Deletion Failed' });
				}
			})
			.catch(e => {
				console.error('Error:', e);
			});
	};

	const undeleteMatter = () => {
		api.post(`/undelete-matter/${MatterNumber}`)
			.then(response => {
				if (response.status === 200) {
					showToast({ severity: 'success', summary: 'Success', detail: 'Matter Undeleted' });
					fetchMatter();
				} else {
					showToast({ severity: 'error', summary: 'Error', detail: 'Matter Undeletion Failed' });
				}
			})
			.catch(e => {
				console.error('Error:', e);
			});
	};

	useEffect(() => {
		if (matter?.Hidden) {
			setMatterMenuItems([
				{
					label: 'Undelete Matter',
					icon: 'pi pi-circle',
					command: () => {
						if (window.confirm('Are you sure you want to undelete this matter?')) {
							undeleteMatter()
						}
					}
				}
			])
		} else {
			setMatterMenuItems([
				{
					label: 'Delete Matter',
					icon: 'pi pi-times',
					command: () => {
						if (window.confirm('Are you sure you want to delete this matter?')) {
							deleteMatter()
						}
					}
				}
			])
		}
	}, [matter])

	if (error) return <NotFoundPage />

	if (matter) return (
		<div>
			<TrelloTaskForm substage={matter.Substage} visible={trelloTaskFormVisible} setVisible={setTrelloTaskFormVisible} currentlyEditing={null} defaultType="DueDate" />
			<ResolvedAttributeForm visible={resolvedAttributeFormVisible} setVisible={setResolvedAttributeFormVisible} attributes={matter?.attributes} fetchAttributes={fetchMatter} />
			<DemandSentAttributeForm visible={demandSentAttributeFormVisible} setVisible={setDemandSentAttributeFormVisible} attributes={matter?.attributes} fetchAttributes={fetchMatter} />
			<div className="surface-ground px-4 py-5">
				<div className="border-bottom-1 surface-border">
					{isEditing ? (
						<CustomInputText
							ref={inputRef}
							className='text-3xl font-medium text-900 mb-3 w-6'
							value={matterNumber || ''}
							onChange={(e) => handleChange(e)}
							onKeyDown={handleKeyDown}
						/>
					) : (
						<>
							<div class="flex justify-content-start flex-wrap">
								<span
									className="block text-3xl font-medium text-900 mb-4 w-max py-2"
								// onClick={() => setIsEditing(true)}
								>{MatterNumber}</span>

								<Menu model={matterMenuItems} popup ref={matterMenu} id="popup_menu_left" />
								<div className='py-1 ml-2'>
									<Button icon="pi pi-ellipsis-v" text severity="secondary" onClick={(event) => matterMenu.current.toggle(event)} aria-controls="popup_menu_left" aria-haspopup />
								</div>

								{matter?.Hidden && (
									<div className='pt-2 ml-2'>
										<Badge severity='danger' size="large" value='Deleted' />
									</div>
								)}
							</div>

						</>
					)}
				</div>
			</div>
			<div className='px-4'>
				<div className='grid'>
					<Clients clients={clients} setClients={setClients} matterNumber={MatterNumber} fetchMatter={fetchMatter} />
					<CaseInfo fetchMatter={fetchMatter} />
					<div className="col-12 big-box mb-6">
						<div className="surface-card shadow-2 border-round p-0 h-full overflow-hidden">

							<TabView className=''
								activeIndex={tabIndex}
								onTabChange={(e) => setTabIndex(e.index)}
							>
								<TabPanel header="Clockify">
									<div className='relative overflow-y-auto' style={{ height: '50rem' }}>
										<div className='h-full overflow-y-scroll'>
											<Billing />
										</div>
									</div>
								</TabPanel>
								<TabPanel header="Xero">
									<div className='relative overflow-y-auto' style={{ height: '50rem' }}>
										<div className='h-full overflow-y-scroll'>
											<Invoices
												invoices={invoices}
												journals={journals}
											/>
										</div>
									</div>
								</TabPanel>
								<TabPanel header="Activity">
									<div className='relative overflow-y-auto' style={{ height: '50rem' }}>
										<div className='h-full overflow-y-scroll'>
											<Activity matterNumber={MatterNumber} matter={matter} />
										</div>
									</div>
								</TabPanel>
								<TabPanel header="Attributes" >
									<div className='relative overflow-y-auto' style={{ height: '50rem' }}>
										<div className='h-full overflow-y-scroll'>
											<Attributes matterNumber={MatterNumber} />
										</div>
									</div>
								</TabPanel>
								<TabPanel header="Trello Tasks">
									<div className='relative overflow-y-auto' style={{ height: '50rem' }}>
										<div className='h-full overflow-y-scroll'>
											<QueuedTasks matterNumber={MatterNumber} />
										</div>
									</div>
								</TabPanel>
							</TabView>

						</div>
					</div>
				</div>
			</div>
		</div>
	)

	return null
}

export default CaseDetails

