import React, { useContext, useEffect, useState } from 'react';
import api from '../../../api';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import DatePicker from 'react-date-picker'
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import ToastContext from '../../../ToastContext';
import { AutoComplete } from 'primereact/autocomplete';
import { Tooltip } from 'primereact/tooltip';
import { Dropdown } from 'primereact/dropdown';

function AddBriefFiledForm({ briefFiledForm, setBriefFiledForm, matterNumber, fetchActivities }) {
    const showToast = useContext(ToastContext);
    const [allTags, setAllTags] = useState([]);

    const [formContent, setFormContent] = useState({
        date: new Date(),
        location: '',
        link: '',
        tags: []
    });

    useEffect(() => {
        api.get('tags/BriefFiled')
            .then(response => {
                console.log(response.data);
                setAllTags(response.data.tags);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        if (briefFiledForm.isEditing && briefFiledForm.currentBriefFiled) {
            console.log(briefFiledForm.currentBriefFiled);
            setFormContent({
                date: new Date(briefFiledForm.currentBriefFiled.Date),
                location: briefFiledForm.currentBriefFiled.Location,
                link: briefFiledForm.currentBriefFiled.Link,
                tags: briefFiledForm.currentBriefFiled.Tags
            });
        } else {
            setFormContent({
                date: new Date(),
                location: '',
                link: '',
                tags: []
            });
        }
    }, [briefFiledForm]);

    const submit = () => {
        const payload = {
            ActivityType: 'BriefFiled',
            Date: formContent.date,
            Location: formContent.location,
            Link: formContent.link,
            Tags: formContent.tags,
            MatterNumber: matterNumber,
            id: briefFiledForm.currentBriefFiled.id
        };

        console.log('payload:', payload);

        const apiMethod = briefFiledForm.isEditing ? api.put : api.post;
        const endpoint = briefFiledForm.isEditing ? `/activity/${briefFiledForm.currentBriefFiledId}` : `/activity`;

        apiMethod(endpoint, payload)
            .then(response => {
                fetchActivities();
                showToast({
                    severity: 'success',
                    summary: 'Successful',
                    detail: briefFiledForm.isEditing ? 'Brief Filed updated' : 'Brief Filed info added to activity log'
                });
                setBriefFiledForm({
                    ...briefFiledForm,
                    visible: false,
                    isEditing: false,
                    currentBriefFiled: null
                });
            })
            .catch(error => {
                console.error('Error:', error);
                showToast({ severity: 'error', summary: 'Error', detail: error.message });
            });
    }

    const deleteBriefFiled = () => {
        api.delete('/activity', { data: { id: briefFiledForm.currentBriefFiledId } })
            .then(response => {
                if (response.status === 201) {  // Added status check
                    fetchActivities();
                    showToast({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Brief Filed deleted'
                    });
                    setBriefFiledForm({
                        ...briefFiledForm,
                        visible: false,
                        isEditing: false,
                        currentBriefFiled: null
                    });
                } else {
                    showToast({ severity: 'error', summary: 'Error', detail: 'Unexpected status code' });
                }
            })
            .catch(error => {
                console.error('Error:', error);
                showToast({ severity: 'error', summary: 'Error', detail: error.message });
            });
    }

    const footerContent = (
        <div>
            {briefFiledForm.isEditing && <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={deleteBriefFiled} />}
            <Button label={briefFiledForm.isEditing ? 'Update' : 'Submit'} icon="pi pi-check" onClick={submit} autoFocus />
        </div>
    );

    return (
        <>
            <Dialog
                header={briefFiledForm.isEditing ? 'Edit Brief Filed' : 'Brief Filed'}
                visible={briefFiledForm.visible}
                style={{ width: '50vw' }}
                onHide={() => setBriefFiledForm(prev => ({ ...prev, visible: false }))}
                footer={footerContent}
            >
                <div className='grid formgrid p-fluid'>
                    <div className="field mb-4 col-12">
                        <div className="py-0">
                            <label htmlFor="date" className="font-medium text-900">Date</label>
                        </div>
                        <div className="py-2">
                            <DatePicker onChange={(date) => setFormContent(prev => ({ ...prev, date: date }))} value={formContent.date} />
                        </div>
                    </div>
                    <div className="field mb-4 col-12">
                        <label htmlFor="location" className="font-medium text-900">Location</label>
                        {/* TODO: needs to be a dropdown */}
                        {/* <InputText
                            id="location"
                            value={formContent.location}
                            onChange={(e) => setFormContent(prev => ({ ...prev, location: e.target?.value }))}
                        /> */}
                        <Dropdown
                            id="location"
                            value={formContent.location}
                            options={[
                                {name: "Federal Court"},
                                {name: "State Court"},
                                {name: "Arbitration"},
                            ]}
                            onChange={(e) => setFormContent(prev => ({ ...prev, location: e.value }))}
                            optionLabel="name" optionValue="name" placeholder="Select a Location"
                        />
                    </div>
                    <div className="field mb-4 col-12">
                        <label htmlFor="link" className="font-medium text-900">Link</label>
                        <Tooltip target=".link-tooltip" position="right" />
                        <span><i
                            className="ml-2 pi pi-question-circle link-tooltip"
                            data-pr-tooltip="Add multiple links by putting each one on a new line"
                            style={{ fontSize: '1rem' }}></i></span>
                        <InputTextarea
                            id="link"
                            value={formContent.link}
                            rows={2}
                            autoResize
                            onChange={(e) => setFormContent(prev => ({ ...prev, link: e.target?.value }))}
                        />
                    </div>
                    <div className="field mb-4 col-12">
                        <label htmlFor="tags" className="font-medium text-900">Tags</label>
                        <BriefTagSelect
                            selectedTags={formContent.tags}
                            setSelectedTags={(tags) => setFormContent(prev => ({ ...prev, tags: tags }))}
                            allTags={allTags}
                        />
                    </div>
                </div>
            </Dialog>
        </>
    )
}

function BriefTagSelect({ selectedTags, setSelectedTags, allTags }) {
    const [filteredTags, setFilteredTags] = useState([]);
    const [query, setQuery] = useState('');
    const [autoCompleteKey, setAutoCompleteKey] = useState(0);

    const search = (event) => {
        setQuery(event.query);
        if (allTags.length === 0) {
            setFilteredTags([]);
            return;
        }
        setTimeout(() => {
            let _filteredTags;
            if (!query.trim().length) {
                _filteredTags = [...allTags];
            } else {
                _filteredTags = allTags.filter((tag) => {
                    return tag.toLowerCase().startsWith(query.toLowerCase());
                });
            }
            setFilteredTags(_filteredTags);
        })
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && query.length > 0) {
            // const newItem = {
            //     TagType: 'BriefFiled',
            //     Name: query
            // }
            setSelectedTags([...selectedTags, query]);
            setQuery('');
            setAutoCompleteKey(autoCompleteKey + 1);
        }
    }

    useEffect(() => {
        console.log('allTags:', allTags)
    }, [allTags])

    useEffect(() => {
        console.log('selectedTags:', selectedTags)
    }, [selectedTags])

    useEffect(() => {
        console.log('filteredTags:', filteredTags)
    }, [filteredTags])

    useEffect(() => {
        console.log('query:', query)
    }, [query])

    return (
        <AutoComplete
            value={selectedTags}
            suggestions={filteredTags}
            completeMethod={search}
            // field="Name"
            multiple
            onChange={(e) => setSelectedTags(e.value)}
            onKeyPress={handleKeyPress}
            className="w-full"
            dropdown
            placeholder="Add tags"
            key={autoCompleteKey}
        />
    )
}

export default AddBriefFiledForm;