import React, { useContext, useEffect, useRef, useState } from 'react';
import api from '../../../api';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import DatePicker from 'react-date-picker'
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import ToastContext from '../../../ToastContext';
import { Dropdown } from 'primereact/dropdown';

function SettlementTrackerForm({ settlementForm, setSettlementForm, matterNumber, fetchActivities, partyMakingOfferOptions }) {
    const showToast = useContext(ToastContext);

    const [formContent, setFormContent] = useState({
        date: new Date(),
        partyMakingOffer: '',
        amount: null,
        comments: ''
    });

    useEffect(() => {
        if (settlementForm.isEditing && settlementForm.currentSettlement) {
            console.log(settlementForm.currentSettlement);
            setFormContent({
                date: new Date(settlementForm.currentSettlement.Date),
                partyMakingOffer: settlementForm.currentSettlement.PartyMakingOffer,
                amount: settlementForm.currentSettlement.Amount,
                comments: settlementForm.currentSettlement.Details
            });
        }
    }, [settlementForm]);

    const submit = () => {
        const payload = {
            ActivityType: 'Settlement',
            Date: formContent.date,
            Details: formContent.comments,
            Amount: formContent.amount,
            PartyMakingOffer: formContent.partyMakingOffer,
            MatterNumber: matterNumber,
            id: settlementForm.currentSettlement.id
        };

        const apiMethod = settlementForm.isEditing ? api.put : api.post;
        const endpoint = settlementForm.isEditing ? `/activity/${settlementForm.currentSettlementId}` : `/activity`;

        apiMethod(endpoint, payload)
            .then(response => {
                fetchActivities();
                showToast({
                    severity: 'success',
                    summary: 'Successful',
                    detail: settlementForm.isEditing ? 'Settlement updated' : 'Tracking info added to activity log'
                });
                setSettlementForm({
                    ...settlementForm,
                    visible: false,
                    isEditing: false,
                    currentSettlement: null
                });
            })
            .catch(error => {
                console.error('Error:', error);
                showToast({ severity: 'error', summary: 'Error', detail: error.message });
            });
    }

    const deleteSettlementOffer = () => {
        api.delete('/activity', { data: { id: settlementForm.currentSettlementId } })
            .then(response => {
                if (response.status === 201) {  // Added status check
                    fetchActivities();
                    showToast({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Settlement deleted'
                    });
                    setSettlementForm({
                        ...settlementForm,
                        visible: false,
                        isEditing: false,
                        currentSettlement: null
                    });
                } else {
                    showToast({ severity: 'error', summary: 'Error', detail: 'Unexpected status code' });
                }
            })
            .catch(error => {
                console.error('Error:', error);
                showToast({ severity: 'error', summary: 'Error', detail: error.message });
            });
    }

    const footerContent = (
        <div>
            {settlementForm.isEditing && <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={deleteSettlementOffer} />}
            <Button label={settlementForm.isEditing ? 'Update' : 'Submit'} icon="pi pi-check" onClick={submit} autoFocus />
        </div>
    );

    return (
        <>
            <Dialog
                header={settlementForm.isEditing ? 'Edit Settlement' : 'Track Settlement'}
                visible={settlementForm.visible}
                style={{ width: '50vw' }}
                onHide={() => setSettlementForm(prev => ({ ...prev, visible: false }))}
                footer={footerContent}
            >
                <div className='grid formgrid p-fluid'>
                    <div className="field mb-4 col-12">
                        <div className="py-0">
                            <label htmlFor="date" className="font-medium text-900">Date</label>
                        </div>
                        <div className="py-2">
                            <DatePicker onChange={(date) => setFormContent(prev => ({ ...prev, date: date }))} value={formContent.date} />
                        </div>
                    </div>
                    <div className="field mb-4 col-12">
                        <label htmlFor="partyMakingOffer" className="font-medium text-900">Party Making Offer</label>
                        {/* <InputText
                            id="partyMakingOffer"
                            value={formContent.partyMakingOffer}
                            onChange={(e) => setFormContent(prev => ({ ...prev, partyMakingOffer: e.target?.value }))}
                        /> */}
                        <Dropdown id="partyMakingOffer" value={formContent.partyMakingOffer} options={partyMakingOfferOptions} onChange={(e) => setFormContent(prev => ({ ...prev, partyMakingOffer: e.value }))} placeholder="Select a party" />
                    </div>
                    <div className="field mb-4 col-12">
                        <label htmlFor="amount" className="font-medium text-900">Amount</label>
                        <InputNumber
                            id="amount"
                            value={formContent.amount}
                            onChange={(e) => setFormContent(prev => ({ ...prev, amount: e.value }))}
                        />
                    </div>
                    <div className="field mb-4 col-12">
                        <label htmlFor="comments" className="font-medium text-900">Comments</label>
                        <InputTextarea
                            id="comments"
                            value={formContent.comments}
                            onChange={(e) => setFormContent(prev => ({ ...prev, comments: e.target?.value }))}
                        />
                    </div>
                </div>
            </Dialog>
        </>
    )

}

export default SettlementTrackerForm;