import React, { useRef, useContext } from 'react';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { Rating } from 'primereact/rating';
import { Knob } from 'primereact/knob';
import ToastContext from '../ToastContext';
import { Tag } from 'primereact/tag';

function Settings() {
    const showToast = useContext(ToastContext);

    const linkDatasource = (name) => {
        showToast({ severity: 'success', summary: 'Successful', detail: 'Authorized Datasource' });
    }

    return (
        <div>
            <div className="surface-ground px-4 py-5">
                <div className="border-bottom-1 surface-border">
                    <span className="block text-3xl font-medium text-900 mb-4">Settings</span>
                </div>
            </div>
            <div className='px-4'>
                <div className="surface-card p-4 shadow-2 border-round">
                    <Tag severity="danger" value="Not plugged in" className='mb-4 bg-red-100 text-red-500' rounded></Tag>
                    <div className="text-3xl font-medium text-900 mb-2">Link Datasources</div>
                    <ul className="list-none p-0 m-0">
                        <li className="border-bottom-1 surface-border py-3 flex flex-row md:align-items-center md:justify-content-between">
                            <div className="inline-flex align-items-center flex-1">
                                <span className="text-900 font-medium">Google Drive</span>
                            </div>
                            <div className="flex align-items-center">
                                <Button icon="pi pi-link" rounded outlined onClick={() => linkDatasource('google_drive')} />
                            </div>
                        </li>
                        <li className="border-bottom-1 surface-border py-3 flex flex-row md:align-items-center md:justify-content-between">
                            <div className="inline-flex align-items-center flex-1">
                                <span className="text-900 font-medium">Google Calendar</span>
                            </div>
                            <div className="flex align-items-center">
                                <Button icon="pi pi-link" rounded outlined onClick={() => linkDatasource('google_calendar')} />
                            </div>
                        </li>
                        <li className="border-bottom-1 surface-border py-3 flex flex-row md:align-items-center md:justify-content-between">
                            <div className="inline-flex align-items-center flex-1">
                                <span className="text-900 font-medium">Xero</span>
                            </div>
                            <div className="flex align-items-center">
                                <Button icon="pi pi-link" rounded outlined onClick={() => linkDatasource('xero')} />
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Settings