import {
    SET_LOADING
} from './action';

const initialState = {
    loading: false
}

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOADING:
            return { ...state, loading: action.payload };
        default:
            return state;
    }
}

export default appReducer;