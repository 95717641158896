import React, { useState, useEffect, useContext } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import ToastContext from '../../ToastContext'
import api from '../../api'
import { Button } from 'primereact/button'

function BillingTable({ matterNumber, billingData, setBillingData }) {
	const [dataTableData, setDataTableData] = useState([])
	const [resyncing, setResyncing] = useState(false)
	const showToast = useContext(ToastContext)

	useEffect(() => {
		if (billingData && billingData.clockify_user_totals && Object.keys(billingData.clockify_user_totals).length > 0) {

			// Prepare data for DataTable
			const dataTableData = Object.keys(billingData.clockify_user_totals).map(label => {
				return {
					name: label,
					rate: billingData.clockify_user_totals[label].rate,
					hours: billingData.clockify_user_totals[label].total_duration_hours.toFixed(2),
					bill: `$${billingData.clockify_user_totals[label].total_bill}`,
				}
			})

			setDataTableData(dataTableData) // You'll have to define this state variable
		}
	}, [billingData])

	const resyncData = () => {
		setResyncing(true)
		api.get(`/resync_clockify_billing_data/${matterNumber}`)
			.then(response => {
				const data = response.data
				console.log('billing:', data)
				setBillingData(data)
				setResyncing(false)
				showToast({ severity: 'success', summary: 'Successful', detail: 'Billing data is up to date' })
			})
			.catch(error => {
				console.error('Error:', error)
				setResyncing(false)
				showToast({ severity: 'error', summary: 'Error', detail: error.message })
			})
	}

	return (
		<>

			<div class="flex align-items-center justify-content-center text-red-500 pt-3">
				{resyncing ? (
					<Button icon="pi pi-spin pi-refresh" severity="secondary" label="Resyncing... This may take a minute" rounded disabled outlined />
				) : (
					<Button icon="pi pi-refresh" severity="secondary" label="Resync" rounded outlined onClick={resyncData} />
				)}
			</div>

			<div className="p-3">
				<div className="border-round border-1 overflow-hidden p-0 border-300">
					<DataTable value={dataTableData} size='small' stripedRows removableSort>
						<Column sortable field="name" header="Attorney"></Column>
						<Column sortable field="rate" header="Rate"></Column>
						<Column sortable field="hours" header="Hours"></Column>
						<Column sortable field="bill" header="Bill"></Column>
					</DataTable>
				</div>
			</div>
		</>
	)
}

export default BillingTable