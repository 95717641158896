import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useState, useEffect, useContext } from 'react';
import api from '../../api';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import ToastContext from '../../ToastContext';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Chip } from 'primereact/chip';
import { InputTextarea } from 'primereact/inputtextarea';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-date-picker';
import TrelloTaskForm from './TrelloTaskForm';
import {
    setRefreshTrelloTasks
} from '../../store/CaseDetails/action';

function QueuedTasks({ matterNumber }) {
    const dispatch = useDispatch();
    const matter = useSelector(state => state.caseDetails.matter);
    const refreshTrelloTasks = useSelector(state => state.caseDetails.refreshTrelloTasks);
    const showToast = useContext(ToastContext);
    const [queuedTasks, setQueuedTasks] = useState([]);
    const [currentlyEditingTrelloTask, setCurrentlyEditingTrelloTask] = useState(null);
    const [trelloTaskFormVisible, setTrelloTaskFormVisible] = useState(false);

    useEffect(() => {
        getTasks(matter.MatterNumber);
    }, [matter.MatterNumber])

    useEffect(() => {
        console.log('refreshTrelloTasks:', refreshTrelloTasks);
        if (refreshTrelloTasks) {
            getTasks(matter.MatterNumber);
            dispatch(setRefreshTrelloTasks(false))
        }
    }, [refreshTrelloTasks])

    const openTrelloTaskFormInEditMode = (queuedTask) => {
        setCurrentlyEditingTrelloTask(queuedTask);
        setTrelloTaskFormVisible(true);
    }

    const getTasks = (matterNumber, taskType) => {
        let url = `/get_trello_tasks?`;

        if (matterNumber) {
            url += `MatterNumber=${matterNumber}&`;
        }
        if (taskType) {
            url += `TaskType=${taskType}`;
        }

        api.get(url)
            .then(response => {
                if (response.status === 200) {
                    console.log('queued tasks:', response.data);
                    setQueuedTasks(response.data);
                } else {
                    console.error('Unexpected status code:', response.status);
                    showToast({ severity: 'error', summary: 'Error', detail: 'Unexpected status code' });
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    return (
        <>
            {/* <QueuedTaskForm queuedTaskForm={queuedTaskForm} setQueuedTaskForm={setQueuedTaskForm} matterNumber={matterNumber} fetchQueuedTasks={fetchQueuedTasks} /> */}
            <TrelloTaskForm substage={matter.Substage} visible={trelloTaskFormVisible} setVisible={setTrelloTaskFormVisible} currentlyEditing={currentlyEditingTrelloTask} />
            <div class="flex align-items-center justify-content-center text-red-500 py-5">
                <Button severity="secondary" icon='pi pi-plus' label="Queue Trello Task" raised onClick={() => {
                    setTrelloTaskFormVisible(true)
                    setCurrentlyEditingTrelloTask(null)
                }} />
            </div>
            {queuedTasks.length > 0 && (
                <div className='px-5'>
                    <div class="grid m-0 p-0 py-3 bg-bluegray-50 border-round border-1 border-bluegray-100">
                        {queuedTasks.filter(queuedTask => queuedTask.TaskType === 'DueDate').length > 0 && (
                            <div class="col">
                                <div className='px-3'>
                                    <div className="text-500 font-bold pb-1">With Due Date</div>
                                    <QueuedTaskChips queuedTasks={queuedTasks.filter(queuedTask => queuedTask.TaskType === 'DueDate')} openTrelloTaskFormInEditMode={openTrelloTaskFormInEditMode} />
                                </div>
                            </div>
                        )}
                        {queuedTasks.filter(queuedTask => queuedTask.TaskType !== 'DueDate').length > 0 && (
                            <div class="col">
                                <div className='px-3'>
                                    <div className="text-500 font-bold pb-1">Without Due Date</div>
                                    <QueuedTaskChips queuedTasks={queuedTasks.filter(queuedTask => queuedTask.TaskType !== 'DueDate')} openTrelloTaskFormInEditMode={openTrelloTaskFormInEditMode} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

export default QueuedTasks;

function truncateText(text, length) {
    if (text.length > length) {
        return text.substring(0, length - 3) + '...'
    }
    return text
}

const formatDate = (value) => {
    // Parse the date in UTC
    const date = new Date(value);
    const utcDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());

    // Format the date
    return utcDate.toLocaleDateString();
};


const getColor = (queuedTask) => {
    switch (queuedTask.TaskType) {
        case 'Passed':
            return 'red';
        default:
            return 'green';
    }
}

function QueuedTaskChips({ queuedTasks, openTrelloTaskFormInEditMode }) {

    return (
        <>
            <ul className='p-0 m-0'>
                {queuedTasks.map((queuedTask, index) => {
                    return (
                        <>
                            <li className={`flex w-full flex-column md:flex-row md:align-items-center md:justify-content-between p-2 shadow-1 my-3 mr-0 bg-${queuedTask.Popped ? 'gray' : 'blue'}-50 cursor-pointer hover:bg-${queuedTask.Popped ? 'gray' : 'blue'}-100`}
                                style={{ borderRadius: '10px' }}
                                onClick={() => openTrelloTaskFormInEditMode(queuedTask)}
                            >
                                <div>
                                    <div>
                                        <span className={`text-${queuedTask.Popped ? 'gray' : 'blue'}-700 font-bold`}>{queuedTask.Name}</span>
                                    </div>
                                    <p className={`text-${queuedTask.Popped ? 'gray' : 'blue'}-700 mt-2 mb-0`}>{queuedTask.Details}</p>
                                    <p className={`text-${queuedTask.Popped ? 'gray' : 'blue'}-700 mt-2 mb-0`}>{formatDate(queuedTask.Date)}</p>
                                </div>
                            </li>
                        </>
                    )
                })}
            </ul>
        </>
    )
}